import React from 'react';
import { CONNECTIONS } from '../../../constants/connection-constants';
import { useDataPreviewContext } from '../Context/DataPreviewContext';
import ApiTemplateDataPreviewTable from './ApiTemplate/ApiTemplateDataPreviewTable';
import ExternalDataPreviewTable from './External/ExternalDataPreviewTable';

export default function DataPreviewTableWrapper() {
    const { activeConnectionObj } = useDataPreviewContext();

    return (
        <div
            className={
                activeConnectionObj.connectionName === CONNECTIONS.CHARGEBEE ||
                activeConnectionObj.connectionName === CONNECTIONS.HUBSPOT ||
                activeConnectionObj.connectionProvider ===
                    CONNECTIONS.EXTERNAL ||
                activeConnectionObj.connectionName ===
                    CONNECTIONS.API_ORCHESTRATOR ||
                activeConnectionObj.connectionProvider ===
                    CONNECTIONS.API_ORCHESTRATOR
                    ? 'data-preview-table-wrapper rounded-tl-[8px] rounded-bl-[8px]'
                    : 'data-preview-table-wrapper-old'
            }
        >
            {activeConnectionObj.connectionProvider ===
                CONNECTIONS.API_ORCHESTRATOR && <ApiTemplateDataPreviewTable />}
            {activeConnectionObj.connectionProvider ===
                CONNECTIONS.EXTERNAL && <ExternalDataPreviewTable />}
        </div>
    );
}
