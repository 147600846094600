import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space } from 'antd';
import React, { useState } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { LuMinus } from 'react-icons/lu';
import { MdClose, MdDone } from 'react-icons/md';
import { CONNECTIONS } from '../../../../../constants/connection-constants';
import { useDataPreviewContext } from '../../../Context/DataPreviewContext';

const { Option } = Select;

function FilterSelection({
    activeConnectionObj,
    appliedFilters,
    setAppliedFilters,
}) {
    const {
        setFilterApplied,
        setApiCustomFilters,
        apiCustomFilters,
        setFilterRemoved,
        activeEndpoint,
    } = useDataPreviewContext();

    const filters =
        activeConnectionObj?.endpoints[activeEndpoint]?.filters?.columnFilters;

    const [showFilterInputSection, setShowFilterInputSection] = useState(false);
    const [filterFieldSelected, setFilterFieldSelected] = useState(false);

    const [activeFilter, setActiveFilter] = useState(null);
    const [activeFilterOperator, setActiveFilterOperator] = useState(null);
    const [activeFilterValue, setActiveFilterValue] = useState(null);

    const [filterReady, setFilterReady] = useState(false);

    const handleFilterFieldChange = (selectedFilterField) => {
        setActiveFilterOperator(null);
        setFilterReady(false);
        setFilterFieldSelected(true);

        const selectedFilterObj = filters.find(
            (filter) => filter.field === selectedFilterField
        );
        setActiveFilter(selectedFilterObj);
    };

    const handleFilterOperator = (selectedFilterOperator) => {
        setActiveFilterOperator(selectedFilterOperator);
    };

    const handleFilterValueChange = (value) => {
        setActiveFilterValue(value);
        setFilterReady(true);
    };

    const applyFilter = () => {
        const filter = {
            field: activeFilter.field,
            operator: activeFilterOperator,
            value: activeFilterValue,
        };
        const allFilters = [...appliedFilters, filter];
        setAppliedFilters(allFilters);

        setShowFilterInputSection(false);
        setFilterFieldSelected(false);
        setActiveFilter(null);
        setActiveFilterOperator(null);
        setActiveFilterValue(null);
        setFilterReady(false);

        switch (activeConnectionObj.connectionName) {
            case CONNECTIONS.FACEBOOK_ADS:
                setApiCustomFilters((prev) => ({
                    ...prev,
                    filtering: [...appliedFilters, filter],
                }));
                break;

            case CONNECTIONS.GOOGLE_SEARCH_CONSOLE:
                setApiCustomFilters((prev) => ({
                    ...prev,
                    dimensionFilterGroups: [
                        {
                            groupType: 'and',
                            filters: allFilters.map((appliedFilter) => ({
                                dimension: appliedFilter.field,
                                operator: appliedFilter.operator,
                                expression: appliedFilter.value,
                            })),
                        },
                    ],
                }));
                break;
            default:
                break;
        }

        setFilterApplied(true);
    };

    const generateFilterInputElement = () => {
        const { dataType } = activeFilter;

        switch (dataType) {
            case 'number':
            case 'string':
                return (
                    <Input
                        placeholder={'Enter value'}
                        style={{ fontSize: '0.8rem' }}
                        onChange={(event) =>
                            handleFilterValueChange(event.target?.value)
                        }
                    />
                );
            case 'multiSelect':
                return (
                    <Select
                        mode={'multiple'}
                        placeholder={'Select from available options'}
                        style={{ minWidth: '250px' }}
                        onChange={(values) => handleFilterValueChange(values)}
                    >
                        {activeFilter.values.map((value) => (
                            <Option key={value} label={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                );
            case 'select':
                return (
                    <Select
                        placeholder={'Choose one'}
                        style={{ minWidth: '250px' }}
                        onChange={(values) => handleFilterValueChange(values)}
                        showSearch={true}
                    >
                        {activeFilter.values.map((value) => (
                            <Option key={value} label={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                );
            default:
                return <></>;
        }
    };

    const removeFilter = (index) => {
        let updateAppliedFilters = appliedFilters;
        const updateCustomFilters = apiCustomFilters;

        if (updateAppliedFilters.length > 0) {
            updateAppliedFilters = [
                ...updateAppliedFilters.slice(0, index),
                ...updateAppliedFilters.slice(index + 1),
            ];

            if (updateAppliedFilters.length === 0) {
                delete updateCustomFilters.filtering;
            } else {
                updateCustomFilters.filtering = [
                    ...updateCustomFilters.filtering.slice(0, index),
                    ...updateCustomFilters.filtering.slice(index + 1),
                ];
            }

            setApiCustomFilters(updateCustomFilters);
            setAppliedFilters(updateAppliedFilters);
            setFilterRemoved(true);
        }
    };

    const formatFilterField = (field) => {
        const parentFields = field.split('.');

        let formattedField = '';
        if (parentFields.length > 1) {
            for (let i = 0; i < parentFields.length - 1; i += 1) {
                formattedField += `[${parentFields[i]
                    .slice(0, 1)
                    .toUpperCase()}${parentFields[i].slice(1).toLowerCase()}] `;
            }
        }

        formattedField +=
            parentFields[parentFields.length - 1].slice(0, 1).toUpperCase() +
            parentFields[parentFields.length - 1].slice(1).toLowerCase();
        return formattedField;
    };

    const formatOperator = (operator) => {
        switch (activeConnectionObj.connectionName) {
            case CONNECTIONS.FACEBOOK_ADS:
                return operator.replace(/_/g, ' ').toLowerCase();
            case CONNECTIONS.GOOGLE_SEARCH_CONSOLE:
                switch (operator) {
                    case 'includingRegex':
                        return 'including regex';
                    case 'excludingRegex':
                        return 'excluding regex';
                    case 'notContains':
                        return 'not contains';
                    case 'notEquals':
                        return 'not equals';
                    default:
                        return operator;
                }
            default:
                return operator;
        }
    };

    return (
        <div className={'p-1'}>
            <div className='bg-stackit-secondary m-2 p-2 rounded-3xl'>
                <div className='flex flex-col gap-[2px] max-w-[700px]'>
                    {appliedFilters.map((filter, index) => {
                        return (
                            <div key={index} className='flex flex-col gap-2'>
                                {index > 0 && (
                                    <div className='flex w-full -my-2 justify-center items-center'>
                                        <LuMinus />{' '}
                                        <span className='flex h-fit text-center justify-center text-[10px] text-stackit-text-gray'>
                                            AND
                                        </span>{' '}
                                        <LuMinus />
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '4px!important',
                                        border: '1px solid #E08752',
                                    }}
                                    className='p-2 mb-2 rounded-lg bg-[#FDF5E6] ml-[2px]'
                                >
                                    <span
                                        style={{
                                            width: '100%',
                                            fontWeight: '400',
                                            fontSize: '13px',
                                            marginRight: '10px',
                                        }}
                                        className='text-black'
                                    >
                                        {formatFilterField(filter.field)}{' '}
                                        <span
                                            style={{
                                                color: '#84848D',
                                            }}
                                            className='mx-1 font-semibold'
                                        >
                                            {filter.operator
                                                .replace(/_/g, ' ')
                                                .toLowerCase()}{' '}
                                        </span>
                                        <span className={'font-semibold'}>
                                            {filter.value}
                                        </span>
                                    </span>
                                    <CloseOutlined
                                        style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        }}
                                        className='w-3 h-3'
                                        onClick={() => removeFilter(index)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                {showFilterInputSection && (
                    <div className={'flex flex-col p-2'}>
                        <div className={'filter-section-input rounded-lg'}>
                            <Space>
                                <Select
                                    placeholder={'Select field'}
                                    showSearch
                                    style={{
                                        minWidth: '180px',
                                        display: 'flex',
                                    }}
                                    options={filters.map((filter) => ({
                                        label: filter.displayName,
                                        value: filter.field,
                                    }))}
                                    onChange={handleFilterFieldChange}
                                />
                                {filterFieldSelected ? (
                                    <Select
                                        placeholder={'Select Filter'}
                                        value={activeFilterOperator || null}
                                        options={activeFilter.operators.map(
                                            (operator) => ({
                                                label: formatOperator(operator),
                                                value: operator,
                                            })
                                        )}
                                        style={{
                                            minWidth: '180px',
                                            display: 'flex',
                                        }}
                                        onChange={handleFilterOperator}
                                    />
                                ) : (
                                    <Select
                                        placeholder={
                                            'No Supported Filters for Selected Column'
                                        }
                                        disabled={true}
                                    />
                                )}
                                {activeFilter &&
                                    activeFilterOperator &&
                                    generateFilterInputElement()}
                                <Button
                                    icon={<MdDone />}
                                    disabled={!filterReady}
                                    type={'link'}
                                    onClick={applyFilter}
                                />
                                <Button
                                    icon={<MdClose />}
                                    onClick={() => {
                                        setShowFilterInputSection(false);
                                        setFilterFieldSelected(false);
                                        setActiveFilter(null);
                                        setActiveFilterOperator(null);
                                        setActiveFilterValue(null);
                                        setFilterReady(false);
                                    }}
                                    type={'ghost'}
                                />
                            </Space>
                        </div>
                    </div>
                )}

                <button
                    onClick={() =>
                        setShowFilterInputSection(!showFilterInputSection)
                    }
                    className={`flex cursor-pointer min-w-[300px] w-[300px] justify-between items-center px-2 py-2 m-0 rounded-md bg-white text-stackit-gray border-[1px] border-solid border-stackit-gray/50 ${
                        showFilterInputSection ? 'hidden' : ''
                    }`}
                >
                    <p className='p-0 m-0'>Apply new filter</p>
                    <IoAddOutline className='text-stackit-primary w-4 h-4 font-semibold' />
                </button>
            </div>
        </div>
    );
}

export default FilterSelection;
