import React from 'react';
import { Tooltip } from 'antd';
import { readableColumnName } from '../../../../../utils/jsUtils';

const generateApiTemplateTableOptions = (tables) => {
    const tableOptions = [];
    Object.keys(tables).forEach((key) => {
        const table = tables[key];
        const tableOption = {
            key: table?.key,
            title: table?.title,
            label: table?.title,
            value: table?.value,
            isParentObject: true,
            children: table?.children,
        };
        tableOptions.push(tableOption);
    });

    return tableOptions;
};

const getApiTemplateDataSource = (treeSelectValue) => {
    return treeSelectValue.map((value) => {
        const checksort = value.split('.').pop();
        return {
            title: readableColumnName(value),
            dataIndex: value,
            key: value,
            ellipsis: {
                showTitle: false,
            },
            render: (textVal) => (
                <Tooltip placement='topLeft' title={textVal}>
                    {textVal}
                </Tooltip>
            ),
            sorter: ['created_at', 'updated_at', 'date'].includes(checksort)
                ? (a, b) => (a[value] < b[value] ? -1 : 1)
                : null,
        };
    });
};

const formatFilterField = (field) => {
    const parentFields = field.split('.');

    let formattedField = '';
    if (parentFields.length > 1) {
        for (let i = 0; i < parentFields.length - 1; i += 1) {
            formattedField += `[${parentFields[i]
                .slice(0, 1)
                .toUpperCase()}${parentFields[i].slice(1).toLowerCase()}] `;
        }
    }

    formattedField +=
        parentFields[parentFields.length - 1].slice(0, 1).toUpperCase() +
        parentFields[parentFields.length - 1].slice(1).toLowerCase();
    return formattedField;
};

export {
    generateApiTemplateTableOptions,
    getApiTemplateDataSource,
    formatFilterField,
};
