import React, { useState } from 'react';
import { SERVER_FUNCTION_CONSTANTS } from '../constants/server-function-constants';

const GsheetUrl = ({ sheetId = '0' }) => {
    const [copied, setCopied] = useState(false);
    const url = `https://docs.google.com/spreadsheets/d/${SERVER_FUNCTION_CONSTANTS.SPREADSHEET_ID}/edit?gid=${sheetId}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className='flex items-center space-x-2 p-4 bg-white rounded-lg shadow-md mb-5'>
            <p>Data is written to the spreadsheet at the following link!</p>
            <input
                type='text'
                value={url}
                readOnly
                className='flex-grow px-3 py-2 text-sm text-gray-700 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
            />
            <button
                onClick={copyToClipboard}
                className={`px-4 py-2 text-sm font-medium text-white rounded-r-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                    copied
                        ? 'bg-green-500 hover:bg-green-600 focus:ring-green-500'
                        : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500'
                }`}
            >
                {copied ? 'Copied!' : 'Copy'}
            </button>
        </div>
    );
};

export default GsheetUrl;
