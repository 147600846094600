import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MdDragIndicator, MdOutlineCancel } from 'react-icons/md';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { CONNECTIONS } from '../../../../constants/connection-constants';

function SortableColumn(props) {
    const { dataSelectedColumns, setSelectedColumns, activeConnectionObj } =
        useDataPreviewContext();

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            animateLayoutChanges: () => false,
            id: props.data,
        });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    function getTitleFromColumnKey() {
        if (dataSelectedColumns.length > 0) {
            let data = {};
            if (
                activeConnectionObj.connectionName === CONNECTIONS.CHARGEBEE ||
                activeConnectionObj.connectionName ===
                    CONNECTIONS.API_ORCHESTRATOR
            ) {
                data = dataSelectedColumns.find(
                    (column) => column.key === props.data
                );
            } else if (
                activeConnectionObj.connectionProvider ===
                    CONNECTIONS.EXTERNAL ||
                activeConnectionObj.connectionProvider ===
                    CONNECTIONS.API_ORCHESTRATOR
            ) {
                data = dataSelectedColumns.find(
                    (column) => column.key === props.data
                );
            } else {
                data = dataSelectedColumns.find(
                    (column) =>
                        column.key ===
                        `${props.data.split('.')[0]}.${
                            props.data.split('.')[
                                props.data.split('.').length - 1
                            ]
                        }`
                );
            }

            return data?.title;
        }
        return null;
    }

    function handleRemoveColumn(event) {
        event.stopPropagation();
        event.preventDefault();
        setSelectedColumns((items) =>
            items.filter((item) => item !== props.data)
        );
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={`mb-2 px-[4px] p-[8px] group flex items-center justify-between rounded-md  bg-opacity-[15%] hover:bg-opacity-25 border-0 border-l-[5px] border-solid cursor-pointer ${
                props?.data.startsWith('FX.')
                    ? ' bg-stackit-dc-primary border-l-stackit-dc-primary'
                    : '  bg-stackit-primary border-l-stackit-primary'
            }`}
        >
            <div className='flex overflow-x-hidden'>
                <MdDragIndicator />
                <p className='ml-[5px] p-0 m-0 text-[12px] font-normal overflow-scroll no-scrollbar'>
                    {getTitleFromColumnKey()}
                </p>
            </div>
            <MdOutlineCancel
                onMouseDown={(e) => handleRemoveColumn(e)}
                className='group-hover:block hidden bg-opacity-25 text-stackit-gray pr-2 z-30'
            />
        </div>
    );
}

export default SortableColumn;
