import React, { useEffect, useRef } from "react";
import AddColumnButton from "./AddColumnButton";
import SortableSelectedColumns from "./SortableSelectedColumns";
import { useDataPreviewContext } from "../../Context/DataPreviewContext";
import { Skeleton } from "antd";

export default function ShowAndRearrangeColumns() {
	const { scrollFields, setScrollFields, isDataLoading, actionNeeded } = useDataPreviewContext();
	const showAndRearrangeColumnRef = useRef(null);
	useEffect(() => {
		if (scrollFields) {
			if (showAndRearrangeColumnRef.current) {
				showAndRearrangeColumnRef.current.scrollTo({
					top: showAndRearrangeColumnRef.current.scrollHeight,
					behavior: "smooth",
				});
			}
		}

		setScrollFields(false);
	}, [scrollFields]);
	return (
		<div
			ref={showAndRearrangeColumnRef}
			className="bg-white mt-[8.8px] pb-[5.5px] overflow-y-auto flex flex-col border-stackit-secondary-border border-[1px] px-[8px] flex-grow rounded-md border-solid">
			<div className="bg-white z-10  py-[8px] top-0 sticky">
				<AddColumnButton />
			</div>
			<div className="flex flex-col flex-grow">
				{isDataLoading ? (
					actionNeeded ? (
						<div className="flex h-full flex-col gap-2 px-3 text-center items-center justify-center">
							<img src={"https://assets.superjoin.ai/images/data-preview-loading.svg"} className="h-20" alt="empty-state" />
							<p className="m-0 font-semibold text-sm">Action Needed</p>
							<p className="m-0 text-xs text-stackit-gray">Please enter the required details to proceed</p>
						</div>
					) : (
						<div className="flex flex-col gap-2">
							{Array(3)
								.fill()
								.map((_, index) => (
									<Skeleton.Input key={index} active={true} block={true} />
								))}
						</div>
					)
				) : (
					<SortableSelectedColumns />
				)}
			</div>
		</div>
	);
}
