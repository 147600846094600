import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import DataPreviewTable from '../DataPreviewTable';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import Filters from './Components/Filters';
import HeaderButton from './Components/HeaderButton';
import ParamPopOverContent from './Components/ParamPopOverContent';
import { IoStopCircle } from 'react-icons/io5';
import LimitPopOverContent from './Components/LimitPopOverContent';

function ApiTemplateDataPreviewTable() {
    const [currentOpenIndex, setCurrentOpenIndex] = useState(null);
    const componentRef = useRef(null);

    const {
        activeConnectionObj,
        totalRecordsToBeFetched,
        activeEndpoint,
        apiRequestParameters,
        apiCustomFilters,
    } = useDataPreviewContext();

    const activeEndpointObj = activeConnectionObj.endpoints[activeEndpoint];
    const requestParameters = activeEndpointObj?.request?.parameters || [];

    const nonAuthRequestParams = useMemo(() => {
        return requestParameters.filter((param) => !param.isAuthParam);
    }, [activeEndpointObj, requestParameters]);

    const customAPIFilters = activeEndpointObj?.filters?.customFilters || [];

    useEffect(() => {
        if (nonAuthRequestParams && nonAuthRequestParams.length > 0) {
            const firstRequiredParamIndex = nonAuthRequestParams.findIndex(
                (param) =>
                    param.required &&
                    !apiRequestParameters[param.fieldPropertyName]
            );
            if (firstRequiredParamIndex !== -1) {
                setCurrentOpenIndex(`param-config`);
            }
        }
    }, [activeEndpoint]);

    function handleSetPopOverOpenIndex(index) {
        if (currentOpenIndex === index) {
            setCurrentOpenIndex(null);
        } else {
            setCurrentOpenIndex(index);
        }
    }

    return (
        <div className={'sql-data-preview-table-container shrink'}>
            <div
                ref={componentRef}
                className={
                    'sql-filter-section-new rounded-tl-[8px] flex flex-shrink items-center justify-between gap-3'
                }
            >
                <div className='flex gap-x-[5px] gap-y-[5px]'>
                    {[
                        CONNECTIONS.FACEBOOK_ADS,
                        CONNECTIONS.GOOGLE_SEARCH_CONSOLE,
                    ].includes(activeConnectionObj.connectionName) &&
                        activeConnectionObj?.endpoints[activeEndpoint]?.filters
                            ?.columnFilters && (
                            <Filters
                                activeConnectionObj={activeConnectionObj}
                            />
                        )}
                    {nonAuthRequestParams &&
                        nonAuthRequestParams.length > 0 && (
                            <HeaderButton
                                currentOpenIndex={currentOpenIndex}
                                itemIndex={`param-config`}
                                setOpenIndex={handleSetPopOverOpenIndex}
                                buttonContent={
                                    <div className='flex items-center'>
                                        <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                            {'Config'}
                                        </p>
                                    </div>
                                }
                                popOverContent={
                                    <ParamPopOverContent
                                        type={'param'}
                                        params={nonAuthRequestParams}
                                        onSetupClick={() =>
                                            setCurrentOpenIndex(null)
                                        }
                                    />
                                }
                            />
                        )}

                    {customAPIFilters &&
                        customAPIFilters.length > 0 &&
                        customAPIFilters.map((filter, index) => (
                            <HeaderButton
                                currentOpenIndex={currentOpenIndex}
                                itemIndex={`filter-${index}`}
                                setOpenIndex={handleSetPopOverOpenIndex}
                                buttonContent={
                                    <div className='flex items-center'>
                                        <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                            {filter.fieldName}
                                        </p>
                                    </div>
                                }
                                isApplied={
                                    apiCustomFilters &&
                                    apiCustomFilters[
                                        filter.fieldPropertyName
                                    ] != null
                                }
                                popOverContent={
                                    <ParamPopOverContent
                                        type={'filter'}
                                        param={filter}
                                        onSetupClick={() =>
                                            setCurrentOpenIndex(null)
                                        }
                                    />
                                }
                            />
                        ))}

                    {![
                        CONNECTIONS.FACEBOOK_ADS,
                        CONNECTIONS.GOOGLE_SEARCH_CONSOLE,
                    ].includes(activeConnectionObj.connectionName) && (
                        <HeaderButton
                            currentOpenIndex={currentOpenIndex}
                            itemIndex={'limit'}
                            setOpenIndex={handleSetPopOverOpenIndex}
                            buttonContent={
                                <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                    <IoStopCircle />
                                    Limit
                                    {totalRecordsToBeFetched &&
                                        totalRecordsToBeFetched > 0 && (
                                            <span className='text-white bg-stackit-primary rounded-full text-[12px] px-2'>
                                                {totalRecordsToBeFetched}
                                            </span>
                                        )}
                                </p>
                            }
                            popOverContent={
                                <LimitPopOverContent
                                    onSetupClick={() =>
                                        setCurrentOpenIndex(null)
                                    }
                                />
                            }
                        />
                    )}
                </div>
            </div>
            <div className={'sql-table-with-filter-section'}>
                <DataPreviewTable />
            </div>
        </div>
    );
}

export default ApiTemplateDataPreviewTable;
