import React, { useState, useMemo, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button, Form, Input, Select, Radio, Switch } from 'antd';

import { BiSolidBookAlt } from 'react-icons/bi';
import { PiPhoneFill } from 'react-icons/pi';
import { FaQuestionCircle } from 'react-icons/fa';
import { RiLoader4Fill } from 'react-icons/ri';
import Loader from '../../common/Loader';
import {
    createNewUserConnection,
    fetchDataConnectionsDefination,
    getUserConnectionStatus,
    healthCheckConnection,
    trackUserEvents,
} from '../../../network/api';
import StackitAccordian from '../../common/StackitAccordian';
import DiscriptionToolTip from '../../common/DescriptionToolTip';
import { logger } from '../../../configs/logger-config';
import { SERVER_FUNCTION_CONSTANTS } from '../../../constants/server-function-constants';

const stackItIcon = 'https://assets.superjoin.ai/images/stackit-icon.svg';
const connIcon = 'https://assets.superjoin.ai/images/conn-icon.svg';

const ExternalConnectionForm = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const controllerSignal = useMemo(() => new AbortController(), []);
    const [connectionType, setConnectionType] = useState(props?.connectionType);
    const [connectionMetadata, setConnectionMetadata] = useState({});
    const [accordionItems, setAccordianMetadata] = useState([]);
    const [errorMessages, setErrorMessages] = useState('');
    const [importLoading, setImportLoading] = useState(false);
    const [retryAuth, setRetryAuth] = useState(false);
    const [userConnectionLoading, setUserConnectionLoading] = useState(false);
    const [userConnectionId, setUserConnectionId] = useState(null);
    const [isPending, startTransition] = useTransition();

    const CONTAINS_AUTH_PARAMS = props?.connectionAuthParams.length > 0;
    const IS_FORM_REQUIRED =
        connectionType === 'external-oauth' && !CONTAINS_AUTH_PARAMS;
    const FIVE_MINUTES = 5 * 60 * 1000;
    const FIVE_SECONDS = 5 * 1000;

    React.useEffect(() => {
        const sendAnalytics = async () => {
            try {
                const eventProperties = {
                    connectionId: props.connectionId,
                    connectionName: props.connectionName,
                };
                const eventName = 'user_connection_auth';
                await trackUserEvents(eventProperties, eventName);
            } catch (error) {
                logger.error(error);
            }
        };
        sendAnalytics();
    }, []);

    React.useEffect(() => {
        setConnectionType(props?.connectionType);
    }, [props]);

    React.useEffect(() => {
        if (props.connectionId) {
            const fetchDataDefination = async () => {
                const response = await fetchDataConnectionsDefination(
                    props.connectionId
                );
                if (response.status === 200) {
                    setConnectionMetadata(response.data.metadata);
                    if (response.data.metadata && response.data) {
                        setAccordianMetadata([
                            {
                                title: response.data.metadata.howToUse.title
                                    ? `${response.data.metadata.howToUse.title}`
                                    : `How to get ${props.connectionName} Credentials`,
                                content: (
                                    <div className='text-xs font-thin'>
                                        {response.data.metadata.howToUse.steps
                                            .length > 0 &&
                                            response.data.metadata.howToUse.steps.map(
                                                (step, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <p className='steps-text'>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                    className='step-highlight'
                                                                >
                                                                    Step{' '}
                                                                    {index + 1}{' '}
                                                                    :{' '}
                                                                </span>
                                                                {step.text}
                                                            </p>
                                                            {step.image && (
                                                                <div
                                                                    style={{
                                                                        borderRadius:
                                                                            '5px',
                                                                        border: '2px solid black',
                                                                        overflow:
                                                                            'hidden',
                                                                    }}
                                                                    className='steps-img-div  flex justify-center items-center relative'
                                                                >
                                                                    <img
                                                                        className=' object-contain h-full w-full'
                                                                        src={
                                                                            step.image
                                                                        }
                                                                        alt='step-image'
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                ),
                                disable: !(
                                    response.data?.metadata?.howToUse?.steps
                                        ?.length > 0
                                ),
                                icon: (
                                    <img
                                        src={props.connectionLogo}
                                        height={20}
                                    />
                                ),
                                arrow: true,
                            },
                            {
                                title: 'Schedule a call with us',
                                href: 'https://calendly.com/manan-19/demo-with-stackit',
                                itemType: 'Link',
                                icon: <PiPhoneFill color='green' size={16} />,
                            },

                            {
                                title: `Guide to connect ${props.connectionName}`,
                                href: response.data.metadata.howToUse.guide,
                                itemType: 'Link',
                                icon: (
                                    <BiSolidBookAlt color='orange' size={16} />
                                ),
                            },
                            {
                                title: 'FAQS',
                                content: (
                                    <ol className='m-0 space-y-3 list-disc'>
                                        {response.data.metadata.faqs.map(
                                            (faq, ind) => (
                                                <li
                                                    key={ind}
                                                    className='font-bold m-0 space-y-2'
                                                >
                                                    <div className='text-xs text-stackit-gray-primary'>
                                                        {faq.question}
                                                    </div>
                                                    <div className='text-xs font-normal text-stackit-gray'>
                                                        {faq.answer}
                                                    </div>
                                                </li>
                                            )
                                        )}
                                    </ol>
                                ),
                                disable: !(
                                    response.data.metadata.faqs.length > 0
                                ),
                                icon: <FaQuestionCircle color='' size={16} />,
                                arrow: true,
                            },
                        ]);
                    }
                }
            };
            fetchDataDefination();
        }
    }, [props.connectionId]);

    const renderFormComponent = (field) => {
        switch (field.fieldType) {
            case 'input':
            case 'password':
            case 'email':
            case 'number':
                return (
                    <Input
                        size='medium'
                        defaultValue={field.fieldDefaultValue || null}
                        type={field.fieldType}
                        placeholder={`Enter ${field.fieldLabel}`}
                    />
                );
            case 'boolean':
                return <Switch size={'medium'} />;
            case 'select':
                return (
                    <Select placeholder={`Select ${field.fieldLabel}`}>
                        {field.fieldOptions?.map((option) => (
                            <Select.Option
                                key={option.OptionValue}
                                value={option.optionValue}
                            >
                                {option.optionLabel}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case 'radio':
                return (
                    <Radio.Group>
                        {field.options?.map((option) => (
                            <Radio key={option} value={option}>
                                {option}
                            </Radio>
                        ))}
                    </Radio.Group>
                );
            default:
                return null;
        }
    };

    const handleSubmit = (values) => {
        startTransition(() => {
            setErrorMessages('');
            (async () => {
                setUserConnectionLoading(true);
                try {
                    const [activeSheetName, sheetId] = [
                        SERVER_FUNCTION_CONSTANTS.SHEET_NAME,
                        SERVER_FUNCTION_CONSTANTS.SPREADSHEET_ID,
                    ];
                    const transformedValues = {
                        ...values,
                        start_date: values.start_date
                            ? new Date(values.start_date).toISOString()
                            : undefined,
                    };
                    const newUserConnectionObject = {
                        connectionId: props.connectionId,
                        connectionConfiguration: IS_FORM_REQUIRED
                            ? undefined
                            : transformedValues,
                        documentInfo: [
                            {
                                spreadsheetId: sheetId,
                                sheetId: activeSheetName,
                            },
                        ],
                    };
                    const response = await createNewUserConnection(
                        newUserConnectionObject,
                        { signal: controllerSignal.signal }
                    );

                    if (response.status === 200) {
                        setUserConnectionId(
                            response?.data?.userConnectionObject
                                ?.userConnectionId
                        );

                        const propsData = {
                            newImport: true,
                            connectionName: props.connectionName,
                            spreadsheetId: sheetId,
                            sheetName: activeSheetName,
                            connectionLogo: props.connectionLogo,
                            connectionId: props.connectionId,
                            response: response.data,
                            connectionProvider: props?.connectionProvider,
                            userConnectionId:
                                response?.data?.userConnectionObject
                                    ?.userConnectionId,
                            displayName:
                                response?.data?.userConnectionObject
                                    .connectionInfo.displayName,
                            identifier:
                                response?.data?.userConnectionObject
                                    ?.connectionAuthConfig
                                    ?.connectionIdentifier || 'stackit',
                        };

                        if (connectionType === 'external-oauth') {
                            setImportLoading(true);
                            const authUrl =
                                response.data.userConnectionObject
                                    .connectionAuthConfig.authorisationUrl;

                            window.open(authUrl, '_blank');

                            const interval = setInterval(async () => {
                                let userConnection;
                                try {
                                    userConnection =
                                        await getUserConnectionStatus(
                                            response.data.userConnectionObject
                                                .userConnectionId
                                        );
                                } catch (e) {
                                    setUserConnectionLoading(false);
                                    setImportLoading(false);
                                    clearInterval(interval);
                                    setTimeout(() => {
                                        setErrorMessages(
                                            'Connection was aborted. Please try again.'
                                        );
                                    }, 1000);
                                    return;
                                }
                                if (
                                    userConnection.data.connectionStatus ===
                                    'CONNECTED'
                                ) {
                                    clearInterval(interval);

                                    const healthCheckResponse =
                                        await healthCheckConnection(
                                            props.userConnectionId,
                                            false
                                        );

                                    if (
                                        healthCheckResponse.status === 200 &&
                                        healthCheckResponse.data &&
                                        healthCheckResponse.data.customObjects
                                    ) {
                                        props.connectionObjects.customObjects =
                                            healthCheckResponse.data
                                                .customObjects || [];
                                    }

                                    // await serverFunctions
                                    //     .openDataPreviewSection(props)
                                    //     .finally(() => {
                                    //         setUserConnectionLoading(false);
                                    //         setImportLoading(false);
                                    //     });
                                    navigate('/connections', {
                                        state: propsData,
                                    });
                                }
                            }, FIVE_SECONDS);

                            setTimeout(() => {
                                clearInterval(interval);
                                setTimeout(() => {
                                    setErrorMessages(
                                        `We have not received your consent to connect to ${props.displayName}. Please authorise again.`
                                    );
                                }, 1000);
                                setUserConnectionLoading(false);
                                setImportLoading(false);
                            }, FIVE_MINUTES);
                            setUserConnectionLoading(false);
                        } else {
                            props.showDataPreview(true);
                            props.setConnectionData(propsData);

                            setUserConnectionLoading(false);
                            setImportLoading(false);
                            // await serverFunctions
                            //     .openDataPreviewSection(props)
                            //     .finally(() => {
                            //         setUserConnectionLoading(false);
                            //         setImportLoading(false);
                            //     });
                        }
                    }
                } catch (error) {
                    console.error(error);
                    setErrorMessages(
                        error.response?.data?.message ||
                            'Something went wrong. Please try again.'
                    );
                    setRetryAuth(true);
                    setUserConnectionLoading(false);
                    setImportLoading(false);
                }
            })();
        });
    };

    return (
        <div className='overflow-x-hidden flex flex-col w-full h-full bg-white shadow-md rounded-lg'>
            {importLoading && (
                <Loader
                    connectionLogo={props.connectionLogo}
                    userConnectionId={userConnectionId}
                    connectionId={props.connectionId}
                />
            )}
            <div className='px-2 py-3 font-semibold shadow-sm mb-5'>
                Superjoin - Data Connector for your SaaS
            </div>
            <div
                className='shopify-auth-sec-top'
                style={{
                    borderBottom: '1px solid #E8E8E8',
                }}
            >
                <div
                    className='flex flex-col gap-3 p-3'
                    style={{
                        borderBottom: '1px solid #E8E8E8',
                    }}
                >
                    {/* TODO: Make a common component */}
                    <div className='flex gap-2 justify-center'>
                        <img
                            className='shopify-auth-sec-connection-logos'
                            src={stackItIcon}
                            width={50}
                            height={50}
                        />
                        <img
                            className='shopify-auth-sec-connection-sync'
                            src={connIcon}
                            width={28}
                            height={28}
                        />
                        <img
                            className='shopify-auth-sec-connection-logos'
                            src={props.connectionLogo}
                            width={50}
                            height={50}
                        />
                    </div>
                    <p className='text-md font-semibold text-center my-6'>
                        {' '}
                        Lets Connect to {props.displayName}
                    </p>
                </div>

                {IS_FORM_REQUIRED && (
                    <>
                        <div className='-mb-2 mt-4'>
                            <Button
                                block
                                type='primary'
                                className='auth-btn'
                                onClick={handleSubmit}
                                loading={isPending || userConnectionLoading}
                                disabled={userConnectionLoading || isPending}
                            >
                                {retryAuth ? 'Retry' : 'Connect'}
                            </Button>
                            {errorMessages && (
                                <p className='error-msg'>{errorMessages}</p>
                            )}
                        </div>
                    </>
                )}
            </div>

            <div className='flex justify-between items-center px-4 mt-3'>
                {CONTAINS_AUTH_PARAMS && (
                    <>
                        <p className=' text-xs text-stackit-gray'>
                            Enter your connection details.
                        </p>
                        <a
                            href={connectionMetadata?.howToUse?.guide ?? ''}
                            target={'_blank'}
                            className='flex items-center text-xs gap-x-1 no-underline cursor-pointer font-bold text-stackit-primary'
                            rel='noreferrer'
                        >
                            <BiSolidBookAlt />
                            <span>Guide</span>
                        </a>
                    </>
                )}
            </div>
            <div className='px-4 flex w-full flex-col mt-3'>
                {CONTAINS_AUTH_PARAMS && (
                    <Form
                        className='flex flex-col'
                        form={form}
                        onFinish={handleSubmit}
                    >
                        {props.connectionAuthParams.map((field, index) => {
                            if (field.fieldType === 'datetime') {
                                return (
                                    <Form.Item
                                        key={index}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please enter ${field.fieldLabel}`,
                                            },
                                        ]}
                                        label={field.fieldLabel}
                                        tooltip={
                                            field.fieldDescription &&
                                            field.fieldDescription.length >
                                                0 && (
                                                <DiscriptionToolTip
                                                    discription={
                                                        field.fieldDescription
                                                    }
                                                />
                                            )
                                        }
                                        name={field.fieldName}
                                        style={{
                                            fontSize: '0.8rem',
                                        }}
                                        required={field.required}
                                    >
                                        <Input
                                            type='date'
                                            placeholder={`Enter ${field.fieldLabel}`}
                                        />
                                    </Form.Item>
                                );
                            }
                            if (field.fieldType === 'boolean') {
                                return (
                                    <Form.Item
                                        key={index}
                                        tooltip={
                                            field.fieldDescription &&
                                            field.fieldDescription.length >
                                                0 && (
                                                <DiscriptionToolTip
                                                    discription={
                                                        field.fieldDescription
                                                    }
                                                />
                                            )
                                        }
                                        name='ssl'
                                        valuePropName='checked'
                                        label={field.fieldLabel}
                                        className='mt-2'
                                        required={field.required}
                                    >
                                        <div className='flex gap-3 justify-between'>
                                            <p className='m-0 p-0 text-[13px]'>
                                                Enforce SSL?
                                            </p>
                                            <Switch defaultChecked={false} />
                                        </div>
                                        <p className='m-0 p-0 text-red-400 text-xs'>
                                            Keep it turned off if you are not
                                            sure.
                                        </p>
                                    </Form.Item>
                                );
                            }
                            return (
                                <Form.Item
                                    key={index}
                                    layout='vertical'
                                    className='mb-10'
                                    style={{
                                        marginBottom: '80px',
                                    }}
                                    tooltip={
                                        field.fieldDescription &&
                                        field.fieldDescription.length > 0 && (
                                            <DiscriptionToolTip
                                                discription={
                                                    field.fieldDescription
                                                }
                                            />
                                        )
                                    }
                                    rules={[
                                        field.regex
                                            ? {
                                                  pattern: new RegExp(
                                                      field.regex
                                                  ),
                                                  message: field?.regexMessage
                                                      ? field.regexMessage
                                                      : `The value must match the pattern ${field.regex}`,
                                              }
                                            : {},
                                        field.fieldName === 'host'
                                            ? {
                                                  required: true,
                                                  validator: (_, value) => {
                                                      if (
                                                          value ===
                                                              'localhost' ||
                                                          value ===
                                                              '127.0.0.1' ||
                                                          value.startsWith(
                                                              '192.168'
                                                          )
                                                      ) {
                                                          return Promise.reject(
                                                              new Error(
                                                                  'We do not support locally hosted databases'
                                                              )
                                                          );
                                                      }
                                                      if (value === '') {
                                                          return Promise.reject(
                                                              new Error(
                                                                  'Please enter host name or IP address'
                                                              )
                                                          );
                                                      }
                                                      if (
                                                          value.startsWith(
                                                              'https://'
                                                          ) ||
                                                          value.startsWith(
                                                              'http://'
                                                          )
                                                      ) {
                                                          return Promise.reject(
                                                              new Error(
                                                                  'Please enter host name without https:// or http://'
                                                              )
                                                          );
                                                      }
                                                      return Promise.resolve();
                                                  },
                                              }
                                            : {
                                                  required: true,
                                                  message: `Please enter ${field.fieldLabel}`,
                                              },
                                    ]}
                                    normalize={(value) => value?.trim()}
                                    name={field.fieldName}
                                    label={field.fieldLabel}
                                    required={field.required}
                                >
                                    {renderFormComponent(field)}
                                </Form.Item>
                            );
                        })}
                        <div className='w-full bg-white bottom-[55px] mt-5 mb-10'>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button
                                    block
                                    type='primary'
                                    className='auth-btn'
                                    htmlType='submit'
                                    loading={isPending || userConnectionLoading}
                                    disabled={
                                        userConnectionLoading || isPending
                                    }
                                >
                                    {retryAuth ? 'Retry' : 'Authorize'}
                                </Button>
                                {errorMessages && (
                                    <p className='error-msg'>{errorMessages}</p>
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                )}
                <div className='flex flex-col my-6 space-y-5'>
                    <div>
                        <h4 className='m-0 font-semibold text-sm'>
                            Step by step guide
                        </h4>
                        <span className='text-xs text-stackit-gray'>{`Here's a list of resources to help you connect to your ${props.connectionName}`}</span>
                    </div>
                    {accordionItems.length > 0 ? (
                        <StackitAccordian
                            items={accordionItems}
                            keepFirstOpen={false}
                        />
                    ) : (
                        <div className='flex justify-center items-center w-full'>
                            <RiLoader4Fill className='animate-spin' />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExternalConnectionForm;
