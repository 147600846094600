import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { VscGroupByRefType } from 'react-icons/vsc';
import {
    getDataPreview,
    getDataPreviewConfig,
} from '../../../../../../network/api';
import { handleErrorWithToast } from '../../../../../../utils/ErrorHandling';
import { useDataPreviewContext } from '../../../../Context/DataPreviewContext';
import SelectedColumnsTree from '../../../common/SelectedColumnsTree';
import {
    generateApiTemplateTableOptions,
    getApiTemplateDataSource,
} from '../../Helpers/ApiTemplateDataPreviewHelpers';

const { Option } = Select;

function GSCDataPreviewConfig() {
    const {
        activeConnectionObj,
        isDataLoading,
        setIsDataLoading,
        dataSource,
        setDataSource,
        setNumberOfRows,
        sessionID,
        setSessionID,
        columnSelectionTree,
        setColumnSelectionTree,
        selectedColumns,
        setSelectedColumns,
        setDataSelectedColumns,
        setUpClicked,
        setSetUpClicked,
        apiCustomFilters,
        activeEndpoint,
        apiTemplateBody,
        filterApplied,
        setFilterApplied,
        filterRemoved,
        setFilterRemoved,
        setApiCustomFilters,
        apiRequestParameters,
        setApiPersistentRequestParameters,
    } = useDataPreviewContext();

    const NINETY_DAYS_IN_MS = 90 * 24 * 60 * 60 * 1000;

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const [breakdowns, setBreakdowns] = useState([]);
    const [breakdownDisabled, setBreakdownDisabled] = useState(true);
    const [selectedBreakdowns, setSelectedBreakdowns] = useState([]);

    const formatDateToAppropriateFormat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const returnDate90DaysBefore = () => {
        const today = new Date();
        const date90DaysBefore = new Date(today - NINETY_DAYS_IN_MS);
        return formatDateToAppropriateFormat(date90DaysBefore);
    };

    const fetchDataPreview = async (sessionId, key, metadata) => {
        try {
            const userConnectionId = activeConnectionObj?.userConnectionId;
            const response = await getDataPreview(
                userConnectionId,
                {
                    sessionId,
                    key,
                },
                metadata
            );

            if (response && response.data) {
                setDataSource(response.data.previewData);
                setIsDataLoading(false);
            }
        } catch (error) {
            handleErrorWithToast(
                error,
                `Error in fetching data preview: ${error.message}`
            );
        }
    };

    const fetchDataPreviewConfig = async (key, metadata = {}) => {
        try {
            setIsDataLoading(true);
            setNumberOfRows(0);
            setSessionID(null);

            const userConnectionId = activeConnectionObj?.userConnectionId;
            const response = await getDataPreviewConfig(
                userConnectionId,
                {
                    sessionID: sessionID ?? null,
                    key,
                },
                metadata
            );

            if (response && response.data) {
                setSessionID(response.data.sessionId);

                const options = generateApiTemplateTableOptions(
                    response.data.formattedSchema
                );
                setColumnSelectionTree(options);
                setSelectedColumns([
                    'rows.clicks',
                    'rows.impressions',
                    'rows.ctr',
                    'rows.position',
                ]);

                if (
                    response.data.accounts &&
                    response.data.accounts.length > 0
                ) {
                    if (selectedAccount == null)
                        setSelectedAccount(response.data.accounts[0].value);
                    setAccounts(response.data.accounts);
                }

                if (response.data.breakdowns) {
                    setBreakdowns(response.data.breakdowns);
                }

                if (response.data.sessionId) {
                    await fetchDataPreview(
                        response.data.sessionId,
                        key,
                        metadata
                    );
                }
            }
        } catch (error) {
            setBreakdownDisabled(true);
            handleErrorWithToast(
                error,
                `Error in fetching preview config from API: ${error.message}`
            );
        }
    };

    const handleAccountChange = async (selectedSiteUrl) => {
        const curSelectedSiteUrl = accounts.find(
            (account) => account.value === selectedSiteUrl
        );
        setSelectedAccount(curSelectedSiteUrl.value);

        const updatedParams = apiRequestParameters;
        const index = updatedParams.findIndex(
            (param) => param.key === 'siteUrl'
        );
        if (index !== -1) {
            updatedParams[index].value = selectedSiteUrl;
        } else {
            const siteUrlParameter = {
                key: 'siteUrl',
                value: selectedSiteUrl,
                type: 'text',
            };

            updatedParams.push(siteUrlParameter);
            setApiPersistentRequestParameters((prev) => [...prev, 'siteUrl']);
        }

        const key =
            activeConnectionObj?.endpoints[activeEndpoint].identifierKey ||
            null;
        fetchDataPreviewConfig(key, {
            params: updatedParams,
            queryParams: [],
            data: { ...(apiTemplateBody || {}), ...apiCustomFilters },
        });
    };

    const handleBreakdownChange = async (selectedValues) => {
        setSelectedBreakdowns(selectedValues);

        let updatedCustomFilters;
        if (selectedValues.length > 0) {
            updatedCustomFilters = apiCustomFilters;
            updatedCustomFilters.dimensions = selectedValues;
            setApiCustomFilters(updatedCustomFilters);
        } else {
            updatedCustomFilters = apiCustomFilters;
            if (updatedCustomFilters.dimensions) {
                delete updatedCustomFilters.dimensions;
                setApiCustomFilters(updatedCustomFilters);
            }
        }

        const key =
            activeConnectionObj?.endpoints[activeEndpoint].identifierKey ||
            null;
        fetchDataPreviewConfig(key, {
            queryParams: [],
            params: apiRequestParameters,
            data: { ...(apiTemplateBody || {}), ...updatedCustomFilters },
        });
    };

    useEffect(() => {
        if (activeConnectionObj != null) {
            const startDate = returnDate90DaysBefore();
            const endDate = formatDateToAppropriateFormat(new Date());

            setApiCustomFilters((prev) => ({ ...prev, startDate, endDate }));
            fetchDataPreviewConfig(
                activeConnectionObj?.endpoints[0]?.identifierKey,
                {
                    queryParams: [],
                    params: [],
                    data: {
                        ...(apiTemplateBody || {}),
                        startDate,
                        endDate,
                    },
                }
            );
        }
    }, [activeConnectionObj]);

    useEffect(() => {
        const newColumns = getApiTemplateDataSource(selectedColumns);
        setDataSelectedColumns(newColumns);

        const maxSize = Math.max(
            ...Object.values(dataSource).map((x) => x.length)
        );
        setNumberOfRows(maxSize < 0 ? 0 : maxSize);
    }, [selectedColumns, columnSelectionTree, dataSource]);

    useEffect(() => {
        const defaultActiveEndpoint =
            activeConnectionObj?.endpoints[activeEndpoint]?.identifierKey;
        if (setUpClicked) {
            fetchDataPreviewConfig(defaultActiveEndpoint, {
                queryParams: [],
                params: [],
                data: { ...(apiTemplateBody || {}), ...apiCustomFilters },
            });
            setSetUpClicked(false);
        }

        if (filterApplied || filterRemoved) {
            setIsDataLoading(true);
            fetchDataPreview(sessionID, defaultActiveEndpoint, {
                queryParams: [],
                params: [],
                data: { ...(apiTemplateBody || {}), ...apiCustomFilters },
            });

            if (filterApplied) setFilterApplied(false);
            if (filterRemoved) setFilterRemoved(false);
        }
    }, [apiCustomFilters, setUpClicked, filterApplied, filterRemoved]);

    return (
        <div className=' px-[13.5px] py-[13.5px] flex w-full overflow-hidden justify-center scrollbar-hide'>
            <div className='gap-y-[15px] w-full overflow-hidden flex flex-col'>
                <div className='flex-shrink'>
                    <div>
                        <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                            <MdOutlineSupervisorAccount className='mr-2' />
                            ACCOUNTS
                        </h4>
                    </div>
                    <div className='mt-[8.8px]'>
                        <Select
                            value={selectedAccount}
                            style={{
                                width: '100%',
                            }}
                            options={accounts}
                            disabled={isDataLoading}
                            onChange={handleAccountChange}
                        />
                    </div>
                </div>
                <div className='flex-shrink'>
                    <div>
                        <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                            <VscGroupByRefType className='mr-2' />
                            DIMENSIONS
                        </h4>
                    </div>
                    <div className='mt-[8.8px]'>
                        <Select
                            mode={'multiple'}
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder='Please select'
                            onChange={handleBreakdownChange}
                            disabled={isDataLoading && breakdownDisabled}
                        >
                            {breakdowns.map((breakdown) => {
                                return (
                                    <Option
                                        key={breakdown.value}
                                        value={breakdown.value}
                                        disabled={selectedBreakdowns.includes(
                                            breakdown.value
                                        )}
                                    >
                                        {breakdown.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>

                <SelectedColumnsTree selectedColumns={selectedColumns} />
            </div>
        </div>
    );
}

export default GSCDataPreviewConfig;
