import React from "react";
import { Popover } from "antd";
import { CgRadioChecked } from "react-icons/cg";

function HeaderButton({ currentOpenIndex, itemIndex, setOpenIndex, buttonContent, popOverContent, isApplied = false }) {
	const isOpen = currentOpenIndex === itemIndex;

	return (
		<Popover placement={"bottomLeft"} trigger={"click"} content={popOverContent} open={isOpen} onOpenChange={() => setOpenIndex(itemIndex)}>
			<button className="ml-2 min-w-fit border-[1px] border-solid border-stackit-gray bg-stackit-background-2 rounded-full cursor-pointer px-3 py-1.5 flex flex-row items-center">
				{isApplied && <CgRadioChecked className="rounded-full mr-0.5 text-xs text-stackit-primary" />}
				<div className="flex m-0 p-0 items-center text-stackit-text-gray font-[500]">{buttonContent}</div>
			</button>
		</Popover>
	);
}

export default HeaderButton;
