import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdArrowDropdown } from 'react-icons/io';
import { MdArrowForwardIos } from 'react-icons/md';
import './stackitAccordian.css';

const AccordianItemType = PropTypes.oneOf(['Collapseable', 'Link']);

const AccordionItem = ({
    title,
    content,
    itemType,
    icon,
    isOpen,
    onToggle,
    href,
    disable,
    arrow = false,
}) => {
    if (disable) {
        return null;
    }
    if (itemType === 'Link') {
        return (
            <a
                href={disable ? '#' : href}
                target={disable ? '' : '_blank'}
                rel='noreferrer'
                className={`bg-stackit-background-1 cursor-pointer no-underline px-2 flex justify-between text-stackit-gray-primary font-bold items-center ${
                    disable
                        ? 'text-stackit-secondary-border cursor-not-allowed hover:bg-none'
                        : 'hover:bg-[#f7e4c6] rounded hover:text-stackit-gray-primary'
                }`}
            >
                <div className='flex justify-center py-1 space-x-1'>
                    <div
                        className={`flex justify-center items-center ${
                            disable
                                ? 'text-stackit-secondary-border'
                                : 'text-stackit-gray-primary'
                        }`}
                    >
                        {icon || null}
                    </div>
                    <div className='p-1 text-xs'>{title}</div>
                </div>
                <MdArrowForwardIos
                    className={disable ? 'text-stackit-secondary-border' : ''}
                    size={12}
                />
            </a>
        );
    }

    return (
        <div className={`${disable ? ' cursor-not-allowed' : ''}`}>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    if (onToggle && !disable) onToggle();
                }}
                className={`flex space-x-1 px-2 py-1 hover:rounded hover:bg-[#f7e4c6] justify-center cursor-pointer items-center text-sm border-none w-full bg-stackit-background-1 ${
                    disable ? ' cursor-not-allowed' : ''
                }`}
            >
                <div className='m-0 flex justify-center items-center'>
                    {icon || (
                        <IoMdArrowDropdown
                            className={`border transition-all duration-200 ease-in-out ${
                                isOpen ? 'rotate-0' : '-rotate-90'
                            }`}
                        />
                    )}
                </div>
                <div
                    className={`w-full text-xs text-left text-stackit-gray-primary font-bold flex hover:text-stackit-gray-primary p-1 m-0 ${
                        disable ? 'text-gray-200 ' : ''
                    }`}
                >
                    {title}
                </div>
                {arrow && (
                    <MdArrowForwardIos
                        className={` transition-all duration-100 ease-in-out ${
                            disable
                                ? 'text-stackit-secondary-border'
                                : 'text-stackit-gray-primary'
                        } ${isOpen ? 'rotate-90' : '-rotate-0'}`}
                        size={16}
                    />
                )}
            </button>
            {isOpen ? (
                <div
                    className={`${
                        isOpen
                            ? 'initialAccordian active bg-stackit-background-1 py-3 px-1  h-auto'
                            : 'initialAccordian h-0'
                    } ${disable ? ' cursor-not-allowed' : ''}`}
                >
                    {content}
                </div>
            ) : null}
        </div>
    );
};

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    itemType: AccordianItemType,
    icon: PropTypes.node,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    href: PropTypes.string,
    disable: PropTypes.bool,
    arrow: PropTypes.bool,
};

const StackitAccordian = ({ items, keepFirstOpen = true }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='border list-none rounded-md overflow-hidden p-1 py-2 flex flex-col gap-y-2 bg-stackit-background-1'>
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    isOpen={
                        index === 0 && keepFirstOpen
                            ? true
                            : index === openIndex
                    }
                    onToggle={() => handleClick(index)}
                    {...item}
                />
            ))}
        </div>
    );
};

StackitAccordian.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            icon: PropTypes.node,
            title: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.node.isRequired,
            ]),
            itemType: AccordianItemType,
            href: PropTypes.string,
            disable: PropTypes.bool,
        })
    ).isRequired,
    keepFirstOpen: PropTypes.bool,
};

export default StackitAccordian;
