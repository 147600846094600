import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { FaAnglesDown } from 'react-icons/fa6';
import { Select, Tag, Tooltip } from 'antd';
import { FaTable } from 'react-icons/fa';
import { VscGroupByRefType } from 'react-icons/vsc';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import SelectedColumnsTree from '../common/SelectedColumnsTree';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { getDataPreview, getDataPreviewConfig } from '../../../../network/api';
import {
    handleError,
    handleErrorWithToast,
} from '../../../../utils/ErrorHandling';
import { readableColumnName } from '../../../../utils/jsUtils';

const { Option } = Select;

function ApiTemplateDataPreviewConfig() {
    const {
        activeConnectionObj,
        setIsDataLoading,
        setNumberOfRows,
        sessionID,
        setSessionID,
        selectedColumns,
        setSelectedColumns,
        setColumnSelectionTree,
        setShowColumnActions,
        setDataSource,
        activeEndpoint,
        setActiveEndpoint,
        columnSelectionTree,
        dataSource,
        setDataSelectedColumns,
        isDataLoading,
        setApiRequestParameters,
        setApiCustomFilters,
        apiCustomFilters,
        setUpClicked,
        setSetUpClicked,
        setActionNeeded,
        apiRequestParameters,
        activeSubObject,
        setActiveSubObject,
        filterApplied,
        setFilterApplied,
        filterRemoved,
        setFilterRemoved,
        apiPersistentRequestParameters,
        setApiPersistentRequestParameters,
    } = useDataPreviewContext();

    const [endpointDisabled, setEndpointDisabled] = useState(true);
    const [drilldownDisabled, setDrilldownDisabled] = useState(true);
    const [subObjects, setSubObjects] = useState(['Select']);
    const [activeSubObjectIndex, setActiveSubObjectIndex] = useState(0);
    const [displaySubObjectAdded, setDisplaySubObjectAdded] = useState(false);

    const [
        formattedSchemaWithNoDrillDowns,
        setFormattedSchemaWithNoDrilldowns,
    ] = useState(null);
    const [
        selectedColumnsWithNoDrillDowns,
        setSelectedColumnsWithNoDrillDowns,
    ] = useState(null);

    // Facebook Ads
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const [breakdowns, setBreakdowns] = useState([]);
    const [breakdownDisabled, setBreakdownDisabled] = useState(true);
    const [selectedBreakdowns, setSelectedBreakdowns] = useState([]);
    const [disabledBreakdowns, setDisabledBreakdowns] = useState([]);

    const selectSomeColumnsByDefault = (objectColumns) => {
        const selColumns = [];
        Object.entries(objectColumns).forEach(([object, columns]) => {
            const newSelColumns = columns
                .slice(0, 5)
                .map((column) => `${object}.${column}`);
            selColumns.push(...newSelColumns);
        });

        setSelectedColumns(selColumns);
        setSelectedColumnsWithNoDrillDowns(selColumns);
    };

    const generateTableOptions = (tables) => {
        const tableOptions = [];
        Object.keys(tables).forEach((key) => {
            const table = tables[key];
            const tableOption = {
                key: table?.key,
                title: table?.title,
                label: table?.title,
                value: table?.value,
                isParentObject: true,
                children: table?.children,
            };
            tableOptions.push(tableOption);
        });
        return tableOptions;
    };

    const getAPIOrchestratorDataSource = (treeSelectValue) => {
        return treeSelectValue.map((value) => {
            const checksort = value.split('.').pop();
            return {
                title: readableColumnName(value),
                dataIndex: value,
                key: value,
                ellipsis: {
                    showTitle: false,
                },
                render: (textVal) => (
                    <Tooltip placement='topLeft' title={textVal}>
                        {textVal}
                    </Tooltip>
                ),
                sorter: ['created_at', 'updated_at', 'date'].includes(checksort)
                    ? (a, b) => (a[value] < b[value] ? -1 : 1)
                    : null,
            };
        });
    };

    const fetchDataPreview = async (sessionId, key, metadata = {}) => {
        try {
            const userConnectionId = activeConnectionObj?.userConnectionId;

            const response = await getDataPreview(
                userConnectionId,
                {
                    sessionId,
                    key,
                    connectionSubObject: metadata.connectionSubObject || null,
                },
                metadata
            );

            if (response.data) {
                setDataSource(response.data.previewData);
                if (metadata.connectionSubObject) {
                    const options = generateTableOptions(response.data.fs);
                    setColumnSelectionTree(options);
                    setSelectedColumns((prev) => [
                        ...prev,
                        ...response.data.columns,
                    ]);
                    setDisplaySubObjectAdded(true);
                    setTimeout(() => setDisplaySubObjectAdded(false), 5000);
                }
            }

            setIsDataLoading(false);
        } catch (e) {
            setBreakdownDisabled(false);
            handleError(e, 'Error while fetching data preview');
        }
    };

    const fetchDataPreviewConfig = async (key, metadata = {}) => {
        try {
            setIsDataLoading(true);
            setNumberOfRows(0);
            setSessionID(null);
            setDrilldownDisabled(true);
            setBreakdownDisabled(true);

            const userConnectionId = activeConnectionObj?.userConnectionId;

            const response = await getDataPreviewConfig(
                userConnectionId,
                {
                    sessionID: sessionID ?? null,
                    key,
                },
                metadata
            );

            if (response.data) {
                setSessionID(response.data.sessionId);

                const options = generateTableOptions(
                    response.data.formattedSchema
                );
                setFormattedSchemaWithNoDrilldowns(
                    response.data.formattedSchema
                );
                setColumnSelectionTree(options);

                selectSomeColumnsByDefault(
                    response.data?.uniqueColumnsOfObject || {}
                );

                const subObjs = response.data?.subObjects || [];
                setSubObjects(['Select', ...subObjs]);

                if (subObjs.length > 0) {
                    setDrilldownDisabled(false);
                }

                // Facebook ads specific
                if (response.data.breakdowns) {
                    setBreakdowns(response.data.breakdowns);
                }

                if (response.data.accounts) {
                    if (!selectedAccount) {
                        setSelectedAccount(response.data.accounts[0].value);
                    }
                    setAccounts(response.data.accounts);
                }

                if (response.data.sessionId)
                    await fetchDataPreview(
                        response.data.sessionId,
                        key,
                        metadata
                    );
            }
        } catch (e) {
            setEndpointDisabled(false);
            setDrilldownDisabled(false);
            setBreakdownDisabled(false);
            handleErrorWithToast(e, "Couldn't fetch data from API");
        }
    };

    useEffect(() => {
        if (activeConnectionObj) {
            fetchDataPreviewConfig(
                activeConnectionObj?.endpoints[0]?.identifierKey,
                { queryParams: [], params: [] }
            );
        }
    }, [activeConnectionObj]);

    useEffect(() => {
        const newColumns = getAPIOrchestratorDataSource(selectedColumns);
        setDataSelectedColumns(newColumns);
        const maxSize = Math.max(
            ...Object.values(dataSource).map((x) => x.length)
        );
        if (maxSize < 0) setNumberOfRows(0);
        else setNumberOfRows(maxSize);
    }, [selectedColumns, columnSelectionTree, dataSource]);

    useEffect(() => {
        const defaultActiveEndpoint =
            activeConnectionObj?.endpoints[activeEndpoint]?.identifierKey;
        if (setUpClicked) {
            fetchDataPreviewConfig(defaultActiveEndpoint, {
                queryParams: apiCustomFilters,
                params: apiRequestParameters,
            });
            setSetUpClicked(false);
        }

        if (filterApplied || filterRemoved) {
            setIsDataLoading(true);
            fetchDataPreview(sessionID, defaultActiveEndpoint, {
                queryParams: apiCustomFilters,
                params: apiRequestParameters,
            });

            if (filterApplied) setFilterApplied(false);
            if (filterRemoved) setFilterRemoved(false);
        }
    }, [apiCustomFilters, setUpClicked, filterApplied, filterRemoved]);

    async function handleEndpointChange(index) {
        setSelectedColumns([]);
        setColumnSelectionTree([]);
        setNumberOfRows(0);
        setSessionID(null);
        setDataSource({});
        setIsDataLoading(true);
        setEndpointDisabled(true);
        setBreakdownDisabled(true);

        // if there are some persistent query parameters, store it inside request parameters, when the endpoint is changed
        const tempApiRequestParameters = apiRequestParameters.filter((param) =>
            apiPersistentRequestParameters.includes(param.key)
        );
        setApiRequestParameters(tempApiRequestParameters);

        setApiCustomFilters({});
        setActionNeeded(false);
        try {
            setActiveEndpoint(index);
            setActiveSubObjectIndex(0);
            const endpoint = activeConnectionObj?.endpoints[index] || [];
            const endpointParameters = endpoint?.request?.parameters || [];
            const requiredQueryParams = endpointParameters.filter(
                (param) => param?.required && !param.isAuthParam
            );
            if ((requiredQueryParams || []).length === 0) {
                setShowColumnActions(true);
                fetchDataPreviewConfig(endpoint?.identifierKey, {
                    queryParams: apiCustomFilters,
                    params: apiRequestParameters,
                });
            } else {
                setShowColumnActions(false);
                setEndpointDisabled(false);
                setActionNeeded(true);
            }
        } catch (error) {
            setIsDataLoading(false);
            handleErrorWithToast(
                error,
                'Error while fetching data preview config'
            );
        }
    }

    async function handleSubObjectChange(index) {
        try {
            setIsDataLoading(true);
            setEndpointDisabled(true);
            setDrilldownDisabled(true);

            if (subObjects && subObjects.length > 0) {
                const currentSelectedSubObject =
                    subObjects[index] === 'Select' ? null : subObjects[index];
                setActiveSubObjectIndex(index);

                const key =
                    activeConnectionObj?.endpoints[activeEndpoint]
                        .identifierKey || null;
                if (key) {
                    setActiveSubObject(currentSelectedSubObject);

                    await fetchDataPreview(sessionID, key, {
                        queryParams: apiCustomFilters,
                        params: apiRequestParameters,
                        connectionSubObject: currentSelectedSubObject,
                    });

                    // if sub object is "select", i.e. no sub object is selected, then use that formatted schema which was received from backend
                    // when the object was changed
                    if (currentSelectedSubObject == null) {
                        const options = generateTableOptions(
                            formattedSchemaWithNoDrillDowns
                        );
                        setColumnSelectionTree(options);
                        setSelectedColumns(selectedColumnsWithNoDrillDowns);
                    }
                }
            }
        } catch (error) {
            handleErrorWithToast(error, 'Error while changing sub object');
        } finally {
            setIsDataLoading(false);
            setEndpointDisabled(false);
            setDrilldownDisabled(false);
        }
    }

    const handleAccountChange = async (selectedAccountId) => {
        const curSelectedAccount = accounts.find(
            (account) => account.value === selectedAccountId
        );
        setSelectedAccount(curSelectedAccount.value);

        const updatedParams = apiRequestParameters;
        const index = updatedParams.findIndex(
            (param) => param.key === 'adAccountId'
        );
        if (index !== -1) {
            updatedParams[index].value = selectedAccountId;
        } else {
            const adAccountIdParameter = {
                key: 'adAccountId',
                value: selectedAccountId,
                type: 'text',
            };

            updatedParams.push(adAccountIdParameter);
            setApiPersistentRequestParameters((prev) => [
                ...prev,
                'adAccountId',
            ]);
        }

        const key =
            activeConnectionObj?.endpoints[activeEndpoint].identifierKey ||
            null;
        fetchDataPreviewConfig(key, {
            params: updatedParams,
            queryParams: apiCustomFilters,
        });
    };

    const handleBreakdownChange = async (selectedValues) => {
        setSelectedBreakdowns(selectedValues);

        let updatedCustomFilters;
        if (selectedValues.length > 0) {
            updatedCustomFilters = apiCustomFilters;
            updatedCustomFilters.breakdowns = selectedValues.join(',');
            setApiCustomFilters(updatedCustomFilters);
        } else {
            updatedCustomFilters = apiCustomFilters;
            if (updatedCustomFilters.breakdowns) {
                delete updatedCustomFilters.breakdowns;
                setApiCustomFilters(updatedCustomFilters);
            }
        }

        // Update the disabled options based on the selected breakdowns
        let notApplicableOptions = new Set();

        if (selectedValues.length > 0) {
            // Find the union of not_applicable_with sets for all selected breakdowns
            selectedValues.forEach((value) => {
                const breakdown = breakdowns.find((bd) => bd.value === value);
                if (breakdown) {
                    const currentNotApplicable = new Set(
                        breakdowns
                            .map((bd) => bd.value)
                            .filter(
                                (val) =>
                                    !breakdown.applicable_with.includes(val) &&
                                    val !== value
                            )
                    );
                    notApplicableOptions = new Set([
                        ...notApplicableOptions,
                        ...currentNotApplicable,
                    ]);
                }
            });

            // Remove the selected values from the notApplicableOptions
            selectedValues.forEach((value) =>
                notApplicableOptions.delete(value)
            );
        }

        setDisabledBreakdowns([...notApplicableOptions]);

        const key =
            activeConnectionObj?.endpoints[activeEndpoint].identifierKey ||
            null;
        fetchDataPreviewConfig(key, {
            queryParams: updatedCustomFilters,
            params: apiRequestParameters,
        });
    };

    return (
        <div className=' px-[13.5px] py-[13.5px] flex w-full overflow-hidden justify-center scrollbar-hide'>
            <div className='gap-y-[15px] w-full overflow-hidden flex flex-col'>
                {activeConnectionObj.connectionName ===
                    CONNECTIONS.FACEBOOK_ADS && (
                    <div className='flex-shrink'>
                        <div>
                            <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                                <MdOutlineSupervisorAccount className='mr-2' />
                                ACCOUNTS
                            </h4>
                        </div>
                        <div className='mt-[8.8px]'>
                            <Select
                                value={selectedAccount}
                                style={{
                                    width: '100%',
                                }}
                                options={accounts}
                                disabled={isDataLoading}
                                onChange={handleAccountChange}
                            />
                        </div>
                    </div>
                )}
                <div className='flex-shrink'>
                    <div>
                        <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                            <FaTable className='mr-2' />
                            OBJECTS
                        </h4>
                    </div>
                    <div className='mt-[8.8px]'>
                        <Select
                            defaultValue={0}
                            style={{
                                width: '100%',
                            }}
                            options={activeConnectionObj?.endpoints.map(
                                (item, index) => ({
                                    label: (
                                        <span className={'m-0'}>
                                            {item.displayName}
                                        </span>
                                    ),
                                    value: index,
                                })
                            )}
                            onChange={(index) =>
                                handleEndpointChange(Number(index))
                            }
                            value={activeEndpoint}
                            disabled={isDataLoading && endpointDisabled}
                        />
                    </div>
                </div>
                {activeConnectionObj.connectionName !==
                    CONNECTIONS.FACEBOOK_ADS && (
                    <div className='flex-shrink'>
                        <div className={'flex flex-row items-center gap-2'}>
                            <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                                <FaAnglesDown className='mr-2' />
                                DRILLDOWN (OPTIONAL)
                            </h4>
                            <Tooltip
                                title={`${
                                    subObjects.length > 1
                                        ? 'Drilldown allows you to fetch more granular data contained within each object.'
                                        : 'Drilldown is disabled for current object.'
                                }`}
                            >
                                <InfoCircleOutlined
                                    style={{
                                        marginLeft: '4px',
                                        width: '0.5rem',
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <div className='mt-[8.8px]'>
                            <Select
                                defaultValue={subObjects[0]}
                                style={{
                                    width: '100%',
                                }}
                                options={subObjects.map((item, index) => ({
                                    label: item,
                                    value: index,
                                }))}
                                value={subObjects[activeSubObjectIndex]}
                                onChange={(index) =>
                                    handleSubObjectChange(Number(index))
                                }
                                disabled={isDataLoading || drilldownDisabled}
                                loading={isDataLoading}
                            />
                        </div>
                        {displaySubObjectAdded && (
                            <div className={'my-2'}>
                                <Tag
                                    icon={
                                        <CheckCircleOutlined
                                            style={{
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold',
                                                marginLeft: '4px',
                                            }}
                                        />
                                    }
                                    style={{
                                        marginBottom: '10px',
                                        fontSize: '0.8rem',
                                        padding: '4px',
                                        borderRadius: '6px',
                                        width: '100%',
                                        flexShrink: 1,
                                    }}
                                    color='green'
                                >
                                    <span className={'whitespace-break-spaces'}>
                                        Added new{' '}
                                        <span className={'font-medium'}>
                                            {activeSubObject
                                                .slice(0, 1)
                                                .toUpperCase() +
                                                activeSubObject.slice(1)}{' '}
                                        </span>
                                        columns inside data preview, scroll to
                                        see.
                                    </span>
                                </Tag>
                            </div>
                        )}
                    </div>
                )}
                {activeConnectionObj.connectionName ===
                    CONNECTIONS.FACEBOOK_ADS && (
                    <div className='flex-shrink'>
                        <div>
                            <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                                <VscGroupByRefType className='mr-2' />
                                BREAKDOWNS
                            </h4>
                        </div>
                        <div className='mt-[8.8px]'>
                            <Select
                                mode={'multiple'}
                                allowClear={true}
                                style={{ width: '100%' }}
                                placeholder='Please select'
                                onChange={handleBreakdownChange}
                                disabled={isDataLoading && breakdownDisabled}
                            >
                                {breakdowns.map((breakdown) => {
                                    return (
                                        <Option
                                            key={breakdown.value}
                                            value={breakdown.value}
                                            disabled={
                                                disabledBreakdowns.includes(
                                                    breakdown.value
                                                ) &&
                                                !selectedBreakdowns.includes(
                                                    breakdown.value
                                                )
                                            }
                                        >
                                            {breakdown.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                )}
                <SelectedColumnsTree selectedColumns={selectedColumns} />
            </div>
        </div>
    );
}

export default ApiTemplateDataPreviewConfig;
