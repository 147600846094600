import React, { useState, useEffect } from 'react';
import { Button, Cascader, Select, Space, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FaSortAlphaDown } from 'react-icons/fa';
import { MdClose, MdDone } from 'react-icons/md';
import { toast } from 'sonner';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { convertToTitleCase } from '../../Utils/Transformations';
import { getIconForColumnType } from '../../../../utils/jsUtils';

const ExternalSortSelection = () => {
    const {
        columnSelectionTree,
        selectedColumns,
        appliedSorts,
        setAppliedSorts,
        importingStatus,
        isDataLoading,
    } = useDataPreviewContext();
    const [showSortsInputSection, setShowSortsInputSection] = useState(false);
    const [inputSortColumns, setInputSortColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [inputSortDirection, setInputSortDirection] = useState('');
    const [inputSortValid, setInputSortValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    function generateTitleForFilter(data) {
        return (
            <div className='flex gap-3 items-center'>
                <p className='m-0 p-0 text-stackit-primary'>
                    {getIconForColumnType(data.type ?? 'text')}
                </p>
                <p className='m-0 p-0 text-black'>{data.title}</p>
            </div>
        );
    }

    useEffect(() => {
        setInputSortValid(inputSortColumns.length === 2 && inputSortDirection);
    }, [inputSortColumns, inputSortDirection]);

    const handleSortDelete = React.useCallback(
        (index) => {
            setAppliedSorts(appliedSorts.filter((_, ind) => ind !== index));
        },
        [appliedSorts, setAppliedSorts]
    );

    const getCascaderChildren = React.useCallback(() => {
        const children = columnSelectionTree?.[0]?.children ?? [];
        const colData = children
            .filter((item) => selectedColumns.includes(item.key))
            .map((item) => ({
                value: item.title,
                title: generateTitleForFilter(item),
            }));

        return [
            {
                children: colData,
                key: columnSelectionTree?.[0]?.key,
                title: columnSelectionTree?.[0]?.label,
                value: columnSelectionTree?.[0]?.value,
            },
        ];
    }, [columnSelectionTree, selectedColumns]);

    const handleApplySort = React.useCallback(() => {
        if (inputSortColumns.length >= 2 && inputSortDirection) {
            const sortColumnValue = inputSortColumns[1];
            const sortOnColumnExists = appliedSorts.some(
                (elem) => elem.sortColumn === sortColumnValue
            );

            if (!sortOnColumnExists) {
                setAppliedSorts((prevSorts) => [
                    ...prevSorts,
                    {
                        sortColumn: sortColumnValue,
                        sortDirection: inputSortDirection,
                    },
                ]);
            } else {
                toast.error('Cannot apply multiple sorts on the same column');
                setTimeout(() => setErrorMsg(''), 5000);
            }
        } else {
            toast.error(
                'Invalid sort column or direction. Please check your input.'
            );
            setTimeout(() => setErrorMsg(''), 5000);
        }

        setShowSortsInputSection(false);
        setInputSortColumns([]);
        setInputSortDirection('');
        setOpen(false);
    }, [inputSortColumns, inputSortDirection, appliedSorts, setAppliedSorts]);

    const handleOpenChange = (newOpen) => {
        if (newOpen === false) {
            setShowSortsInputSection(false);
            setInputSortColumns([]);
            setInputSortDirection('');
        }
        setOpen(newOpen);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
            }}
        >
            <div className='flex gap-x-1'>
                <Popover
                    content={
                        isDataLoading ? (
                            <div className='p-2'>fetching...</div>
                        ) : (
                            <div>
                                {selectedColumns.length > 0 &&
                                importingStatus === false ? (
                                    <div className='p-2'>
                                        {appliedSorts.map((sort, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    padding: '4px!important',
                                                    border: '1px solid #E08752',
                                                }}
                                                className='p-2 mb-2 rounded-lg bg-[#FDF5E6] ml-[2px]'
                                            >
                                                <span
                                                    style={{
                                                        width: '100%',
                                                        fontWeight: '400',
                                                        fontSize: '13px',
                                                        marginRight: '10px',
                                                    }}
                                                    className='text-black'
                                                >
                                                    [
                                                    {columnSelectionTree[0].key}
                                                    ] {sort.sortColumn} [
                                                    {convertToTitleCase(
                                                        sort.sortDirection
                                                    )}
                                                    ]
                                                </span>
                                                <CloseOutlined
                                                    style={{
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                    }}
                                                    className='w-3 h-3'
                                                    onClick={() =>
                                                        handleSortDelete(index)
                                                    }
                                                />
                                            </div>
                                        ))}
                                        {showSortsInputSection &&
                                            columnSelectionTree && (
                                                <div>
                                                    <Space className='mt-1'>
                                                        <Cascader
                                                            options={
                                                                getCascaderChildren() ||
                                                                []
                                                            }
                                                            fieldNames={{
                                                                label: 'title',
                                                            }}
                                                            onChange={
                                                                setInputSortColumns
                                                            }
                                                            disabled={
                                                                !selectedColumns.length ||
                                                                importingStatus
                                                            }
                                                            displayRender={(
                                                                label,
                                                                selectedOptions
                                                            ) =>
                                                                `[${
                                                                    label[0]
                                                                }] ${
                                                                    selectedOptions[
                                                                        label.length -
                                                                            1
                                                                    ].value
                                                                }`
                                                            }
                                                            value={
                                                                inputSortColumns
                                                            }
                                                            multiple={false}
                                                        />
                                                        <Select
                                                            value={
                                                                inputSortDirection
                                                            }
                                                            disabled={
                                                                !selectedColumns.length ||
                                                                importingStatus
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'asc',
                                                                    label: 'A to Z',
                                                                },
                                                                {
                                                                    value: 'desc',
                                                                    label: 'Z to A',
                                                                },
                                                            ]}
                                                            onChange={
                                                                setInputSortDirection
                                                            }
                                                        />
                                                        <Button
                                                            icon={<MdDone />}
                                                            onClick={
                                                                handleApplySort
                                                            }
                                                            type={'link'}
                                                            disabled={
                                                                !inputSortValid
                                                            }
                                                        />
                                                        <Button
                                                            icon={<MdClose />}
                                                            onClick={() => {
                                                                setShowSortsInputSection(
                                                                    false
                                                                );
                                                                setInputSortColumns(
                                                                    []
                                                                );
                                                                setInputSortDirection(
                                                                    ''
                                                                );
                                                                setOpen(false);
                                                            }}
                                                            type={'ghost'}
                                                        />
                                                    </Space>
                                                </div>
                                            )}
                                    </div>
                                ) : (
                                    <div className='text-xs text-stackit-gray-primary p-2 font-semibold'>
                                        No Column is Selected
                                    </div>
                                )}
                            </div>
                        )
                    }
                    trigger='click'
                    open={open}
                    placement='bottom'
                    onOpenChange={handleOpenChange}
                >
                    <button
                        onClick={() => {
                            setShowSortsInputSection(true);
                            setInputSortDirection('asc');
                            setInputSortColumns([]);
                        }}
                        className='ml-2 min-w-fit border-[1px] border-solid border-stackit-gray bg-stackit-background-2 rounded-full cursor-pointer px-3 py-1.5'
                    >
                        <div className='flex m-0 p-0 items-center text-stackit-text-gray font-[500]'>
                            {appliedSorts.length &&
                            appliedSorts.length !== 0 ? (
                                <div className='flex items-center'>
                                    <FaSortAlphaDown
                                        style={{ marginRight: '4px' }}
                                        className='text-stackit-text-gray w-4 h-4'
                                    />
                                    <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                        {appliedSorts.length}{' '}
                                        {appliedSorts.length > 1
                                            ? 'Sort Filters'
                                            : 'Sort Filter'}{' '}
                                        Applied
                                    </p>
                                </div>
                            ) : (
                                <div className='flex items-center'>
                                    <FaSortAlphaDown
                                        style={{ marginRight: '4px' }}
                                        className='text-stackit-text-gray w-4 h-4'
                                    />
                                    <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                        Sort
                                    </p>
                                </div>
                            )}
                        </div>
                    </button>
                </Popover>
            </div>

            {errorMsg && <p className='error-msg'>{errorMsg}</p>}
        </div>
    );
};

export default ExternalSortSelection;
