import React from 'react';

const UseSteps = () => {
    const steps = [
        {
            title: 'Connect to Source',
            description: 'Authorize your Source account',
        },
        {
            title: 'Choose Fields',
            description: 'Select fields in data preview',
        },
        { title: 'Import Data', description: 'Import data to Google Sheet' },
    ];

    return (
        <div className='max-w-md mx-auto'>
            <ol className='relative border-l border-gray-200 dark:border-gray-700'>
                {steps.map((step, index) => (
                    <li key={index} className='mb-10 ml-6'>
                        <span className='absolute flex items-center justify-center w-8 h-8  rounded-full -left-4  bg-stackit-primary'>
                            <span className='text-white '>{index + 1}</span>
                        </span>
                        <h3 className='font-medium leading-tight'>
                            {step.title}
                        </h3>
                        <p className='text-sm text-gray-500 dark:text-gray-400'>
                            {step.description}
                        </p>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default UseSteps;
