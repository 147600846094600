import React, { useState, useTransition } from 'react';
import { Button } from 'antd';
import { toast } from 'sonner';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { createImportConfig, importDataNow } from '../../../../network/api';
import { handleError } from '../../../../utils/ErrorHandling';
import { SERVER_FUNCTION_CONSTANTS } from '../../../../constants/server-function-constants';

const ExternalDataPreviewHeader = ({
    setStatusLoading,
    setImportLoadingData,
}) => {
    const {
        listOfAppliedFilters,
        appliedSorts,
        appliedLimit,
        activeConnectionObj,
        selectedColumns,
        dataSelectedColumns,
        setImportingStatus,
        streamNames,
    } = useDataPreviewContext();

    const [importButtonLoading, setImportButtonLoading] = useState(false);
    const [isPending, startTransition] = useTransition();

    const logoUrl = activeConnectionObj.connectionLogo || '';

    const handleCreateImportConfig = async () => {
        setImportButtonLoading(true);
        setImportingStatus(true);
        startTransition(async () => {
            try {
                const filtersList = listOfAppliedFilters.map((filter) => ({
                    filterValue: filter.enableInput
                        ? filter.filterValue
                        : undefined,
                    filterType: filter.value,
                    filterColumn: filter.columnName,
                }));

                const selectedColumnList = selectedColumns.map(
                    (selectedItem) => ({
                        fieldPath: [selectedItem.split('.')[1]],
                    })
                );

                const currentSheetId = SERVER_FUNCTION_CONSTANTS.SPREADSHEET_ID;

                const importConfig = {
                    connectionImportConfig: {
                        streams: [
                            {
                                stream: streamNames.stream,
                                config: {
                                    ...streamNames.config,
                                    destinationSyncMode: 'overwrite',
                                    syncMode: 'full_refresh',
                                    selected: true,
                                    fieldSelectionEnabled: true,
                                    selectedFields: selectedColumnList,
                                },
                            },
                        ],
                        limitConfig: parseInt(appliedLimit, 10) || undefined,
                        sortConfig:
                            appliedSorts.length > 0 ? appliedSorts : undefined,
                        filterConfig:
                            filtersList.length > 0 ? filtersList : undefined,
                    },
                    userConnectionId: activeConnectionObj.userConnectionId,
                    spreadsheetId: currentSheetId,
                    sharedConnection: activeConnectionObj.sharedConnection,
                };

                const response = await createImportConfig(importConfig);

                if (response.status === 200) {
                    toast.success('Import configuration created successfully!');

                    const importDataConfig = {
                        runType: 'MANUAL_FIRST_TIME',
                        runConfig: {
                            userConnectionId:
                                activeConnectionObj.userConnectionId,
                            importConfigId: response.data.importConfigId,
                            spreadsheetId: activeConnectionObj.spreadsheetId,
                            sharedConfig:
                                activeConnectionObj.sharedConfig == null
                                    ? false
                                    : activeConnectionObj.sharedConfig,
                        },
                    };

                    const runResponse = await importDataNow(importDataConfig);

                    if (runResponse.status === 200) {
                        toast.success('Data import initiated successfully!');
                        const localState = {
                            connectionLogo: activeConnectionObj.connectionLogo,
                            connectionName: activeConnectionObj.connectionName,
                            importConfigId: response.data.importConfigId,
                            spreadsheetId: activeConnectionObj.spreadsheetId,
                            importRunId: runResponse.data.importRunId,
                            connectionProvider: 'External',
                            from: 'importselect',
                        };

                        setStatusLoading(true);
                        setImportLoadingData((prev) => ({
                            ...prev,
                            importRunResponse: {},
                            importRunId: runResponse.data.importRunId,
                        }));
                        const stateString = JSON.stringify(localState);

                        sessionStorage.setItem('state', stateString);
                        const event = new Event('dataPreviewClosed');
                        window.dispatchEvent(event);
                        // google.script.host.close();
                    } else {
                        toast.error('Data import initiation failed.');
                        handleError(
                            'Failed to initiate data import:',
                            runResponse
                        );
                    }
                }
            } catch (error) {
                handleError(error);
            } finally {
                setImportButtonLoading(false);
            }
        });
    };

    return (
        <>
            <div className='connection'>
                <div className='logo'>
                    <img
                        className='connection-logo'
                        src={logoUrl}
                        width={30}
                        height={30}
                        alt='Connection Logo'
                    />
                </div>
                <div className='connection-name'>
                    {activeConnectionObj.displayName}
                    {activeConnectionObj.identifier ? (
                        <span className='text-[12px] ml-1 text-stackit-gray'>
                            [{activeConnectionObj.identifier}]
                        </span>
                    ) : (
                        ' '
                    )}
                </div>
            </div>
            <div className='import-connection'>
                <Button
                    type='primary'
                    block
                    className='import-connection-btn'
                    onClick={handleCreateImportConfig}
                    disabled={
                        dataSelectedColumns?.length === 0 || importButtonLoading
                    }
                    loading={isPending || importButtonLoading}
                >
                    Import
                </Button>
            </div>
        </>
    );
};

export default ExternalDataPreviewHeader;
