import { useState, useEffect, useCallback, useRef } from 'react';
import { handleError } from '../utils/ErrorHandling';
import { pollImportRunStatus } from '../network/api';
import { IMPORT_STATUS } from '../constants/import-constants';

const useImportProgress = (
    startImport,
    exceededLimitEventHandler,
    triggerSuccessEvent,
    triggerFailureEvent,
    importRunId,
    importRunResponse
) => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const intervalIdRef = useRef(null);
    const incrementIntervalIdRef = useRef(null);
    const shouldContinuePolling = useRef(true);

    const fetchProgress = useCallback(async () => {
        try {
            if (!shouldContinuePolling.current) {
                return;
            }
            const response = await pollImportRunStatus(importRunId);
            exceededLimitEventHandler(response.data);
            const newProgress = response.data.progress;
            setStatusMessage(response.data.statusMessage);

            clearInterval(incrementIntervalIdRef.current);

            if (response.data.status === IMPORT_STATUS.COMPLETED) {
                shouldContinuePolling.current = false;
                clearInterval(intervalIdRef.current);
                clearInterval(incrementIntervalIdRef.current);
                console.log('calling success event');
                triggerSuccessEvent(response.data, importRunResponse);
            } else if (response.data.status === IMPORT_STATUS.FAILED) {
                shouldContinuePolling.current = false;
                setErrorMessage(
                    response.data?.errorBucket?.message ||
                        'Import failed! Please try again later.'
                );
                throw new Error('Import failed');
            }

            if (newProgress[0] > progress) {
                setProgress((prevProgress) =>
                    Math.max(prevProgress, newProgress[0])
                );
                incrementIntervalIdRef.current = setInterval(() => {
                    setProgress((prevProgress) =>
                        Math.min(prevProgress + 2, newProgress[1])
                    );
                }, 1000);
            }
        } catch (err) {
            shouldContinuePolling.current = false;
            clearInterval(intervalIdRef.current);
            clearInterval(incrementIntervalIdRef.current);
            handleError(err, 'Failed to fetch progress');
            setError(true);
            setIsLoading(false);
            triggerFailureEvent(importRunId);
        }
    }, [importRunId, error, isLoading]);

    const startImportJob = useCallback(async () => {
        setIsLoading(true);
        setError(false);
        shouldContinuePolling.current = true;
        try {
            await startImport();
            fetchProgress();
            intervalIdRef.current = setInterval(fetchProgress, 6400); // Store intervalId in useRef
        } catch (err) {
            handleError(err, 'Failed to start import job');
            setError(true);
            setIsLoading(false);
        }
    }, [fetchProgress, startImport]);

    useEffect(() => {
        return () => {
            shouldContinuePolling.current = false;
            clearInterval(intervalIdRef.current);
            clearInterval(incrementIntervalIdRef.current);
        };
    }, []);

    return {
        progress,
        statusMessage,
        errorMessage,
        isLoading,
        error,
        startImportJob,
    };
};

export default useImportProgress;
