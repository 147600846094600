import React from 'react';

import { useDataPreviewContext } from '../Context/DataPreviewContext';
import { CONNECTIONS } from '../../../constants/connection-constants';
import ApiTemplateDataPreviewColumnActions from './ApiTemplate/ApiTemplateDataPreviewColumnActions';
import ExternalDataPreviewColumnActions from './External/ExternalDataPreviewColumnActions';

export default function DataPreviewColumnActions() {
    const { activeConnectionObj, showColumnActions } = useDataPreviewContext();

    return (
        <>
            <div
                style={{ borderRight: '0px' }}
                className={`flex transition-all bg-stackit-secondary-orange duration-300 ease-in-out h-[500px] ${
                    showColumnActions
                        ? 'w-[250px] dp-right-container opacity-100'
                        : 'w-0 opacity-0 -z-10'
                } `}
            >
                {activeConnectionObj.connectionProvider ===
                    CONNECTIONS.API_ORCHESTRATOR && (
                    <ApiTemplateDataPreviewColumnActions />
                )}
                {activeConnectionObj.connectionProvider ===
                    CONNECTIONS.EXTERNAL && (
                    <ExternalDataPreviewColumnActions />
                )}
            </div>
        </>
    );
}
