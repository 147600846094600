import React, { useEffect } from 'react';
import { MdAttachEmail } from 'react-icons/md';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PiX } from 'react-icons/pi';
import useImportProgress from '../../hooks/useImportProgress';

const StackItIcon = 'https://assets.superjoin.ai/images/stackit-icon.svg';
const SheetsIcon = 'https://assets.superjoin.ai/images/gsheets.svg';

function ImportProgressCard({
    startImport,
    exceededLimitEventHandler,
    successEventHandler,
    failureEventHandler,
    activeConnectionLoading,
    setStatusLoading,
    importRunId,
    importRunResponse,
}) {
    const navigate = useNavigate();
    const {
        progress,
        statusMessage,
        errorMessage,
        isLoading,
        error,
        startImportJob,
    } = useImportProgress(
        startImport,
        exceededLimitEventHandler,
        successEventHandler,
        failureEventHandler,
        importRunId,
        importRunResponse
    );

    useEffect(() => {
        if (importRunId && importRunId.length > 0) startImportJob();
    }, [importRunId]);

    return (
        <div>
            {isLoading && (
                <div className='import-progress-card'>
                    {activeConnectionLoading ? (
                        <div>
                            <div className='flex justify-center items-center flex-col'>
                                <MdAttachEmail size={'50'} color='#ee8245' />
                                <div className='text-[13px] m-0 font-bold mt-6 text-center'>
                                    {' '}
                                    Looks like it is taking longer to fetch your
                                    data.
                                </div>
                            </div>
                            <div className='px-4'>
                                <p className='text-xs'>
                                    Don’t worry, we will notify you over email
                                    once your data is pulled. It may take 5-10
                                    minutes.
                                </p>
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        setStatusLoading(false);
                                        navigate('/home');
                                    }}
                                    className='text-white font-bold w-full flex justify-center items-center text-center text-xs cursor-pointer'
                                    style={{ background: '#ee8245' }}
                                >
                                    Okay
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    overflowY: 'hidden',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            >
                                <div className='flex justify-center gap-2 items-center w-full'>
                                    <img
                                        className='ic-card-image'
                                        src={StackItIcon}
                                    />
                                    <div className='flex relative flex-col items-center w-1/3'>
                                        <div className='flex w-full  bg-stackit-primary/20 h-[7px] rounded-full'>
                                            <div
                                                className='bg-stackit-primary text-xs font-medium text-blue-100 text-center h-[7px] leading-none rounded-full'
                                                style={{
                                                    width: `${progress}%`,
                                                }}
                                            >
                                                {' '}
                                            </div>
                                        </div>
                                        <p className='m-0 p-0 absolute left-1/2 -translate-x-1/2 top-[10px] text-xs'>
                                            {progress}%
                                        </p>
                                    </div>

                                    <img
                                        className='ic-card-image'
                                        src={SheetsIcon}
                                    />
                                </div>
                                <p className='text-black mt-6 font-bold mb-0'>
                                    Pulling your data to Google Sheets!
                                </p>
                                <p className='text-stackit-gray m-0 p-0 mt-2.5 text-xs text-center'>
                                    {statusMessage}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            )}
            {error && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        overflowY: 'hidden',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                >
                    <div className='flex flex-col justify-center items-center gap-2'>
                        <div className='h-12 w-12 rounded-full flex items-center justify-center bg-red-400'>
                            <PiX className='h-8 w-8 text-white' />
                        </div>
                        <p className='m-0 mt-2 p-0 text-[15px] font-normal text-center text-stackit-text-gray'>
                            {errorMessage}
                        </p>

                        <button
                            className='mt-5 bg-none border-0 bg-transparent text-stackit-primary font-semibold cursor-pointer px-3 py-2 rounded-md'
                            onClick={() => setStatusLoading(false)}
                        >
                            Go to Home
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImportProgressCard;
