import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const { DATE_RANGE_TYPE } = require('../../../constants/date-range-constants');

const DataPreviewContext = createContext();

export const useDataPreviewContext = () => useContext(DataPreviewContext);

const defaultTreeData = [
    {
        title: 'Fetching columns..',
        value: '0',
        key: '0',
    },
];

const sixMonthsAgo = dayjs().subtract(180, 'day');
const oneDayAgo = dayjs().subtract(1, 'day');

export const DataPreviewContextProvider = ({ children }) => {
    // Connection specific states
    const [activeConnectionObj, setActiveConnectionObj] = useState({});
    const [newImport, setNewImport] = useState(true);

    // Data specific states
    const [dataSource, setDataSource] = useState([]);
    const [dataSchema, setDataSchema] = React.useState({});
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [dataSelectedColumns, setDataSelectedColumns] = useState([]);
    const [dataFilterOptions, setDataFilterOptions] = useState({});
    const [dataFilterSchema, setDataFilterSchema] = useState({});
    const [appliedSorts, setAppliedSorts] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [appliedDateFilters, setAppliedDateFilters] = useState([]);
    const [appliedLimit, setAppliedLimit] = useState('');
    const [columnSelectionTree, setColumnSelectionTree] =
        useState(defaultTreeData);
    const [popularColumnList, setPopularColumnList] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loadMoreData, setLoadMoreData] = useState(false);
    const [isPossibleToLoadMoreData, setIsPossibleToLoadMoreData] =
        useState(true);

    // External SPecific states
    const [listOfAppliedFilters, setListOfAppliedFilters] = useState([]);
    const [streamNames, setStreamNames] = React.useState({});
    const [importingStatus, setImportingStatus] = React.useState(false);

    // Loading specific states
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [actionNeeded, setActionNeeded] = useState(false);

    // Error specific states
    const [errorMsg, setErrorMsg] = useState('');
    const [importErrorMsg, setImportErrorMsg] = useState('');

    // Hubspot specific states
    const [enableListSelection, setEnableListSelection] = useState(false);
    const [metaFields, setMetaFields] = useState([]);
    const [selectedList, setSelectedList] = useState('Select');
    const [addFilter, setAddFilter] = useState(false);
    const [columnMapping, setColumnMapping] = useState({});
    const [filterOpenGroupIndex, setFilterOpenGroupIndex] = useState(null);

    // Hubspot specific states
    const [subObjectsListItems, setSubObjectsListItems] = useState([]);

    // Chargebee specific states
    const [needToUpdateSchema, setNeedToUpdateSchema] = useState(false);
    const [subObjectsEnabled, setSubObjectsEnabled] = useState(true);
    const [object, setObject] = useState();
    const [subObject, setSubObject] = React.useState('Select');
    const [subObjects, setSubObjects] = useState([]);
    const [collapseAllObjects, setCollapseAllObjects] = useState(false);
    const [columnSearchText, setColumnSearchText] = useState(null);

    const [selectedFilterColumn, setSelectedFilterColumn] = useState({});
    const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

    // Sql specific states
    const [sqlActiveTab, setSQLActiveTab] = useState(2);
    const [customQuery, setCustomQuery] = useState('');
    const [sqlAiPrompt, setSqlAiPrompt] = useState('');
    const [appliedSqlQuery, setAppliedSqlQuery] = useState('');
    const [sourceTable, setSourceTable] = useState('');
    const [joinTableConfig, setJoinTableConfig] = useState({});
    const [usedSavedQuery, setUsedSavedQuery] = useState(false);

    // Linkedin specific dates
    const [refreshRequired, setRefreshRequired] = useState(false);
    const [refreshButtonClicked, setRefreshButtonClicked] = useState(false);
    const [adAccounts, setAdAccounts] = useState([]);
    const [selectedAdAcc, setSelectedAdAcc] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [campaignStatuses, setCampaignStatuses] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [campaignGroupStatuses, setCampaignGroupStatuses] = useState([]);
    const [campaignGroups, setCampaignGroups] = useState([]);
    const [selectedCampaignGroups, setSelectedCampaignGroups] = useState([]);
    const [selectedCampaignTypes, setSelectedCampaignTypes] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [displayMetrics, setDisplayMetrics] = useState([]);
    const [selectedPivot, setSelectedPivot] = useState('CAMPAIGN');
    const [timeGranularity, setTimeGranularity] = useState('DAILY');
    // Can be Fixed, Since or Last
    const [dateRangeType, setDateRangeType] = useState(DATE_RANGE_TYPE.LAST);
    const [lastXDays, setLastXDays] = useState(180);
    const [startDate, setStartDate] = useState({
        day: sixMonthsAgo.date(),
        month: sixMonthsAgo.month(),
        year: sixMonthsAgo.year(),
    });
    const [endDate, setEndDate] = useState({
        day: oneDayAgo.date(),
        month: oneDayAgo.month(),
        year: oneDayAgo.year(),
    });
    const [linkedinErrorMsg, setLinkedinErrorMsg] = useState('');

    // Import specific states
    const [disableImportButton, setDisableImportButton] = useState(false);
    const [importedRows, setImportedRows] = useState(0);

    // Screen specific states
    const [showColumnActions, setShowColumnActions] = useState(false);

    // session
    const [sessionID, setSessionID] = useState(null);

    // srollbar effects
    const [scrollFields, setScrollFields] = useState(false);

    // extra for now
    const [objectLoadingState, setObjectLoadingState] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);

    // Derived columns states
    const [editDerivedColumn, setEditDerivedColumn] = useState({});
    const [getDerivedColumnPreviewData, setGetDerivedColumnPreviewData] =
        useState(false);
    const [fieldSearchText, setFieldSearchText] = useState('');
    const [formulaSearchText, setFormulaSearchText] = useState('');
    const [editorView, setEditorView] = useState();
    const [derivedColumns, setDerivedColumns] = useState([]);
    const [openDcSelector, setOpenDcSelector] = useState(false);
    const [preDefinedDerivedColumns, setPreDefinedDerivedColumns] = useState(
        []
    );

    // Vlookups
    const [enableSheetInteraction, setEnableSheetInteraction] = useState(false);
    const [vlookupRange, setVlookupRange] = useState({});
    const [vlookupData, setVlookupData] = useState({});
    const [activeWidgetId, setActiveWidgetId] = useState();

    // API Orchestrator
    const [activeField, setActiveField] = useState('');
    const [activeEndpoint, setActiveEndpoint] = useState(0);
    const [activeSubObject, setActiveSubObject] = useState('Select');
    const [pagination, setPagination] = useState({});
    const [totalRecordsToBeFetched, setTotalRecordsToBeFetched] =
        useState(50000);
    const [apiRequestParameters, setApiRequestParameters] = useState([]);
    const [apiPersistentRequestParameters, setApiPersistentRequestParameters] =
        useState([]);
    const [apiCustomFilters, setApiCustomFilters] = useState({});
    const [setUpClicked, setSetUpClicked] = useState(false);
    const [apiTemplateBody, setApiTemplateBody] = useState(null);

    const [templateConfig, setTemplateConfig] = useState({});
    const [parameterAdded, setParameterAdded] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterRemoved, setFilterRemoved] = useState(false);

    const sharedState = {
        numberOfRows,
        dataSource,
        dataSchema,
        dataSelectedColumns,
        dataFilterOptions,
        dataFilterSchema,
        activeConnectionObj,
        newImport,
        errorMsg,
        columnSelectionTree,
        sqlActiveTab,
        appliedSorts,
        appliedFilters,
        appliedLimit,
        isDataLoading,
        sqlAiPrompt,
        appliedSqlQuery,
        sourceTable,
        joinTableConfig,
        usedSavedQuery,
        refreshRequired,
        setRefreshRequired,
        refreshButtonClicked,
        setRefreshButtonClicked,
        dateRangeType,
        lastXDays,
        startDate,
        endDate,
        adAccounts,
        campaigns,
        campaignGroups,
        selectedAdAcc,
        selectedCampaigns,
        selectedCampaignGroups,
        selectedCampaignTypes,
        selectedMetrics,
        displayMetrics,
        selectedPivot,
        timeGranularity,
        linkedinErrorMsg,
        activeField,
        setActiveField,
        setLinkedinErrorMsg,
        setAdAccounts,
        setCampaigns,
        setCampaignGroups,
        setSelectedCampaigns,
        setSelectedCampaignGroups,
        setSelectedCampaignTypes,
        setSelectedAdAcc,
        setSelectedMetrics,
        setDisplayMetrics,
        setSelectedPivot,
        setTimeGranularity,
        setDateRangeType,
        setLastXDays,
        setStartDate,
        setEndDate,
        setUsedSavedQuery,
        setSourceTable,
        setJoinTableConfig,
        setNumberOfRows,
        setDataSource,
        setDataSchema,
        setDataSelectedColumns,
        setDataFilterOptions,
        setDataFilterSchema,
        setActiveConnectionObj,
        setNewImport,
        setErrorMsg,
        setColumnSelectionTree,
        setSQLActiveTab,
        setAppliedSorts,
        setAppliedFilters,
        setAppliedLimit,
        setIsDataLoading,
        setSqlAiPrompt,
        setAppliedSqlQuery,
        objectLoadingState,
        setObjectLoadingState,
        disableInputs,
        setDisableInputs,
        disableImportButton,
        setDisableImportButton,
        customQuery,
        setCustomQuery,
        importedRows,
        setImportedRows,
        popularColumnList,
        setPopularColumnList,
        object,
        setObject,
        subObject,
        setSubObject,
        importErrorMsg,
        setImportErrorMsg,
        needToUpdateSchema,
        setNeedToUpdateSchema,
        subObjectsEnabled,
        setSubObjectsEnabled,
        subObjects,
        setSubObjects,
        showColumnActions,
        setShowColumnActions,
        sessionID,
        setSessionID,
        selectedColumns,
        setSelectedColumns,
        collapseAllObjects,
        setCollapseAllObjects,
        columnSearchText,
        setColumnSearchText,
        loadMoreData,
        setLoadMoreData,
        isPossibleToLoadMoreData,
        setIsPossibleToLoadMoreData,
        appliedDateFilters,
        setAppliedDateFilters,
        scrollFields,
        setScrollFields,
        selectedFilterColumn,
        setSelectedFilterColumn,
        isFilterPopoverOpen,
        setIsFilterPopoverOpen,
        editDerivedColumn,
        setEditDerivedColumn,
        getDerivedColumnPreviewData,
        setGetDerivedColumnPreviewData,
        fieldSearchText,
        setFieldSearchText,
        editorView,
        setEditorView,
        derivedColumns,
        setDerivedColumns,
        openDcSelector,
        setOpenDcSelector,
        formulaSearchText,
        setFormulaSearchText,
        enableSheetInteraction,
        setEnableSheetInteraction,
        vlookupRange,
        setVlookupRange,
        preDefinedDerivedColumns,
        setPreDefinedDerivedColumns,
        vlookupData,
        setVlookupData,
        activeWidgetId,
        setActiveWidgetId,
        campaignStatuses,
        setCampaignStatuses,
        campaignGroupStatuses,
        setCampaignGroupStatuses,
        enableListSelection,
        setEnableListSelection,
        subObjectsListItems,
        setSubObjectsListItems,
        metaFields,
        setMetaFields,
        selectedList,
        setSelectedList,
        addFilter,
        setAddFilter,
        columnMapping,
        listOfAppliedFilters,
        setListOfAppliedFilters,
        setColumnMapping,
        streamNames,
        setStreamNames,
        importingStatus,
        setImportingStatus,
        filterOpenGroupIndex,
        setFilterOpenGroupIndex,
        pagination,
        setPagination,
        totalRecordsToBeFetched,
        setTotalRecordsToBeFetched,
        templateConfig,
        setTemplateConfig,
        parameterAdded,
        setParameterAdded,
        activeEndpoint,
        setActiveEndpoint,
        apiRequestParameters,
        setApiRequestParameters,
        apiCustomFilters,
        setApiCustomFilters,
        actionNeeded,
        setActionNeeded,
        setUpClicked,
        setSetUpClicked,
        activeSubObject,
        setActiveSubObject,
        filterApplied,
        setFilterApplied,
        filterRemoved,
        setFilterRemoved,
        apiPersistentRequestParameters,
        setApiPersistentRequestParameters,
        apiTemplateBody,
        setApiTemplateBody,
    };

    return (
        <DataPreviewContext.Provider value={sharedState}>
            {children}
        </DataPreviewContext.Provider>
    );
};

DataPreviewContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
