import React from 'react';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import ShowAndRearrangeColumns from './ShowAndRearrangeColumns';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';

const SelectedColumnsTree = ({ selectedColumns }) => {
    const { activeConnectionObj, setSelectedColumns } = useDataPreviewContext();
    return (
        <div className='flex flex-grow overflow-y-auto flex-col'>
            <div className='flex justify-between items-center'>
                <div className='flex gap-3'>
                    <p className='m-0 p-0 text-stackit-text-gray text-[12px] font-bold'>
                        {activeConnectionObj?.connectionName ===
                        CONNECTIONS.GOOGLE_SEARCH_CONSOLE
                            ? 'METRICS'
                            : 'FIELDS'}
                    </p>
                    <p className='m-0 p-0 text-stackit-gray text-[12px] font-normal'>
                        {selectedColumns.length}{' '}
                        {activeConnectionObj?.connectionName ===
                        CONNECTIONS.GOOGLE_SEARCH_CONSOLE
                            ? 'metrics'
                            : 'fields'}{' '}
                        selected
                    </p>
                </div>
                <button
                    disabled={selectedColumns.length === 0}
                    onClick={() => setSelectedColumns([])}
                    className={
                        selectedColumns.length === 0
                            ? 'text-stackit-gray bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                            : 'text-stackit-primary bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                    }
                >
                    Clear All
                </button>
            </div>

            <ShowAndRearrangeColumns />
        </div>
    );
};

export default SelectedColumnsTree;
