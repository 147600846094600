import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../screens/Home';
import Auth from '../screens/Auth';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/:connectionId?' exact element={<Auth />} />
                <Route path='/home' exact element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
