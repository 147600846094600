import React, { useEffect } from 'react';
import ApiTemplateConnectionForm from '../components/connections/apiTemplate';
import DataPreview from '../components/data-preview/Components/App';
import ImportProgressCard from '../components/ImportCentral/ImportProgressCard';
import { Drawer } from 'antd';
import { toast, Toaster } from 'react-hot-toast';
import UseSteps from '../components/UseSteps';
import GsheetUrl from '../components/GsheetUrl';
import ExternalConnectionForm from '../components/connections/external/ExternalConnectionForm';
import { useLocation } from 'react-router-dom';
import { CONNECTION_IDS } from '../constants/connection-constants';

function Home() {
    const [showDataPreview, setShowDataPreview] = React.useState(false);
    const [connectionData, setConnectionData] = React.useState({});

    const [statusLoading, setStatusLoading] = React.useState(false);
    const [importLoadingData, setImportLoadingData] = React.useState({
        importRunResponse: {},
    });
    const [activeConnectionLoading, setActiveConnectionLoading] =
        React.useState(false);

    const { state } = useLocation();

    function exceededLimitHandler() {}

    function importSuccessHandler(importData) {
        setImportLoadingData({
            sheetId: importData.sheetId,
        });
        setShowDataPreview(false);
        toast.success('Success');
        setStatusLoading(false);
    }

    function importFailureHandler() {
        setImportLoadingData({});
        setShowDataPreview(false);
        toast.error('Failure');
        setStatusLoading(false);
    }

    function handleShowDataPreview(val) {
        setShowDataPreview(val);
        setImportLoadingData({});
    }

    return (
        <div className='flex h-screen w-screen bg-stackit-primary/10'>
            <Toaster position='top-right' reverseOrder={false} />
            {/* Facebook connection form */}
            <div className='flex relative flex-grow'>
                {/* Data preview like table */}
                {showDataPreview && (
                    <div className='absolute z-20 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  bg-stackit-offwhite p-5 rounded-xl shadow-md flex flex-col'>
                        <DataPreview
                            showDataPreview={handleShowDataPreview}
                            connectionData={connectionData}
                            setStatusLoading={setStatusLoading}
                            setImportLoadingData={setImportLoadingData}
                        />
                    </div>
                )}
                <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center '>
                    {importLoadingData.sheetId && (
                        <GsheetUrl sheetId={importLoadingData.sheetId} />
                    )}
                    <UseSteps />
                </div>
            </div>
            <div className='flex relative flex-col shrink-0 w-[340px] h-screen'>
                {showDataPreview && (
                    <div className='absolute z-20 bg-black/60 flex w-full h-full cursor-not-allowed'></div>
                )}
                {state.connectionId === CONNECTION_IDS.SHOPIFY ? (
                    <ExternalConnectionForm
                        connectionId={CONNECTION_IDS.SHOPIFY}
                        connectionLogo={
                            'https://assets.superjoin.ai/images/shopify-icon.svg'
                        }
                        displayName={'Shopify'}
                        connectionName={'Shopify'}
                        connectionType={'external-non-oauth'}
                        connectionProvider='External'
                        connectionAuthParams={[
                            {
                                fieldName: 'store_name',
                                fieldType: 'input',
                                required: true,
                                regex: '',
                                fieldLabel: 'Store Name',
                                fieldDescription: [
                                    {
                                        header: 'Shopify Store',
                                        body: "The name of your Shopify store found in the URL. For example, if your URL was https://NAME.myshopify.com, then the name would be 'NAME.myshopify.com'.",
                                    },
                                    {
                                        header: 'Example Value',
                                        tag: 'my-store.myshopify.com',
                                    },
                                ],
                            },
                            {
                                fieldName: 'api_password',
                                fieldType: 'password',
                                required: true,
                                regex: '',
                                fieldLabel: 'API Password',
                                fieldDescription: [
                                    {
                                        header: 'API Password',
                                        body: 'The API Password for your private application in the `Shopify` store.',
                                    },
                                ],
                            },
                        ]}
                        showDataPreview={handleShowDataPreview}
                        setConnectionData={setConnectionData}
                    />
                ) : (
                    <ApiTemplateConnectionForm
                        connectionId={'dfedc4d1e738'}
                        connectionLogo={
                            'https://assets.superjoin.ai/images/FacebookAds.png'
                        }
                        displayName={'Facebook Ads'}
                        connectionName={'Facebook Ads'}
                        connectionProvider={'API Orchestrator'}
                        showDataPreview={handleShowDataPreview}
                        setConnectionData={setConnectionData}
                    />
                )}
            </div>
            {/* Import Progress */}
            <Drawer
                placement={'bottom'}
                closable={false}
                open={
                    statusLoading &&
                    Object.keys(importLoadingData || {}).length === 2
                }
                key={'import-tacker'}
                height='fit-content'
            >
                <ImportProgressCard
                    activeConnectionLoading={activeConnectionLoading}
                    startImport={() => {}}
                    exceededLimitEventHandler={exceededLimitHandler}
                    successEventHandler={importSuccessHandler}
                    failureEventHandler={importFailureHandler}
                    setStatusLoading={setStatusLoading}
                    importRunId={importLoadingData.importRunId}
                    importRunResponse={importLoadingData.importRunResponse}
                />
            </Drawer>
        </div>
    );
}

export default Home;
