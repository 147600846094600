import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ColumnSelectionTree from '../common/ColumnSelectionTree';
import ColumnSearch from '../common/ColumnSearch';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';

const ExternalDataPreviewColumnActions = () => {
    const {
        showColumnActions,
        setShowColumnActions,
        collapseAllObjects,
        setCollapseAllObjects,
        columnSearchText,
        setColumnSearchText,
        importingStatus,
    } = useDataPreviewContext();

    const [openDcDrawer, setOpenDcDrawer] = React.useState(false);
    const [editDc, setEditDc] = React.useState(false);
    const [viewOnly, setViewOnly] = React.useState(false);
    const [selectedField, setSelectedField] = React.useState('');
    const [selectedFormula, setSelectedFormula] = React.useState('');

    const handleFieldSelect = (value) => {
        setSelectedField(value);
    };

    const handleFormulaSelect = (value) => {
        setSelectedFormula(value);
    };

    return (
        <div className=' flex flex-col overflow-hidden w-full z-30 relative pb-3'>
            <div className='flex justify-between items-center  flex-shrink  mb-[10px] px-[13.5px] pt-[13.5px]'>
                <div className=' text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                    ADD FIELDS
                </div>
                <div className='flex items-center gap-3'>
                    <div
                        onClick={() =>
                            collapseAllObjects
                                ? {}
                                : setCollapseAllObjects(!collapseAllObjects)
                        }
                        className={
                            collapseAllObjects
                                ? 'text-stackit-gray bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                                : 'text-stackit-primary bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                        }
                    >
                        Collapse All
                    </div>
                    <AiOutlineClose
                        onClick={() => setShowColumnActions(!showColumnActions)}
                        className='cursor-pointer w-5 h-5'
                    />
                </div>
            </div>

            <ColumnSearch
                value={columnSearchText}
                setValue={setColumnSearchText}
                placeholderText={'Search Fields...'}
                classNames={'px-[13.5px] mb-[8px] shrink-0'}
            />

            <ColumnSelectionTree
                columnSearchText={columnSearchText}
                setColumnSearchText={setColumnSearchText}
                setDcDrawer={setOpenDcDrawer}
                setSelectedField={handleFieldSelect}
                setSelectedFormula={handleFormulaSelect}
                setEditDc={setEditDc}
                setViewOnly={setViewOnly}
            />
        </div>
    );
};

export default ExternalDataPreviewColumnActions;
