import React from 'react';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import ApiTemplateDataPreviewConfig from './ApiTemplateDataPreviewConfig';
import GSCDataPreviewConfig from './Components/GoogleSearchConsole/GSCDataPreviewConfig';

function ApiTemplateDataPreviewConfigSwitch() {
    const { activeConnectionObj } = useDataPreviewContext();

    return (
        <>
            {activeConnectionObj.connectionName ===
                CONNECTIONS.GOOGLE_SEARCH_CONSOLE && <GSCDataPreviewConfig />}
            {[
                CONNECTIONS.VITALLY,
                CONNECTIONS.ZOHO_CRM,
                CONNECTIONS.FACEBOOK_ADS,
            ].includes(activeConnectionObj.connectionName) && (
                <ApiTemplateDataPreviewConfig />
            )}
        </>
    );
}

export default ApiTemplateDataPreviewConfigSwitch;
