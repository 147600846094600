import React from 'react';
import { CONNECTIONS } from '../../../constants/connection-constants';
import { DataPreviewContextProvider } from '../Context/DataPreviewContext';
import DataPreviewPanel from './DataPreviewPanel';
import ErrorFallback from './ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../utils/ErrorHandling';

export default function DataPreview({
    showDataPreview,
    connectionData,
    setStatusLoading,
    setImportLoadingData,
}) {
    const [activeConnectionObj, setActiveConnectionObj] = React.useState(
        connectionData || {}
    );

    const [imported, setImported] = React.useState(true);

    if (
        activeConnectionObj.connectionProvider === CONNECTIONS.EXTERNAL ||
        activeConnectionObj.connectionProvider === CONNECTIONS.API_ORCHESTRATOR
    ) {
        return (
            <DataPreviewContextProvider>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={(error, info) =>
                        handleError(error, 'Data Preview Crashed!', {
                            ...info,
                            connectionName: activeConnectionObj?.connectionName,
                        })
                    }
                >
                    <DataPreviewPanel
                        activeConnectionObj={activeConnectionObj}
                        imported={imported}
                        showDataPreview={showDataPreview}
                        setStatusLoading={setStatusLoading}
                        setImportLoadingData={setImportLoadingData}
                    />
                </ErrorBoundary>
            </DataPreviewContextProvider>
        );
    }
    switch (activeConnectionObj?.connectionName) {
        case CONNECTIONS.HUBSPOT:
        case CONNECTIONS.CHARGEBEE:
        case CONNECTIONS.MYSQL:
        case CONNECTIONS.MSSQL:
        case CONNECTIONS.POSTGRES:
        case CONNECTIONS.LINKEDIN:
        case CONNECTIONS.REDSHIFT:
        case CONNECTIONS.SNOWFLAKE:
        case CONNECTIONS.API_ORCHESTRATOR:
            return (
                <DataPreviewContextProvider>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onError={(error, info) =>
                            handleError(error, 'Data Preview Crashed!', {
                                ...info,
                                connectionName:
                                    activeConnectionObj?.connectionName,
                            })
                        }
                    >
                        <DataPreviewPanel
                            activeConnectionObj={activeConnectionObj}
                            imported={imported}
                            showDataPreview={showDataPreview}
                            setStatusLoading={setStatusLoading}
                            setImportLoadingData={setImportLoadingData}
                        />
                    </ErrorBoundary>
                </DataPreviewContextProvider>
            );
        default:
            return <div>Not Found</div>;
    }
}
