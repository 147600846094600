import React, { useEffect, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    createNewUserConnection,
    fetchDataConnectionsDefination,
    getUserConnectionStatus,
    healthCheckConnection,
} from '../../../network/api';
import { PiPhoneFill } from 'react-icons/pi';
import { BiSolidBookAlt } from 'react-icons/bi';
import { FaQuestionCircle } from 'react-icons/fa';
import { Button, Form, Input, Select, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import StackitAccordian from '../../common/StackitAccordian.jsx';
import { RiLoader4Fill } from 'react-icons/ri';
import Loader from '../../common/Loader';
import { SERVER_FUNCTION_CONSTANTS } from '../../../constants/server-function-constants.js';

const stackItIcon = 'https://assets.superjoin.ai/images/stackit-icon.svg';
const connIcon = 'https://assets.superjoin.ai/images/conn-icon.svg';
const secureIcon = 'https://assets.superjoin.ai/images/secure-icon.svg';

const knowMorePage =
    'https://www.superjoin.ai/data-security?utm_source=extension&utm_medium=know-more&utm_campaign=click';

function ApiTemplateConnectionForm({
    connectionId,
    connectionLogo,
    displayName,
    connectionName,
    connectionProvider,
    showDataPreview,
    setConnectionData,
}) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isPending, startTransition] = useTransition();
    const [userConnectionId, setUserConnectionId] = useState(null);

    const [authConfig, setAuthConfig] = useState(null);
    const [loading, setLoading] = useState(false);
    const [waitingForOAuth, setWaitingForOAuth] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [accordionItems, setAccordionItems] = useState([]);
    const [connMetadata, setConnMetadata] = useState({});

    const [connectionParams, setConnectionParams] = useState([]);

    const [pageLoading, setPageLoading] = useState(true);

    const connectionAuthParams =
        connMetadata?.connectionAuth?.authConfig?.params || [];
    const connectionAuthType =
        connMetadata?.connectionAuth?.authType || 'basic';

    const AUTH_WITH_PARAMS = connectionAuthParams.length > 0;

    const FIVE_MINUTES = 5 * 60 * 1000;
    const FIVE_SECONDS = 5 * 1000;

    const fetchConnectionUsageGuide = async () => {
        try {
            const response = await fetchDataConnectionsDefination(connectionId);
            if (response.status === 200) {
                if (response.data && response.data.metadata) {
                    setConnMetadata(response.data.metadata);
                    setAccordionItems([
                        {
                            title: response.data.metadata.howToUse.title
                                ? `${response.data.metadata.howToUse.title}`
                                : `How to get ${displayName} Credentials`,
                            content: (
                                <div className='text-xs font-thin'>
                                    {response.data.metadata.howToUse.steps
                                        .length > 0 &&
                                        response.data.metadata.howToUse.steps.map(
                                            (step, index) => {
                                                return (
                                                    <div key={index}>
                                                        <p className='steps-text'>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                                className='step-highlight'
                                                            >
                                                                Step {index + 1}{' '}
                                                                :{' '}
                                                            </span>
                                                            {step.text}
                                                        </p>
                                                        {step.image && (
                                                            <div
                                                                style={{
                                                                    borderRadius:
                                                                        '5px',
                                                                    border: '2px solid black',
                                                                    overflow:
                                                                        'hidden',
                                                                }}
                                                                className='steps-img-div  flex justify-center items-center relative'
                                                            >
                                                                <img
                                                                    className=' object-contain h-full w-full'
                                                                    src={
                                                                        step.image
                                                                    }
                                                                    alt='step-image'
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            ),
                            disable: !(
                                response.data?.metadata?.howToUse?.steps
                                    ?.length > 0
                            ),
                            icon: <img src={connectionLogo} height={20} />,
                            arrow: true,
                        },
                        {
                            title: 'Schedule a call with us',
                            href: 'https://calendly.com/manan-19/demo-with-stackit',
                            itemType: 'Link',
                            icon: <PiPhoneFill color='green' size={16} />,
                        },

                        {
                            title: `Guide to connect ${displayName}`,
                            href:
                                response.data.metadata.howToUse.guide ||
                                'https://docs.superjoin.ai/integrations',
                            itemType: 'Link',
                            icon: <BiSolidBookAlt color='orange' size={16} />,
                        },
                        {
                            title: 'FAQS',
                            content: (
                                <ol className='m-0 space-y-3 list-disc'>
                                    {response.data.metadata.faqs.map(
                                        (faq, ind) => (
                                            <li
                                                key={ind}
                                                className='font-bold m-0 space-y-2'
                                            >
                                                <div className='text-xs text-stackit-gray-primary'>
                                                    {faq.question}
                                                </div>
                                                <div className='text-xs font-normal text-stackit-gray'>
                                                    {faq.answer}
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ol>
                            ),
                            disable: !(response.data.metadata.faqs.length > 0),
                            icon: <FaQuestionCircle color='' size={16} />,
                            arrow: true,
                        },
                    ]);
                }
            }
        } catch (error) {
            console.error('Error fetching connection metadata:', error);
        } finally {
            setPageLoading(false);
        }
    };

    function handleAuthorizeClick() {
        showDataPreview(false);
        startTransition(() => {
            setErrorMessage('');
            (async () => {
                setLoading(true);
                try {
                    const [activeSheetName, sheetId] = [
                        SERVER_FUNCTION_CONSTANTS.SHEET_NAME,
                        SERVER_FUNCTION_CONSTANTS.SPREADSHEET_ID,
                    ];

                    const newUserConnectionObject = {
                        connectionId,
                        documentInfo: [
                            {
                                spreadsheetId: sheetId,
                                sheetId: activeSheetName,
                            },
                        ],
                        authType: connectionAuthType,
                        authConfig,
                    };

                    if ((connectionParams || {}).length > 0) {
                        newUserConnectionObject.params = connectionParams;
                    }

                    const response = await createNewUserConnection(
                        newUserConnectionObject
                    );
                    if (response.status === 200) {
                        setUserConnectionId(
                            response?.data?.userConnectionObject
                                ?.userConnectionId
                        );

                        const props = {
                            newImport: true,
                            connectionName,
                            spreadsheetId: sheetId,
                            sheetName: activeSheetName,
                            connectionLogo,
                            connectionId,
                            response: response.data,
                            connectionProvider,
                            userConnectionId:
                                response?.data?.userConnectionObject
                                    ?.userConnectionId,
                            displayName:
                                response?.data?.userConnectionObject
                                    .connectionInfo.displayName,
                            identifier:
                                response?.data?.userConnectionObject
                                    ?.connectionAuthConfig
                                    ?.connectionIdentifier || 'stackit',
                            connectionObjects: {},
                            endpoints: [],
                        };

                        if (connectionAuthType === 'oauth') {
                            setWaitingForOAuth(true);
                            const authUrl =
                                response.data.userConnectionObject
                                    .connectionAuthConfig.authConfig
                                    .authenticationURL;
                            window.open(authUrl, '_blank');

                            const interval = setInterval(async () => {
                                let userConnection;
                                try {
                                    userConnection =
                                        await getUserConnectionStatus(
                                            response.data.userConnectionObject
                                                .userConnectionId
                                        );
                                } catch (e) {
                                    setLoading(false);
                                    setWaitingForOAuth(false);
                                    clearInterval(interval);
                                    setTimeout(() => {
                                        setErrorMessage(
                                            'Connection was aborted. Please try again.'
                                        );
                                    }, 1000);
                                    return;
                                }
                                if (
                                    userConnection.data.connectionStatus ===
                                    'CONNECTED'
                                ) {
                                    clearInterval(interval);

                                    const healthCheckResponse =
                                        await healthCheckConnection(
                                            props.userConnectionId,
                                            false
                                        );

                                    if (
                                        healthCheckResponse.status === 200 &&
                                        healthCheckResponse.data
                                    ) {
                                        if (
                                            healthCheckResponse.data
                                                .customObjects
                                        ) {
                                            props.connectionObjects.customObjects =
                                                healthCheckResponse.data
                                                    .customObjects || [];
                                        }

                                        if (
                                            healthCheckResponse.data.endpoints
                                        ) {
                                            props.endpoints =
                                                healthCheckResponse.data
                                                    .endpoints || [];
                                        }
                                    }

                                    showDataPreview(true);
                                    setConnectionData(props);

                                    setLoading(false);
                                    setWaitingForOAuth(false);
                                }
                            }, FIVE_SECONDS);

                            setTimeout(() => {
                                clearInterval(interval);
                                setTimeout(() => {
                                    // setErrorMessage(
                                    //     `We have not received your consent to connect to ${props.displayName}. Please authorise again.`
                                    // );
                                }, 1000);
                                setLoading(false);
                                setWaitingForOAuth(false);
                            }, FIVE_MINUTES);
                            setLoading(false);
                        } else {
                            const healthCheckResponse =
                                await healthCheckConnection(
                                    props.userConnectionId,
                                    false
                                );

                            if (
                                healthCheckResponse.status === 200 &&
                                healthCheckResponse.data
                            ) {
                                if (healthCheckResponse.data.customObjects) {
                                    props.connectionObjects.customObjects =
                                        healthCheckResponse.data
                                            .customObjects || [];
                                }

                                if (healthCheckResponse.data.endpoints) {
                                    props.endpoints =
                                        healthCheckResponse.data.endpoints ||
                                        [];
                                }
                            }

                            // await serverFunctions
                            //     .openDataPreviewSection(props)
                            //     .finally(() => {
                            //         setLoading(false);
                            //         setWaitingForOAuth(false);
                            //     });
                            // navigate('/connections', { state: props });
                        }
                    }
                } catch (error) {
                    setErrorMessage(
                        error.response?.data?.message ||
                            'Something went wrong. Please try again.'
                    );
                    setLoading(false);
                    setWaitingForOAuth(false);
                }
            })();
        });
    }

    useEffect(() => {
        fetchConnectionUsageGuide();
    }, []);

    function handleInputFieldRender(fieldType, authParam) {
        switch (fieldType) {
            case 'select':
                const parsedFieldValues = Object.entries(
                    authParam.fieldValues || {}
                ).map(([key, value]) => ({
                    value: key,
                    label: value.displayName || '',
                }));
                return (
                    <Form.Item
                        label={authParam.fieldName}
                        name={authParam.fieldName}
                        rules={[
                            {
                                required: authParam.required,
                                message: `Please select ${authParam.fieldName}`,
                            },
                        ]}
                        required={authParam.required}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder={`Select ${authParam.fieldName}`}
                            onChange={(value) => {
                                if (authParam?.isRootAuthParam) {
                                    setAuthConfig((prev) => ({
                                        ...prev,
                                        [authParam.fieldPropertyName]: value,
                                    }));
                                } else {
                                    setConnectionParams((prev) => {
                                        const existingIndex = prev.findIndex(
                                            (item) =>
                                                item.key ===
                                                authParam.fieldPropertyName
                                        );

                                        if (existingIndex !== -1) {
                                            // If the key exists, replace the object
                                            return prev.map((item, index) =>
                                                index === existingIndex
                                                    ? {
                                                          key: authParam.fieldPropertyName,
                                                          value,
                                                          type: 'select',
                                                      }
                                                    : item
                                            );
                                        } else {
                                            // If the key doesn't exist, add the new object
                                            return [
                                                ...prev,
                                                {
                                                    key: authParam.fieldPropertyName,
                                                    value,
                                                    type: 'select',
                                                },
                                            ];
                                        }
                                    });
                                }
                            }}
                            options={parsedFieldValues}
                        />
                    </Form.Item>
                );
            default:
                return (
                    <Form.Item
                        label={authParam.fieldName}
                        name={authParam.fieldName}
                        rules={[
                            {
                                required: true,
                                message: `Please enter ${authParam.fieldName}`,
                            },
                        ]}
                        required={true}
                    >
                        <Input
                            placeholder={authParam.fieldName}
                            className={'text-xs rounded-none'}
                            size={'large'}
                            onChange={(e) => {
                                if (authParam?.isRootAuthParam) {
                                    setAuthConfig((prev) => ({
                                        ...prev,
                                        [authParam.fieldPropertyName]:
                                            e.target.value,
                                    }));
                                } else {
                                    setConnectionParams((prev) => {
                                        const existingIndex = prev.findIndex(
                                            (item) =>
                                                item.key ===
                                                authParam.fieldPropertyName
                                        );

                                        if (existingIndex !== -1) {
                                            // If the key exists, replace the object
                                            return prev.map((item, index) =>
                                                index === existingIndex
                                                    ? {
                                                          key: authParam.fieldPropertyName,
                                                          value: e.target.value,
                                                          type: 'text',
                                                      }
                                                    : item
                                            );
                                        } else {
                                            // If the key doesn't exist, add the new object
                                            return [
                                                ...prev,
                                                {
                                                    key: authParam.fieldPropertyName,
                                                    value: e.target.value,
                                                    type: 'text',
                                                },
                                            ];
                                        }
                                    });
                                }
                            }}
                            suffix={
                                <Tooltip title={authParam.description}>
                                    <InfoCircleOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.45)',
                                        }}
                                    />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                );
        }
    }

    return (
        <div className='overflow-x-hidden flex flex-col w-full h-full bg-white shadow-md rounded-lg'>
            {waitingForOAuth && (
                <Loader
                    connectionLogo={connectionLogo}
                    userConnectionId={userConnectionId}
                    connectionId={connectionId}
                />
            )}
            <div className='px-2 py-3 font-semibold shadow-sm mb-5'>
                Superjoin - Data Connector for your SaaS
            </div>
            {pageLoading ? (
                <div
                    className='flex flex-col items-center justify-center'
                    style={{ height: 'calc(100% - 95px' }}
                >
                    <Spin size='large' style={{ color: '#EE8245' }} />
                </div>
            ) : (
                <>
                    <div
                        className='flex flex-col gap-3 p-3'
                        style={{
                            borderBottom: '1px solid #E8E8E8',
                        }}
                    >
                        {/* TODO: Make a common component */}
                        <div className='flex gap-2 justify-center'>
                            <img
                                className='shopify-auth-sec-connection-logos'
                                src={stackItIcon}
                                width={50}
                                height={50}
                            />
                            <img
                                className='shopify-auth-sec-connection-sync'
                                src={connIcon}
                                width={28}
                                height={28}
                            />
                            <img
                                className='shopify-auth-sec-connection-logos'
                                src={connectionLogo}
                                width={50}
                                height={50}
                            />
                        </div>
                        <p className='text-md font-semibold text-center my-6'>
                            {' '}
                            Lets Connect to {displayName}
                        </p>
                    </div>

                    {!AUTH_WITH_PARAMS && (
                        <div className='mt-4 px-2'>
                            <Button
                                block
                                type='primary'
                                className='py-2 px-3 text-lg h-[45px]'
                                onClick={handleAuthorizeClick}
                                loading={loading || isPending}
                                disabled={loading || isPending}
                            >
                                {'Connect'}
                            </Button>
                            {errorMessage && (
                                <p className='mt-4 text-red-400 text-sm'>
                                    {errorMessage}
                                </p>
                            )}
                        </div>
                    )}

                    {AUTH_WITH_PARAMS && (
                        <Form
                            className='flex flex-col'
                            form={form}
                            onFinish={handleAuthorizeClick}
                        >
                            <div className='flex justify-between items-center px-4 mt-3'>
                                <p className=' text-xs text-stackit-gray'>
                                    Enter your connection details.
                                </p>
                                <a
                                    href={
                                        connMetadata?.howToUse?.guide ||
                                        'https://docs.superjoin.ai/integrations'
                                    }
                                    target={'_blank'}
                                    className='flex items-center text-xs gap-x-1 no-underline cursor-pointer font-bold text-stackit-primary'
                                    rel='noreferrer'
                                >
                                    <BiSolidBookAlt />
                                    <span>Guide</span>
                                </a>
                            </div>

                            <div className='px-3 pt-3'>
                                {connectionAuthParams.map((authParam, ind) => {
                                    return (
                                        <div key={ind} className='mb-4'>
                                            {handleInputFieldRender(
                                                authParam?.fieldType,
                                                authParam
                                            )}
                                        </div>
                                    );
                                })}
                            </div>

                            <div
                                className={`fixed z-10 bg-white left-0 right-0 bottom-[55px] p-3`}
                            >
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Button
                                        block
                                        type='primary'
                                        className='auth-btn'
                                        htmlType='submit'
                                        loading={loading || isPending}
                                        disabled={loading || isPending}
                                    >
                                        {'Authorize'}
                                    </Button>
                                    {errorMessage && (
                                        <p className='error-msg'>
                                            {errorMessage}
                                        </p>
                                    )}
                                </Form.Item>
                            </div>
                        </Form>
                    )}

                    {/* Moved Basic Auth params to normal config */}

                    {/* Accordion section */}
                    <div className='flex flex-col my-6 space-y-5 px-2'>
                        <div>
                            <h4 className='m-0 font-semibold text-sm'>
                                Step by step guide
                            </h4>
                            <span className='text-xs text-stackit-gray'>
                                Here&apos;s a list of resources to help you
                                connect to your {displayName}
                            </span>
                        </div>
                        {accordionItems && accordionItems.length > 0 ? (
                            <StackitAccordian
                                items={accordionItems}
                                keepFirstOpen={false}
                            />
                        ) : (
                            <div className='flex justify-center items-center w-full'>
                                <RiLoader4Fill className='animate-spin' />
                            </div>
                        )}
                    </div>

                    {/* Know more section */}
                    {/* TODO: Move to a separate common component */}
                    {/*<div className="shopify-auth-sec-bottom mt-3 pb-[50px]" style={{ marginBottom: "80px" }}>*/}
                    {/*	<img src={secureIcon} alt="secure-icon" className="shopify-auth-sec-bottom-privacy-ico self-start pt-[10px]" width={28} height={28} />*/}
                    {/*	<p className="shopify-auth-sec-bottom-privacy-msg">*/}
                    {/*		Superjoin encrypts your credentials and personal data with the highest industry standards.{" "}*/}
                    {/*		<a href={knowMorePage} target={"_blank"} rel="noreferrer">*/}
                    {/*			Know more*/}
                    {/*		</a>*/}
                    {/*	</p>*/}
                    {/*</div>*/}
                </>
            )}
        </div>
    );
}

export default ApiTemplateConnectionForm;
