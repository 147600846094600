import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const DiscriptionToolTip = ({ discription }) => {
    return (
        <div className='border p-1 m-0 flex flex-col space-y-1'>
            {discription ? (
                Array.isArray(discription) &&
                discription.length > 0 &&
                discription.map((item, index) => {
                    if (item.header && item.body) {
                        return (
                            <div
                                className='p-0 m-0 flex flex-col space-y-2'
                                key={index}
                            >
                                <div className='font-bold '>{item.header}</div>
                                <div className='text-xs py-0'>{item.body} </div>
                            </div>
                        );
                    }
                    if (item.header && item.tag) {
                        return (
                            <div
                                className='p-0 m-0 flex flex-col space-y-2 '
                                key={index}
                            >
                                <div className='font-bold '>{item.header}</div>
                                <Tag
                                    color='#f50'
                                    className='relative max-w-fit whitespace-pre-wrap'
                                >
                                    {item.tag}
                                </Tag>
                            </div>
                        );
                    }
                    return null;
                })
            ) : (
                <div>Required</div>
            )}
        </div>
    );
};

export default DiscriptionToolTip;

DiscriptionToolTip.prototype = {
    discription: PropTypes.array.isRequired.apply,
};
