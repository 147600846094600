import React from 'react';
import ExternalSortSelection from './ExternalSortSelection';
import ExternalLimitSelection from './ExternalLimitSelection';
import '../assets/handlePreview.css';
import ExternalDataPreviewFilters from './ExternalDataPreviewFilters';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';

export default function ExternalDataPreviewTable() {
    const { activeConnectionObj } = useDataPreviewContext();

    return (
        <div className={'sql-data-preview-table-container'}>
            <div
                style={{ padding: '2px' }}
                className={
                    'sql-filter-section-new rounded-tl-[8px] flex items-center justify-between '
                }
            >
                <div className='flex items-center '>
                    <ExternalDataPreviewFilters />
                    <ExternalSortSelection />
                    <ExternalLimitSelection />
                </div>
            </div>
            <div className='flex h-full justify-center w-full items-center'>
                <div className='flex justify-center gap-y-4 items-center w-[50%] flex-col'>
                    <img
                        src='https://assets.superjoin.ai/images/external-data-preview-table.svg'
                        alt='loading'
                    />

                    <>
                        <div className='text-center font-bold text-[12px]'>
                            {activeConnectionObj.connectionName} is in beta!
                        </div>
                        <div className='text-center text-[12px]'>
                            Use the right side pane to customise which data
                            fields you want. Use the filter options on the top
                            to selectively choose your data.
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}
