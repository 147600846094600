import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataPreviewContext } from '../Context/DataPreviewContext';
import './assets/index.css';
import './assets/handlePreview.css';
import DataPreviewConfig from './DataPreviewConfig';
import DataPreviewTableWrapper from './DataPreviewTableWrapper';
import DataPreviewHeaderWrapper from './DataPreviewHeaderWrapper';
import DataPreviewColumnActions from './DataPreviewColumnActions';
import { IoMdClose } from 'react-icons/io';

export default function DataPreviewPanel(props) {
    const {
        setActiveConnectionObj,
        setNewImport,
        setSQLActiveTab,
        setCustomQuery,
        enableSheetInteraction,
    } = useDataPreviewContext();

    useEffect(() => {
        setActiveConnectionObj(props.activeConnectionObj);
        setNewImport(props.imported === true);
        if (
            props.activeConnectionObj?.editSavedQuery ||
            props.activeConnectionObj?.editQueryAndImport ||
            props.activeConnectionObj?.importConfigObject
                ?.connectionImportConfig?.configType === 'customQuery'
        ) {
            setSQLActiveTab(2);
            setCustomQuery(props.activeConnectionObj.query);
        }

        if (
            props.activeConnectionObj?.importConfigObject
                ?.connectionImportConfig?.configType === 'visualBuilder'
        )
            setSQLActiveTab(1);

        if (props.activeConnectionObj?.openCustomQuery) {
            setSQLActiveTab(2);
        }
    }, []);

    return (
        <div className='flex flex-col w-[1055px] max-w-[1055px] overflow-auto'>
            <div className='flex justify-end mb-2 '>
                <IoMdClose
                    className='cursor-pointer'
                    size={20}
                    onClick={() => props.showDataPreview(false)}
                />
            </div>
            <div
                className={
                    enableSheetInteraction ? 'hidden ' : 'dp-head-container'
                }
            >
                <DataPreviewHeaderWrapper
                    setStatusLoading={props.setStatusLoading}
                    setImportLoadingData={props.setImportLoadingData}
                />
            </div>
            <div className={enableSheetInteraction ? 'hidden' : 'main'}>
                <DataPreviewTableWrapper />
                <DataPreviewColumnActions />
                <DataPreviewConfig />
            </div>
        </div>
    );
}

DataPreviewPanel.propTypes = {
    activeConnectionObj: PropTypes.shape({
        connectionName: PropTypes.string,
        connectionId: PropTypes.string,
        userConnectionId: PropTypes.string,
        connectionLogo: PropTypes.string,
        importConfigId: PropTypes.string,
        selectedObject: PropTypes.string,
        displayName: PropTypes.string,
        connectionObjects: PropTypes.shape({
            objects: PropTypes.arrayOf(PropTypes.string),
        }),
        identifier: PropTypes.string,
    }),
    imported: PropTypes.bool,
};
