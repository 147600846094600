import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import SingleObjectColumns from './SingleObjectColumns';
import { CONNECTIONS } from '../../../../constants/connection-constants';

function ColumnSelectionTree({
    isDcFieldSelector = false,
    setColumnSearchText,
    columnSearchText,
    setDcDrawer = undefined,
    setSelectedField = undefined,
    setSelectedFormula = undefined,
    setEditDc = undefined,
    setViewOnly = undefined,
}) {
    const {
        columnSelectionTree,
        object,
        isDataLoading,
        derivedColumns,
        preDefinedDerivedColumns,
        activeConnectionObj,
    } = useDataPreviewContext();

    const showPreDefinedDerivedColumns =
        !isDcFieldSelector &&
        preDefinedDerivedColumns &&
        preDefinedDerivedColumns.length > 0;
    const showDerivedColumns =
        !isDcFieldSelector && derivedColumns && derivedColumns.length > 0;

    useEffect(() => {
        setColumnSearchText('');
    }, [object]);

    return (
        <div
            className={`border-solid border-stackit-secondary-border border-[1px] rounded-md mt-[3px]  overflow-hidden flex flex-grow  bg-white -z-10 ${
                !isDcFieldSelector ? ' mx-[13.5px]' : ' max-h-[130px] h-[130px]'
            } ${
                (activeConnectionObj.connectionName ===
                    CONNECTIONS.API_ORCHESTRATOR ||
                    activeConnectionObj.connectionProvider ===
                        CONNECTIONS.API_ORCHESTRATOR) &&
                'mb-3.5'
            }`}
        >
            {isDataLoading ? (
                <div className='flex flex-col w-full mt-2 px-2 gap-2'>
                    {Array(3)
                        .fill()
                        .map((_, index) => (
                            <Skeleton.Input
                                key={index}
                                size={'small'}
                                active={true}
                                block={true}
                            />
                        ))}
                </div>
            ) : (
                <div className='flex flex-col overflow-y-auto w-full'>
                    {(activeConnectionObj.connectionName ===
                        CONNECTIONS.CHARGEBEE ||
                        activeConnectionObj.connectionName ===
                            CONNECTIONS.HUBSPOT) && (
                        <>
                            {showPreDefinedDerivedColumns && (
                                <SingleObjectColumns
                                    key={`stackit-fields`}
                                    column={{
                                        key: 'FX',
                                        title: 'Superjoin Fields',
                                        children: preDefinedDerivedColumns,
                                    }}
                                    position={0}
                                    searchText={columnSearchText}
                                    object={object}
                                    isChild={false}
                                    isStackitField={true}
                                    isDcFieldSelector={isDcFieldSelector}
                                    setDcDrawer={setDcDrawer}
                                    setSelectedField={setSelectedField}
                                    setSelectedFormula={setSelectedFormula}
                                    setViewOnly={setViewOnly}
                                />
                            )}
                            {showDerivedColumns && (
                                <SingleObjectColumns
                                    key={`calculated_field`}
                                    column={{
                                        key: 'FX',
                                        title: 'Your Calculated Fields',
                                        children: derivedColumns,
                                    }}
                                    position={0}
                                    searchText={columnSearchText}
                                    object={object}
                                    isChild={false}
                                    isDcField={true}
                                    isDcFieldSelector={isDcFieldSelector}
                                    setDcDrawer={setDcDrawer}
                                    setSelectedField={setSelectedField}
                                    setSelectedFormula={setSelectedFormula}
                                    setEditDc={setEditDc}
                                />
                            )}
                        </>
                    )}
                    {columnSelectionTree.map((column, index) => {
                        return (
                            <SingleObjectColumns
                                key={index}
                                column={column}
                                position={index}
                                searchText={columnSearchText}
                                object={object}
                                isDcFieldSelector={isDcFieldSelector}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default ColumnSelectionTree;
