import { logger } from "../configs/logger-config";
import { toast } from "sonner";

function handleError(error = null, errorMessage = null, errorMetadata = {}) {
	let errorObject = null;
	if (error instanceof Error) {
		errorObject = {
			devMessage: errorMessage,
			message: error?.message,
			additionalErrorMetadata: errorMetadata,
			stack: error?.stack,
		};
	} else {
		errorObject = {
			devMessage: errorMessage,
			additionalErrorMetadata: errorMetadata,
			stack: error,
		};
	}

	logger.error(
		`DEV MESSAGE -> ${errorObject?.devMessage} ERR MESSAGE -> ${errorObject?.message} METADATA -> ${JSON.stringify(
			errorObject?.additionalErrorMetadata
		)} ERROR STACK -> ${errorObject?.stack}`
	);

	return errorObject;
}

function handleErrorWithToast(error = null, errorMessage = null, errorMetadata = {}) {
	const errorObj = handleError(error, errorMessage, errorMetadata);
	toast.error("Error", { description: errorObj.devMessage, closeButton: true, position: "top-center" });
}

export { handleError, handleErrorWithToast };
