import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { toast, Toaster } from 'react-hot-toast';
import { sandboxUserLogin } from '../network/api';
import { useNavigate, useParams } from 'react-router-dom';

const Auth = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { connectionId } = useParams();

    const onFinish = async (values) => {
        try {
            // Simulating an API call
            const response = await sandboxUserLogin(values);

            if (response.status === 200) {
                sessionStorage.setItem(
                    'stackit-google-user-jwt',
                    response.data.token
                );
                toast.success('Login successful!');
                navigate('/home', {
                    state: {
                        connectionId: connectionId || 'dfedc4d1e738',
                    },
                });
            } else {
                throw new Error(response.error || 'Authentication failed');
            }
        } catch (error) {
            toast.error(error.message || 'Login failed. Please try again.');
        }
    };

    return (
        <div className='min-h-screen flex items-center justify-center bg-gray-100'>
            <Toaster position='top-right' reverseOrder={false} />
            <div className='bg-white p-8 rounded-lg shadow-md w-96'>
                <h2 className='text-2xl font-bold mb-6 text-center'>Login</h2>
                <Form
                    form={form}
                    name='login'
                    onFinish={onFinish}
                    layout='vertical'
                >
                    <Form.Item
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                            {
                                min: 3,
                                message:
                                    'Username must be at least 3 characters long',
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder='Username'
                            className='rounded-md'
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message:
                                    'Password must be at least 6 characters long',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder='Password'
                            className='rounded-md'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='w-full bg-blue-500 hover:bg-blue-600'
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Auth;
