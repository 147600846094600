import React from 'react';
import { CONNECTIONS } from '../../../constants/connection-constants';
import { useDataPreviewContext } from '../Context/DataPreviewContext';
import ApiTemplateDataPreviewConfigSwitch from './ApiTemplate/ApiTemplateDataPreviewConfigSwitch';
import ExternalDataPreviewConfig from './External/ExternalDataPreviewConfig';

export default function DataPreviewConfig() {
    const { activeConnectionObj } = useDataPreviewContext();

    return (
        <>
            <div
                className={
                    activeConnectionObj.connectionName ===
                        CONNECTIONS.CHARGEBEE ||
                    activeConnectionObj.connectionName ===
                        CONNECTIONS.HUBSPOT ||
                    activeConnectionObj.connectionProvider ===
                        CONNECTIONS.EXTERNAL ||
                    activeConnectionObj.connectionName ===
                        CONNECTIONS.API_ORCHESTRATOR ||
                    activeConnectionObj.connectionProvider ===
                        CONNECTIONS.API_ORCHESTRATOR
                        ? 'dp-right-container rounded-tr-[8px] rounded-br-[8px] bg-stackit-secondary-orange flex h-[500px] w-[250px]'
                        : 'dp-right-container-old h-[89%] w-[35%]'
                }
            >
                {activeConnectionObj.connectionProvider ===
                    CONNECTIONS.API_ORCHESTRATOR && (
                    <ApiTemplateDataPreviewConfigSwitch />
                )}
                {activeConnectionObj.connectionProvider ===
                    CONNECTIONS.EXTERNAL && <ExternalDataPreviewConfig />}
            </div>
        </>
    );
}
