import {
    placeholderTextEffect,
    placeholderTextState,
} from '../extensions/Widgets/vlookupHandler';
import { CONNECTIONS } from '../../../../../../constants/connection-constants';
import {
    readableColumnName,
    readableColumnNameHubspot,
} from '../../../../../../utils/jsUtils';

export function getColumnMapping(columnSelectionTree) {
    let columnMapping = [];
    columnSelectionTree.forEach((column) => {
        if (column.children) {
            columnMapping = columnMapping.concat(
                getColumnMapping(column.children)
            );
        } else {
            columnMapping.push({
                key: column.key,
                title: column?.title,
            });
        }
    });

    return columnMapping;
}

export function getTransformedFormula(formula, vlookupData) {
    let transformed = formula.replace(/(\[\{.*?\}\])/g, (match) => {
        return match.replace(/(\[\{|\}\])/g, '');
    });

    // // Next, replace the [[widget id="<some-id>"]] placeholders
    transformed = transformed.replace(
        /\[\[widget id="(.*?)"\]\]/g,
        (match, id) => {
            // Replace with value from the widgetValues object, or keep the original match if not found
            return getVlookupFunctionFromObject(vlookupData[`${id}`]) || match;
        }
    );

    // Trim whitespaces
    transformed = transformed.trim();

    // Prepend "=" if it's not already there
    if (!transformed.startsWith('=')) {
        transformed = `=${transformed}`;
    }

    return transformed;
}

function getVlookupFunctionFromObject(data) {
    let vlookupFunction = 'VLOOKUP(';
    vlookupFunction += `${data?.fieldData ?? ''}, `;
    vlookupFunction += `${data?.rangeData ?? ''}, `;
    vlookupFunction += `${data?.indexData ?? ''}, `;
    vlookupFunction += `${data?.sortData ?? ''})`;
    return vlookupFunction;
}

export function applyReverseTransformationOnFormula(
    formula,
    usedColumns,
    setData,
    view
) {
    let newFormula = formula;
    // sort usedColumns in descending order of their lengths
    usedColumns.sort((a, b) => b.length - a.length);
    usedColumns.forEach((column) => {
        // Escape regex special characters
        const escapedColumn = column.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        // Match all occurrences of the column name, respecting word boundaries
        const regex = new RegExp(`\\b${escapedColumn}\\b`, 'g'); // Adjusted line
        newFormula = newFormula.replace(regex, `[{${column}}]`); // Replace with encapsulated version
    });

    // convert Vlookup to widget format
    newFormula = newFormula.replace(
        /VLOOKUP\(([^,]+),\s*([^,]+),\s*([^,]+),\s*([^)]+)\)/g,
        (match, item1, item2, item3, item4) => {
            const params = [item1, item2, item3, item4].map((s) => s.trim()); // Now directly splitting based on captured groups
            const widgetId = Math.random().toString(36).substring(7);

            const data = {
                fieldData: removeBracesFromString(params[0]) || '',
                rangeData: removeBracesFromString(params[1]) || '',
                indexData: removeBracesFromString(params[2]) || null,
                sortData: removeBracesFromString(params[3]) || 'false',
            };

            if (view) {
                view.dispatch({
                    effects: placeholderTextEffect.of({
                        ...view.state.field(placeholderTextState),
                        [widgetId]: {
                            ...view.state.field(placeholderTextState)[widgetId],
                            ...data,
                        },
                    }),
                });
            }

            setData((prev) => ({
                ...prev,
                [widgetId]: data,
            }));
            return `[[widget id="${widgetId}"]]`;
        }
    );

    return newFormula;
}

function removeBracesFromString(str) {
    // Check if the string starts with '{{' and ends with '}}'
    if (/^\[\{.*\}\]$/.test(str)) {
        // Remove the leading '{{' and trailing '}}' from the string
        return str.substring(2, str.length - 2);
    }
    // Return the original string if it does not match the condition
    return str;
}

export function removeItemFromList(list, key) {
    // remove from list whee list item.key is equal to item.key
    return list.filter((listItem) => listItem.key !== key);
}

export function updateItemInList(list, key, newItem) {
    return list.map((listItem) => {
        if (listItem.key === key) {
            return newItem;
        }
        return listItem;
    });
}

export function getColumnTitle(connName, mapping, key) {
    if (connName === CONNECTIONS.HUBSPOT) {
        return readableColumnNameHubspot(mapping, key);
    }
    return readableColumnName(key);
}
