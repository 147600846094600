import React from "react";
import { Popover, Button, InputNumber, Badge } from "antd";
import { TiCancel } from "react-icons/ti";
import { useDataPreviewContext } from "../../Context/DataPreviewContext";

export default function ExternalLimitSelection() {
	const { appliedLimit, setAppliedLimit, selectedColumns, importingStatus, isDataLoading } = useDataPreviewContext();

	const [limitConfig, setLimitConfig] = React.useState(appliedLimit);
	const [open, setOpen] = React.useState(false);

	const handleOpenChange = (newOpen) => setOpen(newOpen);

	return (
		<Popover
			content={
				isDataLoading ? (
					<div className="p-2">fetching...</div>
				) : (
					<div>
						{selectedColumns.length > 0 && importingStatus === false ? (
							<div className="flex flex-col max-w-[250px] p-4">
								<div className="font-semibold text-stackit-gray-primary">Rows Limits</div>
								<div className="text-[12px] text-stackit-gray w-full ">
									When fetching data from a source, you can choose to get up to 20,000 rows at once. If you don't specify this limit, the
									system will automatically fetch all available data until it reaches the maximum of 20,000 rows.
								</div>
								<div className="py-1 text-xs font-semibold my-1">Rows to be fetched</div>
								<InputNumber
									placeholder={"Enter Limit"}
									style={{ width: "100%" }}
									value={limitConfig.length !== 0 ? limitConfig : ""}
									onChange={(value) => setLimitConfig(value || "")}
									stringMode
									size="small"
									type="number"
									min={0}
									max={20000}
									className="p-0"
								/>

								<p className="text-[12px] font-bold">Note: Limit will be applied while importing</p>
								<div className={"sort-section-action-button-section flex"}>
									<Button
										type={"link"}
										size={"small"}
										style={{ color: "black" }}
										onClick={() => {
											setLimitConfig("");
											setAppliedLimit("");
										}}>
										Clear
									</Button>

									<Button
										className="bg-stackit-primary cursor-pointer hover:text-stackit-secondary border-none  text-stackit-secondary text-xs"
										size={"small"}
										onClick={() => {
											handleOpenChange();
											setAppliedLimit(limitConfig);
										}}>
										Apply
									</Button>
								</div>
							</div>
						) : (
							<div className="text-xs text-stackit-gray-primary p-2 font-semibold">No Column is Selected</div>
						)}
					</div>
				)
			}
			trigger="click"
			placement="bottom"
			open={open}
			onOpenChange={handleOpenChange}>
			<button className="ml-2 min-w-fit border-[1px] border-solid border-stackit-gray bg-stackit-background-2 rounded-full cursor-pointer px-3 py-1.5">
				<div className="flex m-0 p-0 items-center text-stackit-text-gray font-[500]">
					<div className="flex items-center gap-x-1">
						{appliedLimit.length !== 0 ? <Badge count={parseInt(appliedLimit)} color={"#ee8245"} overflowCount={1000000} /> : <TiCancel />}
						<p className="m-0 p-0 flex gap-1 items-center text-[12px]"> Limit</p>
					</div>
				</div>
			</button>
		</Popover>
	);
}
