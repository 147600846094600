import React, { useEffect, useState } from "react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";

import SortableColumn from "./SortableColumn";
import { useDataPreviewContext } from "../../Context/DataPreviewContext";

function SortableSelectedColumns() {
	const { selectedColumns, setSelectedColumns } = useDataPreviewContext();

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragEnd(event) {
		if (event && event.active && event.over) {
			const { active, over } = event;

			if (active.id !== over.id) {
				setSelectedColumns((items) => {
					const oldIndex = items.indexOf(active.id);
					const newIndex = items.indexOf(over.id);

					return arrayMove(items, oldIndex, newIndex);
				});
			}
		}
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
			<SortableContext items={selectedColumns} strategy={verticalListSortingStrategy}>
				{selectedColumns.map((data, index) => (
					<SortableColumn key={index} data={data} />
				))}
			</SortableContext>
		</DndContext>
	);
}

export default SortableSelectedColumns;
