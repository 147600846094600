import React, { useState } from "react";
import { Popover } from "antd";
import { FiFilter } from "react-icons/fi";
import { RiFilter2Fill } from "react-icons/ri";
import FilterSelection from "./FilterSelection";

function Filters({ activeConnectionObj }) {
	const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState([]);

	return (
		<div>
			<Popover
				placement={"bottomLeft"}
				trigger={"click"}
				content={<FilterSelection activeConnectionObj={activeConnectionObj} appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />}
				open={filterPopoverOpen}
				onOpenChange={(value) => setFilterPopoverOpen(value)}>
				<button className="ml-2 min-w-fit border-[1px] border-solid border-stackit-gray bg-stackit-background-2 rounded-full cursor-pointer px-3 py-1.5">
					<div className="flex m-0 p-0 items-center text-stackit-text-gray font-[500]">
						{appliedFilters && appliedFilters.length > 0 ? (
							<div className="flex items-center">
								<RiFilter2Fill style={{ marginRight: "4px" }} className="text-stackit-text-gray w-4 h-4" />
								<p className="m-0 p-0 flex gap-1 items-center text-[12px]">
									{appliedFilters.length} {appliedFilters.length > 1 ? "Filters" : "Filter"} Applied
								</p>
							</div>
						) : (
							<div className="flex items-center">
								<FiFilter style={{ marginRight: "4px" }} className="text-stackit-text-gray w-4 h-4" />
								<p className="m-0 p-0 flex gap-1 items-center text-[12px]">Filter</p>
							</div>
						)}
					</div>
				</button>
			</Popover>
		</div>
	);
}

export default Filters;
