import React from "react";
import { IoSearchSharp } from "react-icons/io5";

function ColumnSearch({ value, setValue, classNames = "", placeholderText = "Search" }) {
	return (
		<div className={classNames}>
			<div className="relative">
				<div className="absolute left-2 inset-y-0 start-0 flex items-center ps-5 pointer-events-none text-stackit-gray">
					<IoSearchSharp />
				</div>
				<input
					type="search"
					id="default-search"
					value={value}
					className="flex w-full pl-7 pr-3 py-1.5 text-sm text-gray-900 border-[1px] border-stackit-secondary-border border-solid rounded-lg focus:outline-none placeholder:text-stackit-gray/70 "
					placeholder={placeholderText}
					onChange={(e) => setValue(e.target.value)}
				/>
			</div>
		</div>
	);
}

export default ColumnSearch;
