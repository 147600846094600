import React, { useState, useCallback, useMemo } from 'react';
import {
    Cascader,
    Popover,
    Space,
    Select,
    Input,
    Button,
    Alert,
    DatePicker,
} from 'antd';
import { FiFilter } from 'react-icons/fi';
import { CloseOutlined } from '@ant-design/icons';
import { MdClose, MdDone } from 'react-icons/md';
import { RiFilter2Fill } from 'react-icons/ri';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { getIconForColumnType } from '../../../../utils/jsUtils';

const ExternalDataPreviewFilters = () => {
    const {
        columnSelectionTree,
        appliedFilters,
        dataFilterOptions,
        selectedColumns,
        listOfAppliedFilters,
        setListOfAppliedFilters,
        isDataLoading,
    } = useDataPreviewContext();

    const [showFiltersInputSection, setShowFiltersInputSection] =
        useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);
    const [appliedObjectFilters, setAppliedObjectFilters] = useState({});

    function generateTitleForFilter(data) {
        return (
            <div className='flex gap-3 items-center'>
                <p className='m-0 p-0 text-stackit-primary'>
                    {getIconForColumnType(data.type ?? 'text')}
                </p>
                <p className='m-0 p-0 text-black'>{data.title}</p>
            </div>
        );
    }

    const cascaderOptions = useMemo(() => {
        const children = columnSelectionTree?.[0]?.children ?? [];
        const filteredColumns = children.filter((item) =>
            selectedColumns.includes(item.key)
        );
        const mappedColumns = filteredColumns.map((item) => ({
            value: item.title,
            title: generateTitleForFilter(item),
        }));

        return [
            {
                children: mappedColumns,
                key: columnSelectionTree?.[0]?.key,
                title: columnSelectionTree?.[0]?.label,
                value: columnSelectionTree?.[0]?.value,
            },
        ];
    }, [columnSelectionTree, selectedColumns]);

    const handlePopoverOpenChange = useCallback((newOpen) => {
        setPopoverOpen(newOpen);
    }, []);
    const handleObjectSelection = useCallback(
        (value) => {
            if (Array.isArray(value)) {
                const selectedKey = `${value[0]}.${value[1]}`;
                const filterObject = columnSelectionTree[0].children.find(
                    (item) => item.key === selectedKey
                );
                if (filterObject) {
                    setSelectedObject({
                        filterOptions: dataFilterOptions[filterObject.type],
                        ...filterObject,
                    });
                    setAppliedObjectFilters({
                        columnName: value[1],
                        parentName: value[0],
                    });
                }
            }
        },
        [columnSelectionTree, dataFilterOptions]
    );

    const handleChangeFilterOption = useCallback(
        (selectedFilt) => {
            const option = selectedObject.filterOptions.find(
                (filt) => filt.value === selectedFilt
            );
            if (option) {
                setAppliedObjectFilters((prev) => ({
                    ...prev,
                    enableInput: option.enableInput,
                    value: selectedFilt,
                }));
            }
        },
        [selectedObject]
    );

    const handleApplyFilter = useCallback(() => {
        setListOfAppliedFilters((prev) => [...prev, appliedObjectFilters]);
        setSelectedObject(null);
        setAppliedObjectFilters({});
    }, [appliedObjectFilters]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Popover
                content={
                    isDataLoading ? (
                        <div className='p-2'>fetching...</div>
                    ) : (
                        <div>
                            {selectedColumns.length > 0 ? (
                                <div className='p-2 flex flex-col gap-y-2 relative'>
                                    {listOfAppliedFilters.length > 0 &&
                                        listOfAppliedFilters.map(
                                            (filt, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'space-between',
                                                        alignItems: 'center',
                                                        padding:
                                                            '4px!important',
                                                        border: '1px solid #E08752',
                                                    }}
                                                    className='p-2 mb-2 rounded-lg bg-[#FDF5E6] ml-[2px]'
                                                >
                                                    <span
                                                        style={{
                                                            width: '100%',
                                                            fontWeight: '400',
                                                            fontSize: '13px',
                                                            marginRight: '10px',
                                                        }}
                                                        className='text-black'
                                                    >
                                                        {`[${filt.parentName}]`}
                                                        {'  '}
                                                        {filt.columnName}{' '}
                                                        <span
                                                            style={{
                                                                color: '#84848D',
                                                            }}
                                                            className='mx-1 font-semibold'
                                                        >
                                                            {filt.value}{' '}
                                                        </span>
                                                        {filt.filterValue}
                                                    </span>
                                                    <CloseOutlined
                                                        style={{
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                        }}
                                                        className='w-3 h-3'
                                                        onClick={() =>
                                                            setListOfAppliedFilters(
                                                                (prev) =>
                                                                    prev.filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.value !==
                                                                            filt.value
                                                                    )
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    <div className='flex gap-x-2'>
                                        {showFiltersInputSection &&
                                            columnSelectionTree && (
                                                <Space>
                                                    <Cascader
                                                        options={
                                                            cascaderOptions
                                                        }
                                                        fieldNames={{
                                                            label: 'title',
                                                        }}
                                                        value={
                                                            selectedObject?.key?.split(
                                                                '.'
                                                            ) || []
                                                        }
                                                        onChange={
                                                            handleObjectSelection
                                                        }
                                                        displayRender={(
                                                            label,
                                                            selectedOptions
                                                        ) =>
                                                            `[${label[0]}] ${
                                                                selectedOptions[
                                                                    label.length -
                                                                        1
                                                                ].value
                                                            }`
                                                        }
                                                        disabled={
                                                            !selectedColumns.length
                                                        }
                                                        multiple={false}
                                                    />
                                                </Space>
                                            )}
                                        {selectedObject &&
                                        selectedObject.type !== 'object' &&
                                        Array.isArray(
                                            selectedObject.filterOptions
                                        ) ? (
                                            <Select
                                                open={filterOptionsOpen}
                                                autoFocus={true}
                                                placeholder='Select Filter'
                                                value={{
                                                    key: appliedObjectFilters.value,
                                                    value: appliedObjectFilters.value,
                                                    label: appliedObjectFilters.value
                                                        ? appliedObjectFilters.value
                                                              .replace(
                                                                  /^[^_]*_/,
                                                                  ''
                                                              )
                                                              .replace(
                                                                  /_/g,
                                                                  ' '
                                                              )
                                                        : '',
                                                }}
                                                onChange={
                                                    handleChangeFilterOption
                                                }
                                                options={
                                                    selectedObject.filterOptions
                                                }
                                                onDropdownVisibleChange={
                                                    setFilterOptionsOpen
                                                }
                                                style={{
                                                    minWidth: '180px',
                                                    display: 'flex',
                                                }}
                                            />
                                        ) : (
                                            <Select
                                                placeholder='No Supported Filters for Selected Column'
                                                disabled={true}
                                            />
                                        )}
                                        {appliedObjectFilters.enableInput && (
                                            <div className='flex items-center gap-x-2'>
                                                {appliedObjectFilters.value.split(
                                                    '_'
                                                )[0] === 'date' ? (
                                                    <DatePicker
                                                        onChange={(e) =>
                                                            setAppliedObjectFilters(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    filterValue:
                                                                        e.format(
                                                                            'DD-MM-YYYY'
                                                                        ),
                                                                })
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <Input
                                                        type='text'
                                                        className='h-full'
                                                        value={
                                                            appliedObjectFilters?.filterValue ??
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            setAppliedObjectFilters(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    filterValue:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            )
                                                        }
                                                        placeholder='Enter value'
                                                        size='small'
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {appliedFilters && (
                                            <Button
                                                icon={<MdDone />}
                                                onClick={handleApplyFilter}
                                                type='link'
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='text-xs text-stackit-gray-primary p-2 font-semibold'>
                                    No Column is Selected
                                </div>
                            )}
                        </div>
                    )
                }
                trigger='click'
                open={popoverOpen}
                placement='bottom'
                onOpenChange={handlePopoverOpenChange}
            >
                <button
                    onClick={() => {
                        if (selectedColumns.length > 0) {
                            setShowFiltersInputSection(true);
                        }
                    }}
                    className='ml-2 min-w-fit border-[1px] border-solid border-stackit-gray bg-stackit-background-2 rounded-full cursor-pointer px-3 py-1.5'
                >
                    <div className='flex m-0 p-0 items-center text-stackit-text-gray font-[500]'>
                        {listOfAppliedFilters.length &&
                        listOfAppliedFilters.length !== 0 ? (
                            <div className='flex items-center'>
                                <RiFilter2Fill
                                    style={{ marginRight: '4px' }}
                                    className='text-stackit-text-gray w-4 h-4'
                                />
                                <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                    {listOfAppliedFilters.length}{' '}
                                    {listOfAppliedFilters.length > 1
                                        ? 'Filters'
                                        : 'Filter'}{' '}
                                    Applied
                                </p>
                            </div>
                        ) : (
                            <div className='flex items-center'>
                                <FiFilter
                                    style={{ marginRight: '4px' }}
                                    className='text-stackit-text-gray w-4 h-4'
                                />
                                <p className='m-0 p-0 flex gap-1 items-center text-[12px]'>
                                    Filter
                                </p>
                            </div>
                        )}
                    </div>
                </button>
            </Popover>
        </div>
    );
};

export default ExternalDataPreviewFilters;
