import React from "react";

export default function HighlightedSearchText({ title, searchValue }) {
	if (!searchValue) {
		return (
			<span className="m-0 p-0 text-[12px] font-normal" style={{ overflowWrap: "anywhere" }}>
				{title}
			</span>
		);
	}

	const index = title.toLowerCase().indexOf(searchValue.toLowerCase());
	if (index === -1) {
		return (
			<span className="m-0 p-0 text-[12px] font-normal" style={{ overflowWrap: "anywhere" }}>
				{title}
			</span>
		);
	}

	const beforeStr = title.substr(0, index);
	const afterStr = title.substr(index + searchValue.length);
	return (
		<p className="m-0 p-0 text-[12px] font-normal" style={{ overflowWrap: "anywhere" }}>
			{beforeStr}
			<span className="text-stackit-primary" style={{ overflowWrap: "anywhere" }}>
				{title.substr(index, searchValue.length)}
			</span>
			{afterStr}
		</p>
	);
}
