import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    getIconForColumnType,
    readableColumnName,
} from '../../../../utils/jsUtils';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import {
    MdModeEditOutline,
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import HighlightedSearchText from './HiglightedSearchText';
import { Tooltip } from 'antd';
import { TbMathFunction } from 'react-icons/tb';
import { IoMdEye } from 'react-icons/io';
import { applyReverseTransformationOnFormula } from './DerivedColumns/utils/generalUtils';
import { CONNECTIONS } from '../../../../constants/connection-constants';

function SingleObjectColumns({
    column,
    isChild,
    position,
    searchText,
    setParentIsOpen,
    setParentColumns,
    object,
    isDcFieldSelector = false,
    isStackitField = false,
    isDcField = false,
    setDcDrawer = undefined,
    setSelectedField = undefined,
    setSelectedFormula = undefined,
    setEditDc = undefined,
    setViewOnly = undefined,
}) {
    const {
        selectedColumns,
        setSelectedColumns,
        collapseAllObjects,
        setCollapseAllObjects,
        setScrollFields,
        editorView,
        setVlookupData,
        activeConnectionObj,
    } = useDataPreviewContext();
    const [isOpen, setIsOpen] = useState(
        (activeConnectionObj.connectionName === CONNECTIONS.HUBSPOT ||
            activeConnectionObj.connectionProvider === CONNECTIONS.EXTERNAL ||
            activeConnectionObj.connectionName ===
                CONNECTIONS.API_ORCHESTRATOR ||
            activeConnectionObj.connectionProvider ===
                CONNECTIONS.API_ORCHESTRATOR) &&
            !isChild
    );
    const [matchingColumns, setMatchingColumns] = useState([]);
    // const [totalSelectedColumns, setTotalSelectedColumns] = useState(0);

    useEffect(() => {
        if (collapseAllObjects) setIsOpen(false);
    }, [collapseAllObjects]);

    useEffect(() => {
        if (
            object &&
            activeConnectionObj.connectionName === CONNECTIONS.CHARGEBEE
        ) {
            setIsOpen(false);
        }
    }, [object]);

    useEffect(() => {
        if (searchText !== '' && searchText != null) {
            const matchingValues = findMatchingValues(searchText) ?? [];
            if (matchingValues.length > 0) {
                if (isChild) {
                    setParentIsOpen(true);
                    setParentColumns((prev) => {
                        if (!prev.includes(column)) {
                            return [...prev, column];
                        }
                        return prev;
                    });
                }
                setIsOpen(true);
                setCollapseAllObjects(false);
                setMatchingColumns(matchingValues);
            } else {
                if (isChild) {
                    setParentIsOpen(false);
                }
                setIsOpen(false);
                setMatchingColumns([]);
            }
        } else {
            // if (isChild) {
            // 	setParentIsOpen(false);
            // }
            // setIsOpen(false);
            setMatchingColumns([]);
        }
    }, [searchText]);

    function childrenHaveMatchingValues(children) {
        return children.some((child) =>
            readableColumnName(child.title)
                .toLowerCase()
                .includes(searchText.toLowerCase())
        );
    }

    const findMatchingValues = (text) => {
        return column?.children?.filter(
            (item) =>
                readableColumnName(item.title)
                    .toLowerCase()
                    .includes(text.toLowerCase()) ||
                (item.children && childrenHaveMatchingValues(item.children))
        );
    };

    const handleColumnSelection = (data) => {
        setSelectedColumns((prev) => {
            if (prev.find((item) => item === data.key)) {
                return prev.filter((item) => item !== data.key);
            }
            setScrollFields(true);
            return [...prev, data.key];
        });
    };

    const handleColumnNameInsertion = (data) => {
        if (!editorView) return;
        const { from, to } = editorView.state.selection.main;
        const insertPos = from;
        editorView.dispatch({
            changes: {
                from: editorView.state.selection.main.from,
                to: editorView.state.selection.main.to,
                insert: `[{${data?.key}}]`,
            },
            selection: { anchor: insertPos + data.key.length + 4 },
        });

        editorView.focus(); // Focus the editor after inserting the text
    };

    function checkIfIncluded(data) {
        return selectedColumns.some((item) => item === data.key);
    }

    useEffect(() => {
        column?.children?.sort((a, b) => {
            if (a.children && b.children) {
                return 0;
            }
            if (a.children) {
                return 1;
            }
            if (b.children) {
                return -1;
            }
            return a.title.localeCompare(b.title);
        });
    }, [column]);

    // useEffect(() => {
    // 	let totalSelected = 0;
    //
    // 	column?.children?.forEach((child) => {
    // 		if (selectedColumns.includes(child.key)) {
    // 			totalSelected += 1;
    // 		}
    // 		if (child.children) {
    // 			child.children.forEach((grandChild) => {
    // 				if (selectedColumns.includes(grandChild.key)) {
    // 					totalSelected += 1;
    // 				}
    // 			});
    // 		}
    // 	});
    //
    // 	setTotalSelectedColumns(totalSelected);
    // }, [selectedColumns]);

    const toggleDropDown = useCallback(() => {
        if (!isOpen) setCollapseAllObjects(false);
        setIsOpen(!isOpen);
    });

    const handleDerivedColumnEdit = useCallback(
        (child) => {
            setEditDc(true);
            setSelectedField(child?.title);
            setSelectedFormula(
                applyReverseTransformationOnFormula(
                    child?.formula,
                    child?.selectedColumns,
                    setVlookupData,
                    editorView
                )
            );
            setDcDrawer(true);
        },
        [column, editorView]
    );

    const handleDerivedColumnView = useCallback(
        (child) => {
            setViewOnly(true);
            setSelectedField(child?.title);
            setSelectedFormula(
                applyReverseTransformationOnFormula(
                    child?.formula,
                    child?.selectedColumns,
                    setVlookupData,
                    editorView
                )
            );
            setDcDrawer(true);
        },
        [column, editorView]
    );

    if (isChild) {
        return (
            <div className='flex flex-col'>
                <div
                    className='cursor-pointer bg-white sticky top-[30px] flex justify-start py-[8.4px] px-[20px] items-center z-0'
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? (
                        <IoMdArrowDropdown className='mr-2.5 cursor-pointer' />
                    ) : (
                        <IoMdArrowDropright className='mr-2.5 cursor-pointer' />
                    )}
                    <div className='flex gap-[6px] items-center'>
                        <p className='m-0 p-0 text-[12px] font-[500]'>
                            {column?.title}
                        </p>
                        {/*{totalSelectedColumns > 0 && (*/}
                        {/*	<div className="bg-stackit-gray-secondary rounded-full text-[8px] font-bold w-4 h-4 flex justify-center items-center">*/}
                        {/*		{totalSelectedColumns}*/}
                        {/*	</div>*/}
                        {/*)}*/}
                    </div>
                </div>

                <div
                    className={`ml-[20px] transition-opacity transition-height duration-700 ease-in-out  pt-[3px] ${
                        isOpen ? 'flex flex-col' : 'hidden '
                    }`}
                >
                    {(matchingColumns?.length > 0
                        ? matchingColumns
                        : column?.children
                    )?.map((child, index) => {
                        if (child.children) {
                            return (
                                <SingleObjectColumns
                                    key={`${child.key}.${index}`}
                                    isChild={true}
                                    column={child}
                                    position={index}
                                    searchText={searchText}
                                    setParentIsOpen={setIsOpen}
                                    setParentColumns={setMatchingColumns}
                                    isDcFieldSelector={isDcFieldSelector}
                                />
                            );
                        }
                        const isIncluded = checkIfIncluded(child);
                        return (
                            <Tooltip
                                key={index}
                                title={child.description ?? ''}
                                mouseEnterDelay={1.2}
                                autoAdjustOverflow={true}
                            >
                                <div
                                    onClick={
                                        isDcFieldSelector
                                            ? () =>
                                                  handleColumnNameInsertion(
                                                      child
                                                  )
                                            : () => handleColumnSelection(child)
                                    }
                                    className='px-[8.4px] py-[3px] flex'
                                >
                                    <div
                                        className={`flex justify-between items-center cursor-pointer w-full px-[13px] py-[4px] rounded-md ${
                                            isIncluded && !isDcFieldSelector
                                                ? ' bg-stackit-primary bg-opacity-[15%]'
                                                : ' hover:bg-stackit-primary-hover'
                                        }`}
                                    >
                                        <div className='flex items-center gap-[10px]'>
                                            <p className='text-stackit-primary m-0 p-0 flex items-center'>
                                                {getIconForColumnType(
                                                    child.dataType ||
                                                        child.type ||
                                                        'text'
                                                )}
                                            </p>
                                            <HighlightedSearchText
                                                title={child?.title}
                                                searchValue={searchText}
                                            />
                                        </div>

                                        {!isDcFieldSelector && (
                                            <input
                                                checked={isIncluded}
                                                type='checkbox'
                                                className='text-stackit-primary bg-stackit-primary cursor-pointer accent-stackit-primary'
                                                readOnly={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-col'>
            <div
                className={`cursor-pointer bg-white sticky top-0 left-0 right-0 flex justify-between py-[8.4px] px-[13px] items-center rounded-tl-md rounded-tr-md shadow-sm z-10`}
                onClick={toggleDropDown}
            >
                <div className='flex gap-[6px] items-center'>
                    <p className='m-0 p-0 text-[12px] font-[500]'>
                        {column?.title}
                    </p>
                    {/*{totalSelectedColumns > 0 && (*/}
                    {/*	<div className="bg-stackit-gray-secondary rounded-full text-[8px] font-bold w-4 h-4 flex justify-center items-center">*/}
                    {/*		{totalSelectedColumns}*/}
                    {/*	</div>*/}
                    {/*)}*/}
                </div>
                {isOpen ? (
                    <MdOutlineKeyboardArrowUp className='mr-2.5 cursor-pointer' />
                ) : (
                    <MdOutlineKeyboardArrowDown className='mr-2.5 cursor-pointer' />
                )}
            </div>

            <div
                className={`transition-opacity transition-height duration-700 ease-in-out  border-0 border-t-[1px] border-b-[1px] border-stackit-light-gray border-solid pt-[3px] ${
                    isOpen ? 'flex flex-col' : 'hidden '
                }`}
            >
                {(matchingColumns?.length > 0
                    ? matchingColumns
                    : column?.children
                )?.map((child, index) => {
                    if (
                        child.children &&
                        activeConnectionObj.connectionProvider !==
                            CONNECTIONS.EXTERNAL
                    ) {
                        return (
                            <SingleObjectColumns
                                key={`${child.key}.${index}`}
                                isChild={true}
                                column={child}
                                position={index}
                                searchText={searchText}
                                setParentIsOpen={setIsOpen}
                                setParentColumns={setMatchingColumns}
                                isDcFieldSelector={isDcFieldSelector}
                            />
                        );
                    }

                    const isIncluded = checkIfIncluded(child);
                    return (
                        <Tooltip
                            key={index}
                            title={child.description ?? ''}
                            mouseEnterDelay={1.2}
                            autoAdjustOverflow={true}
                        >
                            <div
                                onClick={
                                    isDcFieldSelector
                                        ? () => handleColumnNameInsertion(child)
                                        : () => handleColumnSelection(child)
                                }
                                className='px-[8.4px] py-[3px] flex group'
                            >
                                <div
                                    className={`flex  justify-between items-center cursor-pointer w-full px-[13px] py-[4px] rounded-md ${
                                        isIncluded && !isDcFieldSelector
                                            ? ` ${
                                                  isDcField || isStackitField
                                                      ? ' bg-stackit-dc-primary '
                                                      : 'bg-stackit-primary '
                                              }  bg-opacity-[15%]`
                                            : ' hover:bg-stackit-primary-hover'
                                    }`}
                                >
                                    <div className='flex items-center gap-[10px]'>
                                        {isDcField || isStackitField ? (
                                            <TbMathFunction className='text-stackit-dc-primary rounded-md font-bold' />
                                        ) : (
                                            <p className='text-stackit-primary m-0 p-0 flex items-center'>
                                                {getIconForColumnType(
                                                    (child?.dataType ||
                                                        child?.type) ??
                                                        'text'
                                                )}
                                            </p>
                                        )}
                                        <HighlightedSearchText
                                            title={child?.title}
                                            searchValue={searchText}
                                        />
                                    </div>

                                    <div className='flex gap-2 items-center'>
                                        {isStackitField ? (
                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDerivedColumnView(
                                                        child
                                                    );
                                                }}
                                                className='z-20 hidden group-hover:flex justify-center items-center h-full px-1'
                                            >
                                                <IoMdEye className='' />
                                            </div>
                                        ) : (
                                            isDcField && (
                                                <div
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDerivedColumnEdit(
                                                            child
                                                        );
                                                    }}
                                                    className='z-20 hidden group-hover:flex justify-center items-center h-full px-1'
                                                >
                                                    <MdModeEditOutline className='' />
                                                </div>
                                            )
                                        )}

                                        {!isDcFieldSelector && (
                                            <input
                                                checked={isIncluded}
                                                type='checkbox'
                                                className={`${
                                                    isDcField || isStackitField
                                                        ? ' text-stackit-dc-primary bg-stackit-dc-primary accent-stackit-dc-primary'
                                                        : ' text-stackit-primary bg-stackit-primary accent-stackit-primary '
                                                } cursor-pointer`}
                                                readOnly={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
}

export default SingleObjectColumns;
