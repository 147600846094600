import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'antd';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import {
    createImportConfig,
    importDataNow,
    pollImportRunStatusByImportConfigId,
} from '../../../../network/api';
import { handleError } from '../../../../utils/ErrorHandling';
import { toast } from 'sonner';
import { SERVER_FUNCTION_CONSTANTS } from '../../../../constants/server-function-constants';

function ApiTemplateDataPreviewHeader({
    setStatusLoading,
    setImportLoadingData,
}) {
    const {
        activeConnectionObj,
        dataSelectedColumns,
        numberOfRows,
        activeField,
        newImport,
        totalRecordsToBeFetched,
        selectedColumns,
        apiCustomFilters,
        apiRequestParameters,
        activeEndpoint,
        activeSubObject,
        apiTemplateBody,
    } = useDataPreviewContext();
    const logoUrl = activeConnectionObj.connectionLogo;
    const [importButtonLoading, setImportButtonLoading] = useState(false);
    const pollingIntervalRef = useRef();

    const checkImportRunStatus = useCallback(
        async (uid, stateString, interval) => {
            try {
                const response = await pollImportRunStatusByImportConfigId(uid);
                if (response.status === 200) {
                    if (
                        response.data?.status === 'COMPLETED' ||
                        response.data?.completed
                    ) {
                        clearInterval(interval);

                        // set session storage for further use
                        sessionStorage.setItem('state', stateString);

                        // closing data preview
                        const event = new Event('dataPreviewClosed');
                        window.dispatchEvent(event);
                        // google.script.host.close();

                        setStatusLoading(true);
                        setImportLoadingData((prev) => ({
                            ...prev,
                            importRunResponse: {},
                            importRunId: sessionStorage.getItem('importRunId'),
                        }));
                    } else if (!response.data?.rowsInserted[1]) {
                        // -- IMPORT FAILED --
                        clearInterval(interval);
                        handleError(new Error('Import Failed'));
                    }
                } else if (response.data.status === 'FAILED') {
                    clearInterval(interval);
                    handleError(new Error(response?.data?.message));
                }
            } catch (err) {
                clearInterval(interval);

                toast.warning('Error', {
                    description:
                        err?.response?.data.displayMessage ||
                        err?.response?.data.message ||
                        err.displayMessage ||
                        err.message ||
                        'Some Error Occured',
                    position: 'bottom-center',
                    closeButton: true,
                });

                handleError(err, 'Error in fetching import status', {
                    importConfigId: uid,
                });
            }
        }
    );

    async function handleCreateImportConfig() {
        try {
            setImportButtonLoading(true);
            const currentSheetId = SERVER_FUNCTION_CONSTANTS.SPREADSHEET_ID;

            let connectionImportConfig = {
                key: activeConnectionObj.endpoints[activeEndpoint || 0]
                    .identifierKey,
                object: activeField,
                columns: selectedColumns,
                cap: totalRecordsToBeFetched,
                connectionSubObject:
                    activeSubObject === 'Select' ? null : activeSubObject,
            };

            switch (activeConnectionObj.connectionName) {
                case CONNECTIONS.GOOGLE_SEARCH_CONSOLE:
                    connectionImportConfig = {
                        ...connectionImportConfig,
                        params:
                            (apiRequestParameters || []).length > 0
                                ? apiRequestParameters
                                : [],
                        data:
                            Object.keys(apiCustomFilters || {}).length > 0
                                ? apiCustomFilters
                                : {},
                    };
                    break;
                default:
                    connectionImportConfig = {
                        ...connectionImportConfig,
                        queryParams:
                            Object.keys(apiCustomFilters || {}).length > 0
                                ? apiCustomFilters
                                : {},
                        params:
                            (apiRequestParameters || []).length > 0
                                ? apiRequestParameters
                                : [],
                    };
            }

            const importConfigObj = {
                userConnectionId: activeConnectionObj.userConnectionId,
                spreadsheetId: currentSheetId,
                connectionImportConfig,
            };

            const response = await createImportConfig(importConfigObj);
            if (response.status === 200) {
                const importNowConfigObj = {
                    runType: 'MANUAL_FIRST_TIME',
                    runConfig: {
                        userConnectionId: activeConnectionObj.userConnectionId,
                        importConfigId: response.data.importConfigId,
                        spreadsheetId: currentSheetId,
                    },
                };

                const runResponse = await importDataNow(importNowConfigObj);
                if (runResponse.status === 200) {
                    const localState = {
                        connectionLogo: activeConnectionObj.connectionLogo,
                        connectionProvider:
                            activeConnectionObj.connectionProvider,
                        connectionName: activeConnectionObj.connectionName,
                        importConfigId: response.data.importConfigId,
                        spreadsheetId: currentSheetId,
                        importRunId: runResponse.data.importRunId,
                        from: 'importselect',
                    };

                    sessionStorage.setItem(
                        'importRunId',
                        runResponse.data.importRunId
                    );

                    const stateString = JSON.stringify(localState);

                    pollingIntervalRef.current = setInterval(() => {
                        checkImportRunStatus(
                            response.data.importConfigId + response.data.userId,
                            stateString,
                            pollingIntervalRef.current
                        );
                    }, 1000);
                } else {
                    clearInterval(pollingIntervalRef.current);
                    setImportButtonLoading(false);
                    handleError(new Error(runResponse?.data?.message));
                }
            } else {
                clearInterval(pollingIntervalRef.current);
                setImportButtonLoading(false);
                handleError(new Error(response?.data?.message));
            }
        } catch (err) {
            toast.warning('Error', {
                description:
                    err?.response?.data.displayMessage ||
                    err?.response?.data.message ||
                    err.displayMessage ||
                    err.message ||
                    'Some Error Occured',
                position: 'bottom-center',
                closeButton: true,
            });

            handleError(err, 'Error in creating import config', {
                connectionName: activeConnectionObj?.connectionName,
                userConnectionId: activeConnectionObj?.userConnectionId,
            });
        }
    }

    return (
        <>
            <div className='connection'>
                <div className='logo'>
                    <img
                        className='connection-logo'
                        src={logoUrl}
                        width={30}
                        height={30}
                        alt='logo'
                    />
                </div>
                <div className='connection-name'>
                    {activeConnectionObj.displayName}{' '}
                    {activeConnectionObj.identifier && activeConnectionObj ? (
                        <span className='text-[12px] ml-1 text-stackit-gray'>
                            [{activeConnectionObj?.identifier}]
                        </span>
                    ) : (
                        'stackit'
                    )}
                </div>
                {/* <p className="p-0 m-0 ml-4 text-red-500 font-[500] text-[12px] line-clamp-1">{importErrorMsg}</p> */}
            </div>
            <div className='import-connection'>
                <span className='fields-head'>
                    {dataSelectedColumns?.length} columns selected (showing only{' '}
                    {numberOfRows} rows)
                </span>
                <span> </span>
                {newImport && (
                    <Button
                        type='primary'
                        block
                        className='import-connection-btn'
                        onClick={handleCreateImportConfig}
                        disabled={
                            dataSelectedColumns?.length === 0 ||
                            importButtonLoading
                        }
                        loading={importButtonLoading}
                    >
                        {importButtonLoading === true ? `Importing` : 'Import'}
                    </Button>
                )}
            </div>
        </>
    );
}

export default ApiTemplateDataPreviewHeader;
