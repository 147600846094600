import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useDataPreviewContext } from "../../../Context/DataPreviewContext";
import { toast } from "sonner";

function LimitPopOverContent({ onSetupClick }) {
	const { totalRecordsToBeFetched, setTotalRecordsToBeFetched } = useDataPreviewContext();
	const [localTotalRecords, setLocalTotalRecords] = useState(totalRecordsToBeFetched);

	const handleSetLimit = () => {
		if (localTotalRecords == null || !Number.isFinite(Number(localTotalRecords)) || Number(localTotalRecords) > 50000 || Number(localTotalRecords) <= 0) {
			toast.error("Invalid limit value, it should be a number greater than 0 and less than 50000.");
			return;
		}

		setTotalRecordsToBeFetched(localTotalRecords);
		toast.success("Limit on total number of records to be fetched is set.");
		onSetupClick();
	};

	return (
		<div className="px-4 py-4 w-60 border-stackit-gray-primary">
			<p className="p-0 m-0 font-medium">Total records</p>
			<p className="text-xs text-stackit-gray p-0">
				Total number of records that are to be fetched, if not set, defaults to number of records returned by the API, if less than 50,000.{" "}
			</p>
			<div className="flex flex-row justify-between mt-4 my-2 gap-8">
				<p className={"text-xs m-0 p-0"}>Total records to be fetched</p>
			</div>
			<Form>
				<Form.Item style={{ marginBottom: "0px" }}>
					<Input
						value={localTotalRecords}
						onChange={(e) => setLocalTotalRecords(e.target.value)}
						className="db-input rounded p-1 px-2 border border-stackit-gray text-xs py-2"
						style={{ border: "none", margin: 0 }}
						size={"large"}
					/>
				</Form.Item>
				<Button
					block
					type={"default"}
					onClick={handleSetLimit}
					disabled={localTotalRecords == null}
					className="bg-stackit-primary my-4 mt-6 hover:bg-stackit-primary text-white border-none hover:border-none pagination-btn text-xs">
					Set limit
				</Button>
			</Form>
		</div>
	);
}

export default LimitPopOverContent;
