import React from 'react';
import { CONNECTIONS } from '../../../constants/connection-constants';
import { useDataPreviewContext } from '../Context/DataPreviewContext';
import ApiTemplateDataPreviewHeader from './ApiTemplate/ApiTemplateDataPreviewHeader';
import ExternalDataPreviewHeader from './External/ExternalDataPreviewHeader';

export default function DataPreviewHeaderWrapper(props) {
    const { activeConnectionObj } = useDataPreviewContext();

    return (
        <>
            {activeConnectionObj.connectionProvider ===
                CONNECTIONS.API_ORCHESTRATOR && (
                <ApiTemplateDataPreviewHeader
                    setStatusLoading={props.setStatusLoading}
                    setImportLoadingData={props.setImportLoadingData}
                />
            )}
            {activeConnectionObj.connectionProvider ===
                CONNECTIONS.EXTERNAL && (
                <ExternalDataPreviewHeader
                    setStatusLoading={props.setStatusLoading}
                    setImportLoadingData={props.setImportLoadingData}
                />
            )}
        </>
    );
}
