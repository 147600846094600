const CONNECTIONS = Object.freeze({
    CHARGEBEE: 'Chargebee',
    HUBSPOT: 'Hubspot',
    MYSQL: 'MySql',
    POSTGRES: 'PostgreSQL',
    LINKEDIN: 'Linkedin',
    REDSHIFT: 'Amazon Redshift',
    API_ORCHESTRATOR: 'API Orchestrator',
    EXTERNAL: 'External',
    MSSQL: 'MsSql',
    GSHEETS: 'Google Sheets',
    VITALLY: 'Vitally',
    ZOHO_CRM: 'Zoho CRM',
    SNOWFLAKE: 'Snowflake',
    FACEBOOK_ADS: 'Facebook Ads',
    GOOGLE_SEARCH_CONSOLE: 'Google Search Console',
});

const CONNECTION_IDS = Object.freeze({
    SHOPIFY: '9da77001-af33-4bcd-be46-6252bf9342b9',
    FACEBOOK: 'dfedc4d1e738',
});

module.exports = { CONNECTIONS, CONNECTION_IDS };
