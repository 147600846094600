const LOG_LEVEL = 'info';

const NO_OP = () => {};

function formatLog(message) {
    const userEmail =
        `${sessionStorage.getItem('stackit-user-email')}` || 'No Email';
    return `[${userEmail}] ${message}`;
}

class ConsoleLogger {
    constructor(options = {}) {
        const { level } = options;

        this.error = this.createLogMethod(console.error, 'error');

        if (level === 'error') {
            this.warn = NO_OP;
            this.log = NO_OP;
            return;
        }

        this.warn = this.createLogMethod(console.warn, 'warn');

        if (level === 'warn') {
            this.log = NO_OP;
            return;
        }

        this.log = this.createLogMethod(console.log, 'info');
    }

    createLogMethod(logFunction, logLevel) {
        return (message) => {
            const formattedMessage = formatLog(message);
            logFunction.call(console, formattedMessage);
        };
    }
}

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export { logger, ConsoleLogger };
