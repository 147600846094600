import React from 'react';
import { MdOutlineNumbers, MdViewArray } from 'react-icons/md';
import { TbHttpOptions, TbTextSize } from 'react-icons/tb';
import { FaCalendarAlt, FaYinYang } from 'react-icons/fa';
import { HiMiniTicket } from 'react-icons/hi2';
import { BiDialpad, BiSolidObjectsHorizontalCenter } from 'react-icons/bi';
import { logger } from '../configs/logger-config';

const getIconForColumnType = (columnType) => {
    switch (columnType) {
        case 'text':
        case 'varchar':
        case 'char':
        case 'citext':
        case 'name':
        case 'uuid':
        case 'xml':
        case 'string':
            return <TbTextSize />;
        case 'integer':
        case 'int4':
        case 'smallint':
        case 'bigint':
        case 'decimal':
        case 'numeric':
        case 'real':
        case 'double precision':
        case 'serial':
        case 'bigserial':
        case 'money':
        case 'number':
            return <MdOutlineNumbers />;
        case 'date':
        case 'datetime':
        case 'time':
        case 'time with time zone':
        case 'timestamp with time zone':
        case 'timestamp':
        case 'interval':
            return <FaCalendarAlt />;
        case 'bool':
        case 'boolean':
            return <FaYinYang />;
        case 'enum':
            return <TbHttpOptions />;
        case 'phone_number':
            return <BiDialpad />;
        case 'object':
        case 'json':
        case 'jsonb':
        case 'hstore':
            return <BiSolidObjectsHorizontalCenter />;
        case 'array':
        case 'integer[]':
        case 'text[]':
        case 'varchar[]':
        case 'boolean[]':
        case 'date[]':
        case 'json[]':
        case 'uuid[]':
            return <MdViewArray />;
        case 'null':
            return <HiMiniTicket />;
        default:
            return <TbTextSize />;
    }
};

const getLeafNodesInColumns = (tree, importedColumns) => {
    let leafNodes = [];
    tree.forEach((node) => {
        if (node.children && node.children.length > 0) {
            leafNodes = leafNodes.concat(
                getLeafNodesInColumns(node.children, importedColumns)
            );
        } else if (importedColumns.includes(node.key)) {
            leafNodes.push({
                ...node,
                type: node.type,
                label: node.title,
                value: node.key,
            });
        }
    });

    return leafNodes;
};

const getParentKey = (key, treeData, parentKey = null) => {
    for (let i = 0; i < treeData.length; i += 1) {
        const node = treeData[i];
        const { children, key: itemKey } = node;

        if (itemKey === key) {
            return parentKey;
        }

        if (children) {
            if (children.some((child) => child.key === key)) {
                return itemKey;
            }

            const newparentKey = getParentKey(key, children, itemKey);
            if (newparentKey) {
                return newparentKey;
            }
        }
    }

    return null;
};

const findExpandedKeys = (treeData, searchValue) => {
    const expandedKeys = [];

    const loop = (data) => {
        data.forEach((item) => {
            if (item.title.toLowerCase().includes(searchValue.toLowerCase())) {
                const parentKey = getParentKey(item.key, treeData);
                if (parentKey) {
                    expandedKeys.push(parentKey);
                }
                expandedKeys.push(item.key);
            }
            if (item.children) {
                loop(item.children);
            }
        });
    };

    loop(treeData);
    return expandedKeys;
};

function readableOperation(operation, connectionName = '') {
    const convertedOperation = operation.toLowerCase();
    switch (connectionName) {
        default:
            switch (convertedOperation) {
                case 'in':
                    return 'exactly is';
                case 'not_in':
                    return 'exactly is not';
                case 'is':
                    return 'exactly is';
                case 'is_not':
                    return 'exactly is not';
                case 'lt':
                    return 'less than';
                case 'lte':
                    return 'less than or equals';
                case 'gt':
                    return 'greater than';
                case 'gte':
                    return 'greater than or equals';
                case 'on':
                    return 'on';
                case 'before':
                    return 'before';
                case 'after':
                    return 'after';
                case 'is_in_list':
                    return 'equals';
                case 'is_not_in_list':
                    return 'not equals';
                case 'BETWEEN':
                    return 'between';
                default:
                    return operation.toLowerCase();
            }
    }
}

const evalParentColumnNameFromTree = (tree, importedColumns) => {
    const newColumns = importedColumns.map((column) => {
        let fullKey = null;
        tree.forEach((item) => {
            if (item.children) {
                item.children.forEach((child) => {
                    if (child.value === column) {
                        fullKey = child.key;
                    }
                });
            }
        });
        return fullKey;
    });
    return newColumns;
};

export function getHalfHourIntervals() {
    const intervals = [];
    const startTime = new Date('2023-05-05T00:00:00'); // set the start time to 12:00 AM

    while (startTime < new Date('2023-05-05T23:31:00')) {
        // set the end time to 11:30 PM
        const formattedTime = startTime.toLocaleTimeString('en-US', {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        });
        intervals.push(formattedTime);
        startTime.setTime(startTime.getTime() + 30 * 60 * 1000); // add 30 minutes to the start time
    }

    return intervals;
}
function readableColumnName(colName) {
    const parts = colName.split('.');
    return parts
        .map((part, index) => {
            // Capitalize the first letter of each word and replace underscores with spaces
            const readablePart = part
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase());
            // If it's the last part, don't add brackets
            return index === parts.length - 1
                ? readablePart
                : `[${readablePart}]`;
        })
        .join(' ');
}

function readableColumnNameHubspot(mapping, colKey) {
    return (
        mapping[colKey]?.readableTitle?.replace(/["'(),]/g, '') ||
        readableColumnName(colKey)
    );
}

function convertUnixEpochToDate(unixTimestamp, connectionName) {
    switch (connectionName) {
        case 'Chargebee': {
            const milliseconds = unixTimestamp * 1000;
            const date = new Date(milliseconds);
            return date.toISOString().slice(0, 10);
        }
        default:
            return unixTimestamp;
    }
}

const flattenTree = (tree) => {
    let result = [];
    tree.forEach((node) => {
        if (node.children && node.children.length > 0) {
            result = result.concat(flattenTree(node.children));
        } else {
            result.push(node.value);
        }
    });
    return result;
};

function readableValue(
    operation,
    value,
    connectionName,
    highValue = null,
    dateIncluded = false
) {
    let val;
    switch (connectionName) {
        default:
            switch (operation) {
                case 'in':
                case 'IN':
                case 'NOT_IN':
                case 'not_in':
                    val = Array.isArray(value) ? value.join(', ') : value;
                    return val;
                case 'is':
                case 'is_not':
                case 'lt':
                case 'lte':
                case 'gt':
                case 'gte':
                case 'LT':
                case 'LTE':
                case 'GT':
                case 'GTE':
                    return value;
                case 'on':
                case 'before':
                case 'after':
                case 'BETWEEN':
                    return convertUnixEpochToDate(value, connectionName);
                case 'last':
                    if (value === 'last_month') {
                        return 'Month';
                    }
                    val = value > 1 ? `${value} Days` : `${value} Day`;
                    return val;
                default:
                    return value;
            }
    }
}

function formatDate(date) {
    try {
        let month = `${date.getUTCMonth() + 1}`;
        let day = `${date.getUTCDate()}`;
        const year = date.getUTCFullYear();

        if (month.length < 2) month = `0${month}`;
        if (day.length < 2) day = `0${day}`;

        return [year, month, day].join('-');
    } catch (error) {
        logger.error('Error in formatDate', error);
        return 'Problem getting date';
    }
}

const getTitleFromKey = (key, selectionTree) => {
    let title = null;
    if (selectionTree) {
        selectionTree.forEach((item) => {
            if (item.children) {
                item.children.forEach((child) => {
                    if (child.children) {
                        child.children.forEach((grandkid) => {
                            if (grandkid.key === key) {
                                title = `[${item.title}] ${grandkid.title}`;
                            }
                        });
                    }
                });
            }
        });
    }
    return title;
};

const handleKeyPressForNumericalInput = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9]$/;
    if (!numericRegex.test(keyValue)) {
        event.preventDefault();
    }
};

// Function to check if a given date is within the last 24 hours
const isWithinLast24Hours = (dateString) => {
    // Parse the date string
    const date = new Date(dateString);

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - date;

    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);

    // Check if the difference is less than 24 hours
    return diffInHours < 24;
};
function capitalizeFirstLetter(str) {
    return str
        .split(' ') // Split the string into an array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words back into a string
}

function convertTitleToKey(title) {
    return title.replace(/ /g, '_').toLowerCase();
}

const truncateString = (str, num) => {
    if (str?.length <= num) {
        return str;
    }
    return `${str?.slice(0, num)}...`;
};

function calculatePercentage(a, b) {
    // Convert input to numbers, handle null and undefined cases
    const numA = Number(a);
    const numB = Number(b);

    // Check if the conversion to number is successful
    if (Number.isNaN(numA) || Number.isNaN(numB)) {
        throw new Error(
            'Invalid input: inputs must be numbers or convertible to numbers.'
        );
    }

    // Ensure b is not zero to avoid division by zero
    if (numB === 0) {
        throw new Error('Invalid input: denominator (b) cannot be zero.');
    }

    // Calculate percentage
    return (numA / numB) * 100;
}

function truncateToOneDecimal(number) {
    const numStr = number.toString();
    const decimalIndex = numStr.indexOf('.');

    // If there's no decimal point, just return the number
    if (decimalIndex === -1) {
        return numStr;
    }

    // Return the part of the string up to one digit after the decimal point
    return numStr.substring(0, decimalIndex + 2);
}

function convertToK(number) {
    // Ensure the input is a number
    if (typeof number !== 'number' || Number.isNaN(number)) {
        throw new Error('Invalid input: input must be a valid number.');
    }

    // Check if the number is less than 1000
    if (number < 1000) {
        return number.toString();
    }

    // Calculate the number in 'k' format
    const numberInK = number / 1000;
    return numberInK % 1 === 0
        ? `${numberInK}k`
        : `${truncateToOneDecimal(numberInK)}k`;
}

export {
    getLeafNodesInColumns,
    getParentKey,
    findExpandedKeys,
    readableOperation,
    readableValue,
    evalParentColumnNameFromTree,
    readableColumnName,
    convertUnixEpochToDate,
    flattenTree,
    formatDate,
    getTitleFromKey,
    getIconForColumnType,
    handleKeyPressForNumericalInput,
    isWithinLast24Hours,
    capitalizeFirstLetter,
    convertTitleToKey,
    readableColumnNameHubspot,
    truncateString,
    calculatePercentage,
    convertToK,
};
