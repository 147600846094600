import axios from 'axios';

const { BASE_URL } = { BASE_URL: 'https://preprod.superjoin.ai/api/v1' };

const ASTRA_ROUTE = 'astra';
const INTERSECTION_ROUTE = 'intersection';
const APOLLO_ROUTE = 'apollo';
const CHRONA_ROUTE = 'chrona';
const ZEUS_ROUTE = 'zeus';
const WORKSPACE_ROUTE = 'astra/workspace';
const ONBOARDING_ROUTE = 'astra/onboarding';
const SUBSCRIPTION_ROUTE = 'astra/subscription';
const NEXUS_ROUTE = 'nexus';
const TEMPLATE_ROUTE = 'apollo/template';
const STRIPE_ROUTE = 'stripe';
const HERMES_ROUTE = 'hermes';

async function ExecuteAPI(
    path,
    method,
    queryParams = {},
    body = {},
    isAuthenticated = true,
    source = null
) {
    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-type': 'application/json',
        },
    });

    const requestConfig = {
        method,
        url: path,
        data: body,
        params: queryParams,
        headers: {
            'X-App-Identifier-Id': 'main',
        },
    };

    if (source) {
        requestConfig.cancelToken = source.token;
    }

    let identityToken = '';

    if (isAuthenticated) {
        identityToken = sessionStorage.getItem('stackit-google-user-jwt');
        requestConfig.headers = {
            ...requestConfig.headers,
            Authorization: `Bearer ${identityToken}`,
        };
    }

    try {
        return await axiosInstance.request(requestConfig);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = '/';
        }
        throw error;
    }
}

// User Auth Verify
export async function validateIdentityToken() {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${identityToken}`,
    };

    return axios.get(`${BASE_URL}/${ASTRA_ROUTE}/auth-verify`, {
        headers,
    });
}

export async function sandboxUserLogin(newUserConnectionObject) {
    return ExecuteAPI(
        `${BASE_URL}/${ASTRA_ROUTE}/sandbox-login`,
        'POST',
        {},
        newUserConnectionObject,
        false
    );
}

export async function seamlessAuth(newUserConnectionObject) {
    return ExecuteAPI(
        `${BASE_URL}/${ASTRA_ROUTE}/seamless-auth`,
        'POST',
        {},
        newUserConnectionObject,
        false
    );
}

// Landing Page
export async function fetchLandingPageData() {
    return ExecuteAPI(`${BASE_URL}/${INTERSECTION_ROUTE}/landing`, 'GET');
}

export async function fetchOnboardingForm() {
    return ExecuteAPI(`${BASE_URL}/${ZEUS_ROUTE}/onboarding-form`, 'GET');
}

export async function submitOnboardingForm(informationObject) {
    return ExecuteAPI(
        `${BASE_URL}/${ZEUS_ROUTE}/onboarding-form`,
        'POST',
        {},
        informationObject
    );
}

// Connections Listing Page
export async function fetchDataConnections() {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/connections-listing`,
        'GET'
    );
}

export async function fetchDataConnectionsDefination(connectionId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/connection-definition/${connectionId}`,
        'GET'
    );
}

// New User Connection
export async function createNewUserConnection(newUserConnectionObject) {
    const source = axios.CancelToken.source();
    setTimeout(() => {
        source.cancel();
    }, 60000);
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/create-user-connection`,
        'POST',
        undefined,
        newUserConnectionObject,
        true,
        source
    );
}

// Request Access
export async function requestAccess(type, info) {
    return ExecuteAPI(
        `${BASE_URL}/${WORKSPACE_ROUTE}/request-permission`,
        'POST',
        {},
        {
            type,
            info,
        }
    );
}

// Get Onboarding Status
export async function getOnboardingStatus() {
    return ExecuteAPI(`${BASE_URL}/${ONBOARDING_ROUTE}/`, 'GET');
}

// Get Connection Sharing
export async function getConnectionSharing(userConnectionId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/connection-sharing`,
        'GET',
        {
            userConnectionId,
        }
    );
}

// Get workspace members
export async function getWorkspaceMembers() {
    return ExecuteAPI(
        `${BASE_URL}/${WORKSPACE_ROUTE}/get-workspace-users`,
        'GET'
    );
}

// Update Connection Sharing
export async function updateConnectionSharing(newSharingObject) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/connection-sharing`,
        'POST',
        {},
        newSharingObject
    );
}

// Delete User Connection
export async function deleteUserConnection(payload) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/delete-user-connection`,
        'DELETE',
        {},
        payload
    );
}

// APOLLO APIs

// Create Import Config
export async function createImportConfig(importConfigObject) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/create-import-config`,
        'POST',
        undefined,
        importConfigObject
    );
}

export async function updateImportConfig(importConfigObject) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/update-import-config`,
        'PUT',
        {},
        importConfigObject,
        true
    );
}

// Import Data Now
export async function importDataNow(importNowObject) {
    const source = axios.CancelToken.source();
    setTimeout(() => {
        console.log('timeout');
        source.cancel();
    }, 1200000);

    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/import-now`,
        'POST',
        {},
        importNowObject,
        true,
        source
    );
}

// Get Import tab Data
export async function importTabData(spreadsheetId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/get-import-tab`,
        'GET',
        {
            spreadsheetId,
        }
    );
}

// Get import tab for all spreadsheet
export async function importTabDataForOtherSpreadsheet(spreadsheetId) {
    return ExecuteAPI(
        `${INTERSECTION_ROUTE}/get-other-spreadsheet-import-tab/`,
        'GET',
        {
            spreadsheetId,
        }
    );
}

// Saved Queries
export async function getSavedQueriesForUser() {
    return ExecuteAPI(`${BASE_URL}/${INTERSECTION_ROUTE}/saved-query`, 'GET');
}

export async function updateSavedQuery(savedQueryId, updateObject) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query/${savedQueryId}`,
        'POST',
        {},
        updateObject
    );
}

export async function getSavedQuerySharing(savedQueryId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query-sharing/${savedQueryId}`,
        'GET',
        {}
    );
}

export async function updateSavedQuerySharing(newSharingObject) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query-sharing`,
        'POST',
        {},
        newSharingObject
    );
}

export async function getSavedQueriesForUserConnection(userConnectionId) {
    return ExecuteAPI(`${BASE_URL}/${INTERSECTION_ROUTE}/saved-query`, 'GET', {
        userConnectionId,
    });
}

export async function createSavedQuery(
    name,
    description,
    queryConfig,
    userConnectionId,
    connectionId,
    sharedConfig
) {
    const requestBody = {
        name,
        description,
        queryConfig,
        userConnectionId,
        connectionId,
        sharedConfig,
    };

    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query`,
        'POST',
        undefined,
        requestBody
    );
}

export async function fetchSavedQuery(savedQueryId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query/${savedQueryId}`,
        'GET'
    );
}

export async function runSavedQuery(savedQueryId, spreadsheetId, sharedQuery) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/run-saved-query/`,
        'POST',
        {},
        {
            spreadsheetId,
            savedQueryId,
            sharedQuery,
        }
    );
}

export async function deleteSavedQuery(savedQueryId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/saved-query/${savedQueryId}`,
        'DELETE'
    );
}

export async function pollImportRunStatus(importRunId) {
    return ExecuteAPI(`${BASE_URL}/${APOLLO_ROUTE}/import-run-status`, 'GET', {
        importRunId,
    });
}

export async function getImportedPageData(icId, sharedConfig) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/imported-page-landing`,
        'GET',
        {
            import_config_id: icId,
            sharedConfig,
        }
    );
}

export async function createImportSchedule(createSchedulePayload) {
    return ExecuteAPI(
        `${BASE_URL}/${CHRONA_ROUTE}/create-new-schedule`,
        'POST',
        undefined,
        createSchedulePayload
    );
}

export async function fetchSnapshots(scheduleId) {
    return ExecuteAPI(
        `${BASE_URL}/${CHRONA_ROUTE}/fetch-snapshots-by-scheduleId`,
        'GET',
        {
            scheduleId,
        }
    );
}

export async function fetchActiveSchedules(spreadsheetId) {
    return ExecuteAPI(
        `${CHRONA_ROUTE}/fetch-active-schedules/${spreadsheetId}`,
        'GET'
    );
}

export async function resumeSchedule(scheduleId, jobType = null) {
    return ExecuteAPI(
        `${CHRONA_ROUTE}/resume-schedule`,
        'POST',
        {},
        { scheduleId, jobType }
    );
}

export async function fetchOtherActiveSchedules(spreadsheetId) {
    return ExecuteAPI(
        `${CHRONA_ROUTE}/fetch-other-active-schedules?spreadsheetId=${spreadsheetId}`,
        'GET'
    );
}

export async function deleteImportConfig(importConfigId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/delete-import-config`,
        'DELETE',
        {},
        {
            importConfigId,
        }
    );
}

export async function deleteImportSchedule(deleteSchedulePayload) {
    return ExecuteAPI(
        `${BASE_URL}/${CHRONA_ROUTE}/delete-import-schedule`,
        'DELETE',
        {},
        deleteSchedulePayload,
        true
    );
}

export async function prefetchObjectSchema(userConnectionId, connObjectName) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${identityToken}`,
    };

    const apiConfig = {
        headers,
    };

    return axios.get(
        `${BASE_URL}/${INTERSECTION_ROUTE}/prefetch-schema?userConnectionId=${userConnectionId}&connectionObjectName=${connObjectName}`,
        apiConfig
    );
}

export async function postPreviewData(
    userConnectionId,
    connObjectName,
    filterObj,
    subObjectId = null,
    previewType = null
) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${identityToken}`,
    };

    const apiConfig = {
        headers,
    };

    let URL = `${BASE_URL}/${INTERSECTION_ROUTE}/get-preview-data?userConnectionId=${userConnectionId}&connectionObjectName=${connObjectName}&previewType=${previewType}`;
    if (subObjectId) URL += `&subObjectId=${subObjectId}`;

    return axios.post(URL, filterObj, apiConfig);
}

export async function fetchFilterOptions(connectionName) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/get-filter-options`,
        'GET',
        {
            connectionName,
        }
    );
}

export async function fetchFilterSchema(connectionName) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/get-filter-schema`,
        'GET',
        {
            connectionName,
        }
    );
}

export async function postUpdatedSchema(
    userConnectionId,
    connectionObjectName
) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };

    return axios.post(
        `${BASE_URL}/${INTERSECTION_ROUTE}/generate-full-schema?userConnectionId=${userConnectionId}&connectionObjectName=${connectionObjectName}`,
        {},
        {
            headers,
        }
    );
}

// EXPERIMENTATIONS
export async function fetchUsers() {
    return axios.get(`${BASE_URL}/users`);
}

export async function getUserConnectionStatus(userConnectionId) {
    return ExecuteAPI(`${BASE_URL}/${ASTRA_ROUTE}/get-user-connection`, 'GET', {
        userConnectionId,
    });
}

export async function getConnectionObjects(connectionId) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };
    return axios.get(
        `${BASE_URL}/${INTERSECTION_ROUTE}/get-connection-objects?connectionId=${connectionId}`,
        {
            headers,
        }
    );
}

export async function askLookUpAI(userConnectionId, query) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };
    return axios.post(
        `${BASE_URL}/${INTERSECTION_ROUTE}/ask-lookup-ai?userConnectionId=${userConnectionId}`,
        query,
        {
            headers,
        }
    );
}

export async function regenerateResponseFromLookUpAI(userConnectionId, query) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };
    return axios.post(
        `${BASE_URL}/${INTERSECTION_ROUTE}/regenerate-response-lookup-ai?userConnectionId=${userConnectionId}`,
        query,
        {
            headers,
        }
    );
}

export async function trackUserEvents(eventProperties, eventName) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };

    const data = {
        eventName,
        eventProperties,
    };

    return axios.post(`${BASE_URL}/${INTERSECTION_ROUTE}/track-event`, data, {
        headers,
    });
}

export async function trackLookupAIResponse(
    query,
    response,
    userConnectionId,
    status
) {
    const identityToken = sessionStorage.getItem('stackit-google-user-jwt');
    const headers = {
        Authorization: `Bearer ${identityToken}`,
    };

    const data = {
        eventName: 'lookup_ai_response',
        eventProperties: {
            userConnectionId,
            query,
            response,
            status,
        },
    };

    return axios.post(`${BASE_URL}/${INTERSECTION_ROUTE}/track-event`, data, {
        headers,
    });
}

export async function getDataPreviewConfig(
    userConnectionId,
    queryParams,
    metadata = {}
) {
    return ExecuteAPI(
        `${INTERSECTION_ROUTE}/data-preview-config/${userConnectionId}`,
        'POST',
        queryParams,
        metadata
    );
}

export async function getDataPreview(userConnectionId, queryParams, filterObj) {
    return ExecuteAPI(
        `${INTERSECTION_ROUTE}/data-preview/${userConnectionId}`,
        'POST',
        queryParams,
        filterObj
    );
}

export async function updateSheetName(importConfigId, newSheetName) {
    return ExecuteAPI(
        `${INTERSECTION_ROUTE}/update-sheet-name/`,
        'POST',
        {},
        {
            importConfigId,
            newSheetName,
        }
    );
}

export async function pollImportRunStatusByImportConfigId(importConfigId) {
    return ExecuteAPI(`${APOLLO_ROUTE}/import-run-status`, 'GET', {
        importConfigId,
    });
}

export async function cancelRunningImport(importRunId, importConfigId) {
    return ExecuteAPI(`${APOLLO_ROUTE}/cancel-import`, 'DELETE', {
        importRunId,
        importConfigId,
    });
}

export async function fetchImportRunHistoryByImportConfigId(
    page,
    importConfigId
) {
    return ExecuteAPI(
        `${APOLLO_ROUTE}/timeline/get-import-timeline/${importConfigId}`,
        'GET',
        {
            page,
            limit: 10,
        }
    );
}

/* Workflows apis */

export async function createWorkflow(createPayload) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/create-new-workflow`,
        'POST',
        {},
        {
            ...createPayload,
        }
    );
}

export async function deleteWorkflow(workflowId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/delete-workflow`,
        'POST',
        {},
        {
            workflowConfigId: workflowId,
        }
    );
}

export async function pauseWorkflow(workflowConfigId, pause) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/pause-workflow`,
        'POST',
        {},
        {
            workflowConfigId,
            pause,
        }
    );
}

export async function getWorkflowHistory(workflowConfigId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/history/get-workflow-history`,
        'GET',
        {
            workflowConfigId,
        }
    );
}

export async function dryRunAction(action) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/dry-run-action`,
        'POST',
        {},
        {
            action,
        }
    );
}

export async function getWorkflows() {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/get-workflows`,
        'GET'
    );
}

export async function getWorkflowDetails(workflowConfigId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/workflow/get-workflow-details`,
        'GET',
        {
            workflowConfigId,
        }
    );
}

export async function getSlackChannels(slackUserConnectionId) {
    return ExecuteAPI(`${BASE_URL}/${HERMES_ROUTE}/slack/channels`, 'GET', {
        slackUserConnectionId,
    });
}

export async function getAllConnections() {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/connections-listing`,
        'GET',
        {
            showAll: true,
        }
    );
}

/* Paywall apis */
export async function fetchSubscription() {
    return ExecuteAPI(`${ASTRA_ROUTE}/subscription/fetch-subscription`, 'GET');
}

export async function updateUserSubscription(annuallyChecked) {
    return ExecuteAPI(
        `${STRIPE_ROUTE}/checkout`,
        'POST',
        {},
        { annuallyChecked }
    );
}

export async function continueWithFreePlan() {
    return ExecuteAPI(
        `${ASTRA_ROUTE}/subscription/continue-free-plan`,
        'POST',
        {},
        {}
    );
}

// updatec column rearrangement
export async function preUpdateImportConfig(importConfigId) {
    return ExecuteAPI(`${APOLLO_ROUTE}/pre-update-import-config`, 'GET', {
        importConfigId,
    });
}

// derived column formula validation
export async function validateDerivedColumnFormula(
    userConnectionId,
    sessionId,
    formula
) {
    return ExecuteAPI(
        `${INTERSECTION_ROUTE}/validate-derived-column/${userConnectionId}`,
        'POST',
        { sessionId },
        { derivedColumn: formula }
    );
}

// advanced settings
export async function updateFormulaDrilldown(importConfigId, formulaDrillDown) {
    return ExecuteAPI(
        `${APOLLO_ROUTE}/update-import-config-formuladrilldown/${importConfigId}`,
        'PUT',
        {},
        { formulaDrillDown }
    );
}

// health check connection
export async function healthCheckConnection(
    userConnectionId,
    sharedConnection,
    metadata = {}
) {
    return ExecuteAPI(
        `${BASE_URL}/${ASTRA_ROUTE}/check-connection-health`,
        'POST',
        { userConnectionId, sharedConnection },
        metadata
    );
}

// template routes
export async function getTemplateConfigById(templateId) {
    return ExecuteAPI(
        `${BASE_URL}/${TEMPLATE_ROUTE}/get-template-config/${templateId}`,
        'GET'
    );
}

export async function updateUserConnectionAPIOrchestrator(
    connectionId,
    userConnectionId,
    authConfig,
    requestConfig,
    action
) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/update-user-connection`,
        'POST',
        {},
        {
            connectionId,
            userConnectionId,
            action,
            config: authConfig
                ? {
                      clientSecret: authConfig.clientSecret,
                      accessTokenURL: authConfig.accessTokenURL,
                      refreshTokenURL: authConfig.refreshTokenURL,
                  }
                : requestConfig,
        }
    );
}

// export routes
export async function getColumnConfig(userConnectionId, queryParams) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/export/get-column-config/${userConnectionId}`,
        'GET',
        queryParams
    );
}

export async function createExportConfig(exportConfigObject) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/export/export-config`,
        'POST',
        {},
        exportConfigObject
    );
}

export async function runExportNow(exportRunObject) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/export/export-now`,
        'POST',
        {},
        exportRunObject
    );
}

export async function pollExportRunStatusByExportRunId(exportRunId) {
    return ExecuteAPI(`${APOLLO_ROUTE}/export/export-run-status`, 'GET', {
        exportRunId,
    });
}

export async function exportTabData(spreadsheetId) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/get-export-tab`,
        'GET',
        {
            spreadsheetId,
        }
    );
}

export async function getExportConfig(exportConfigId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/export/export-config`,
        'GET',
        {
            exportConfigId,
        }
    );
}

export async function updateExportConfig(newExportConfigData, updateType) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/export/export-config`,
        'PUT',
        {},
        {
            ...newExportConfigData,
            updateType,
        }
    );
}

export async function deleteExportConfig(exportConfigId) {
    return ExecuteAPI(
        `${BASE_URL}/${APOLLO_ROUTE}/export/export-config`,
        'DELETE',
        {},
        {
            exportConfigId,
        }
    );
}

export async function initiateExportAuth(reqBody) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/initiate-export-auth`,
        'POST',
        {},
        reqBody
    );
}

export async function fetchConnectionInfo(connectionId) {
    return ExecuteAPI(
        `${BASE_URL}/${NEXUS_ROUTE}/connections/${connectionId}`,
        'GET',
        {}
    );
}

export async function getAppConfigDataForSharding() {
    return ExecuteAPI(`${BASE_URL}/${ZEUS_ROUTE}/app-config`, 'GET');
}

export async function submitUserSuggestion(reqBody) {
    return ExecuteAPI(
        `${BASE_URL}/${INTERSECTION_ROUTE}/submit-user-suggestion`,
        'POST',
        {},
        reqBody
    );
}

export async function fetchSuperGPTUsage() {
    return ExecuteAPI(
        `${BASE_URL}/${SUBSCRIPTION_ROUTE}/fetch-subscription`,
        'GET',
        { featureName: 'supergpt' }
    );
}

export async function createJobSchedule(jobPayload) {
    return ExecuteAPI(
        `${BASE_URL}/${CHRONA_ROUTE}/create-job-schedule`,
        'POST',
        {},
        jobPayload
    );
}

export async function deleteJobSchedule(scheduleId) {
    return ExecuteAPI(
        `${BASE_URL}/${CHRONA_ROUTE}/delete-job-schedule`,
        'DELETE',
        { scheduleId }
    );
}

export async function fetchExportRunHistoryByExportConfigId(
    page,
    exportConfigId
) {
    return ExecuteAPI(
        `${APOLLO_ROUTE}/export/timeline/get-export-timeline/${exportConfigId}`,
        'GET',
        {
            page,
            limit: 10,
        }
    );
}
