import React, { useState } from 'react';
import { Button, Drawer, Skeleton, Space } from 'antd';
import './Loader.css';
import { deleteUserConnection } from '../../network/api';

export default function Loader(props) {
    const StackItIcon = 'https://assets.superjoin.ai/images/stackit-icon.svg';
    const sheetsIcon = 'https://assets.superjoin.ai/images/gsheets.svg';
    const [openEdit, setOpenEdit] = useState(true);

    const [cancelling, setCancelling] = useState(false);

    const handleCancel = async () => {
        try {
            setCancelling(true);
            await deleteUserConnection({
                connectionId: props.connectionId,
                userConnectionId: props.userConnectionId,
                markConnectionAborted: true,
                abortOnlyExport: props?.isReAuth || false,
            });
        } catch (err) {
            // TODO: Handle error
        }
    };

    return (
        <Drawer
            placement={'bottom'}
            closable={false}
            open={openEdit}
            key={'bottom'}
            height={180}
        >
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <img className='ic-card-image' src={StackItIcon} />
                        <Space
                            className='ic-progress-bar pl-[10px]'
                            direction='vertical'
                            style={{
                                width: '50px !important',
                            }}
                        >
                            <Skeleton
                                paragraph={false}
                                active
                                className='w-14'
                                style={{
                                    height: '5px !important',
                                }}
                            />
                        </Space>
                        <img
                            className='ic-card-image'
                            style={{ marginLeft: '12px' }}
                            src={props.connectionLogo || sheetsIcon}
                            width={38}
                            height={38}
                        />
                    </div>
                    <h4>Authorizing...</h4>
                    <p
                        style={{
                            color: 'var(--grey-secondary, #84848D)',
                            textAlign: 'center',
                            fontSize: 12,
                            margin: '0px',
                            padding: '0px',
                            textTransform: 'capitalize',
                        }}
                    >
                        Sit tight while we authorize your connection
                    </p>
                    {props && props.userConnectionId && props.connectionId && (
                        <Button
                            className={
                                'hover:text-stackit-danger-primary text-center text-xs cursor-pointer text-stackit-danger-primary m-0 bg-transparent border-none shadow-none'
                            }
                            loading={cancelling}
                            onClick={handleCancel}
                            disabled={cancelling}
                        >
                            <span
                                className={
                                    'underline hover:text-stackit-danger-primary'
                                }
                            >
                                {cancelling ? 'Cancelling' : 'Cancel'}
                            </span>
                        </Button>
                    )}
                </div>
            </>
        </Drawer>
    );
}
