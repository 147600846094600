const SPREADSHEET_ID = '1hJEgKrHU5h2fWO4Y8K1MXRvdvXKXjKeh0hJODKTRh4k';

const SHEET_ID = '0';

const SHEET_NAME = 'Sheet1';

const SERVER_FUNCTION_CONSTANTS = {
    SPREADSHEET_ID,
    SHEET_ID,
    SHEET_NAME,
};

module.exports = {
    SERVER_FUNCTION_CONSTANTS,
};
