import React from 'react';
import { MdAddCircle } from 'react-icons/md';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';

function AddColumnButton() {
    const {
        activeConnectionObj,
        showColumnActions,
        setShowColumnActions,
        setColumnSearchText,
    } = useDataPreviewContext();

    const handelToggle = () => {
        setShowColumnActions(!showColumnActions);
        setColumnSearchText('');
    };
    return (
        <div
            onClick={() => handelToggle()}
            className=' bg-white border-dashed border-stackit-primary border-[1px] py-[6px] rounded-md text-stackit-primary hover:bg-stackit-background-2/20 flex items-center px-[5px] border-l-[4px] border-l-stackit-primary border-l-dashed cursor-pointer'
        >
            <MdAddCircle />
            <button className='text-stackit-primary bg-transparent border-none cursor-pointer text-[12px] font-[500]'>
                Add or Modify{' '}
                {activeConnectionObj?.connectionName ===
                CONNECTIONS.GOOGLE_SEARCH_CONSOLE
                    ? 'Metrics'
                    : 'Fields'}
            </button>
        </div>
    );
}

export default AddColumnButton;
