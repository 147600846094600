import React from 'react';

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div className='flex w-full h-[96%] flex-1 justify-center items-center flex-col overflow-hidden bg-stackit-background-1/20 rounded-3xl'>
            <img
                src='https://assets.superjoin.ai/images/multi-user-error.png'
                className='w-[200px] h-[200px] object-contain'
            />
            <p className='text-[20px] font-semibold m-0 p-0 my-6'>
                Aaaah! Something went wrong
            </p>
            <p className='text-center m-0 p-0 mb-6'>
                Brace yourself till we get the error fixed.
                <br />
                You many also refresh the page or try again later.
            </p>
            <button
                onClick={resetErrorBoundary}
                className='bg-stackit-primary px-6 py-2 rounded-lg cursor-pointer text-white border-0 hover:scale-[103%] active:scale-100'
            >
                Try Again
            </button>
        </div>
    );
}

export default ErrorFallback;
