import { Decoration, EditorView, MatchDecorator, ViewPlugin, WidgetType } from "@codemirror/view";
import React from "react";
import { createRoot } from "react-dom/client";
import Vlookup from "./Vlookup";
import { StateEffect, StateField } from "@codemirror/state";

class VlookupWidget extends WidgetType {
	constructor(view, key, handleOpenRangeSelector, handleMangeVlookupData, columnOptions) {
		super();
		this.view = view;
		this.key = key;
		this.handleOpenRangeSelector = handleOpenRangeSelector;
		this.handleMangeVlookupData = handleMangeVlookupData;
		this.container = document.createElement("div");
		this.fieldData = view.state.field(placeholderTextState)[key]?.fieldData;
		this.rangeData = view.state.field(placeholderTextState)[key]?.rangeData;
		this.indexData = view.state.field(placeholderTextState)[key]?.indexData;
		this.sortData = view.state.field(placeholderTextState)[key]?.sortData;
		this.columnOptions = columnOptions;
		this.root = createRoot(this.container);
	}

	eq(other) {
		try {
			return other.key === this.key && other.rangeData === this.rangeData;
		} catch (error) {
			console.error("Error comparing widget keys:", error);
			return false;
		}
	}

	toDOM() {
		try {
			this.root.render(
				<Vlookup
					wid={this.key}
					view={this.view}
					handleOpenRangeSelector={this.handleOpenRangeSelector}
					handleManageVlookupData={this.handleMangeVlookupData}
					fieldData={this.fieldData}
					rangeData={this.rangeData}
					indexData={this.indexData}
					sortData={this.sortData}
					columnOptions={this.columnOptions}
				/>
			);
			return this.container;
		} catch (error) {
			console.error("Error creating widget DOM:", error);
			const span = document.createElement("span");
			span.textContent = "Error";
			return span;
			return null;
		}
	}

	ignoreEvent(event) {
		// Allow the input to gain focus and handle its own events
		return event.target.tagName === "INPUT" || event.target.tagName === "SELECT" || event.target.tagName === "OPTION" || event.target.tagName === "BUTTON";
	}

	destroy() {
		// Cleanup: Unmount the React component when the widget is destroyed
		this.root.unmount();
	}
}

// Adjusting MatchDecorator to use inline widgets for placeholders
const placeholderDecorator = (handleOpenRangeSelector, handleMangeVlookupData, columnOptions) => {
	return new MatchDecorator({
		regexp: /\[\[widget id="(\w+)"\]\]/g,
		decoration: (match, view) => {
			try {
				return Decoration.widget({
					widget: new VlookupWidget(view, match[1], handleOpenRangeSelector, handleMangeVlookupData, columnOptions),
					side: 1,
					inclusive: false,
					block: false,
				});
			} catch (error) {
				console.error("Error creating widget decoration:", error);
				return null;
			}
		},
	});
};

export const vlookupPlugin = (handleOpenRangeSelector, handleMangeVlookupData, columnOptions) => {
	let placeholderWithFunction = placeholderDecorator(handleOpenRangeSelector, handleMangeVlookupData, columnOptions);
	return ViewPlugin.fromClass(
		class {
			decorations;

			constructor(view) {
				this.decorations = placeholderWithFunction.createDeco(view);
			}

			update(update) {
				try {
					placeholderWithFunction = placeholderDecorator(handleOpenRangeSelector, handleMangeVlookupData, columnOptions);
					this.decorations = placeholderWithFunction.createDeco(update.view);
				} catch (error) {
					console.error("Error updating decorations:", error);
					this.decorations = Decoration.none;
				}
			}
		},
		{
			decorations: (v) => v.decorations,
			provide: (plugin) =>
				EditorView.atomicRanges.of((view) => {
					try {
						return view.plugin(plugin)?.decorations || Decoration.none;
					} catch (error) {
						console.error("Error providing decorations:", error);
						return Decoration.none;
					}
				}),
		}
	);
};

export const placeholderTextEffect = StateEffect.define();
export const placeholderTextState = StateField.define({
	create() {
		return {};
	},
	update(value, tr) {
		for (const effect of tr.effects) if (effect.is(placeholderTextEffect)) return effect.value;
		return value;
	},
});
