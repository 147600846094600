import React from 'react';
import { FaTable } from 'react-icons/fa';
import { Select, Tooltip } from 'antd';
import SelectedColumnsTree from '../common/SelectedColumnsTree';
import { readableColumnName } from '../../../../utils/jsUtils';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';
import { getDataPreviewConfig } from '../../../../network/api';

const ExternalDataPreviewConfig = () => {
    const {
        activeConnectionObj,
        selectedColumns,
        setSelectedColumns,
        setColumnMapping,
        columnSelectionTree,
        setColumnSelectionTree,
        setDataSelectedColumns,
        setDataFilterOptions,
        columnMapping,
        setShowColumnActions,
        streamNames,
        setStreamNames,
        setIsDataLoading,
        setCollapseAllObjects,
        setAppliedLimit,
        setListOfAppliedFilters,
        setAppliedSorts,
    } = useDataPreviewContext();

    const [streamsList, setStreamsList] = React.useState([]);

    const [objectLoadingState, setObjectLoadingState] = React.useState(true);

    const generateTableOptions = (tables, objectName) => {
        const tableOptions = [];
        Object.keys(tables).forEach((key) => {
            const table = tables[key];
            const tableOption = {
                key: table?.key,
                title: table?.title,
                label: table?.title,
                value: table?.value,
                isParentObject: table?.title === objectName,
                children: table?.children,
            };
            tableOptions.push(tableOption);
        });
        return tableOptions;
    };

    const generateColumnMapping = (tree) => {
        try {
            const columnMappings = {};
            Object.entries(tree || {}).forEach(([key, value]) => {
                if (value.children) {
                    value.children.map((grandChild, index) => {
                        columnMappings[grandChild?.key] = {
                            title: grandChild?.title,
                            parent: value?.title,
                            grandParent: undefined,
                            readableTitle: `[${value?.title}] ${grandChild?.title}`,
                        };
                    });
                }
            });

            return columnMappings;
        } catch (error) {
            console.log('Error in generateColumnMapping: ', error);
            return {};
        }
    };
    const getExternalDataSource = (treeSelectValue) => {
        return treeSelectValue.map((value) => {
            const checksort = value.split('.')[1];
            return {
                title: value.startsWith('FX.')
                    ? readableColumnName(value)
                    : columnMapping[value]?.title,
                dataIndex: `${value.split('.')[0]}.${
                    value.split('.')[value.split('.').length - 1]
                }`,
                key: `${value.split('.')[0]}.${
                    value.split('.')[value.split('.').length - 1]
                }`,
                fullKey: value,
                ellipsis: {
                    showTitle: false,
                },
                render: (textVal) => (
                    <Tooltip placement='topLeft' title={textVal}>
                        {textVal}
                    </Tooltip>
                ),
                sorter: ['created_at', 'updated_at', 'date'].includes(checksort)
                    ? (a, b) => (a[value] < b[value] ? -1 : 1)
                    : null,
            };
        });
    };

    React.useEffect(() => {
        const newColumns = getExternalDataSource(selectedColumns);
        setDataSelectedColumns(newColumns);
    }, [selectedColumns, columnSelectionTree]);

    const GetUserProperties = (Schema, name) => {
        const subObjectArr = [];
        const formattedSchema = {};
        Object.keys(Schema).forEach((key) => {
            const oneInstance = {};
            if (Schema[key].type) {
                if (Array.isArray(Schema[key].type)) {
                    if (
                        Schema[key].type[1] === 'object' &&
                        Schema[key].properties
                    ) {
                        oneInstance.key = `${name}.${key}`;
                        oneInstance.title = key;
                        oneInstance.type = Schema[key].type[1];
                        oneInstance.children = Object.keys(
                            Schema[key].properties
                        ).map((item) => {
                            return {
                                title: item,
                                key: `${key}.${item}`,
                                value: item,
                                type:
                                    Schema[key].properties[item].type[1] ?? ' ',
                            };
                        });
                    } else if (
                        Schema[key].type[1] === 'object' &&
                        Schema[key].additionalProperties
                    ) {
                        oneInstance.key = `${name}.${key}`;
                        oneInstance.title = key;
                        oneInstance.value = key;
                        oneInstance.type = 'object';
                        oneInstance.children = [];
                    } else if (
                        (Schema[key].type[1] === 'string' ||
                            Schema[key].type[0] === 'string') &&
                        Schema[key].format === 'date-time'
                    ) {
                        oneInstance.key = `${name}.${key}`;
                        oneInstance.title = key;
                        oneInstance.value = key;
                        oneInstance.type = 'datetime';
                        oneInstance.children = [];
                    } else {
                        oneInstance.key = `${name}.${key}`;
                        oneInstance.title = key;
                        oneInstance.value = key;
                        oneInstance.type =
                            Schema[key].type[1] === 'null'
                                ? Schema[key].type[0]
                                : Schema[key].type[1];
                        oneInstance.children = [];
                    }
                } else {
                    oneInstance.key = `${name}.${key}`;
                    oneInstance.title = key;
                    oneInstance.value = key;
                    oneInstance.type = Schema[key].type;
                    oneInstance.children = [];
                }
            } else {
                oneInstance.key = `${name}.${key}`;
                oneInstance.title = key;
                oneInstance.value = key;
                oneInstance.type = 'unknown';
                oneInstance.children = [];
            }

            subObjectArr.push(oneInstance);
        });

        formattedSchema[name] = {
            children: subObjectArr,
            title: name,
            key: name,
            type: 'object',
            value: name,
        };
        return formattedSchema;
    };

    React.useEffect(() => {
        const fetchData = async () => {
            setIsDataLoading(true);
            setShowColumnActions(true);
            const response = await getDataPreviewConfig(
                activeConnectionObj.userConnectionId
            );
            if (response.data?.streams) {
                setStreamsList(response.data.streams);
                setDataFilterOptions(response.data.filterOptions);
            }
        };

        fetchData();
    }, [activeConnectionObj.userConnectionId]);

    React.useEffect(() => {
        if (streamNames && streamsList.length > 0) {
            setObjectLoadingState(false);
            if (streamsList.length > 0) {
                const selectedObj = streamsList[0].stream.name;
                const subObjects = GetUserProperties(
                    streamsList.find((item) => item.stream.name === selectedObj)
                        .stream.jsonSchema.properties,
                    selectedObj
                );
                if (subObjects) {
                    setStreamNames({
                        label: selectedObj,
                        value: selectedObj,
                        stream: streamsList.find(
                            (item) => item.stream.name === selectedObj
                        ).stream,
                        config: streamsList.find(
                            (item) => item.stream.name === selectedObj
                        ).config,
                        subObjects: subObjects,
                    });
                    const formattedSchemaCopy = generateTableOptions(
                        subObjects,
                        selectedObj
                    );
                    setIsDataLoading(false);

                    setColumnMapping(generateColumnMapping(subObjects));
                    setSelectedColumns([]);
                    setColumnSelectionTree(formattedSchemaCopy);
                    setSelectedColumns(
                        subObjects[selectedObj].children
                            .slice(0, 5)
                            .map((item) => item.key)
                    );
                    setCollapseAllObjects(false);
                }
            }
        }
    }, [streamsList]);

    return (
        <div className=' px-[13.5px] py-[13.5px] flex w-full overflow-hidden justify-center scrollbar-hide'>
            <div className='gap-y-[15px] w-full overflow-hidden flex flex-col'>
                <div className='flex-shrink'>
                    <div>
                        <h4 className='text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                            <FaTable className='mr-2' />
                            OBJECTS
                        </h4>
                    </div>
                    <div className='mt-[8.8px]'>
                        <Select
                            className='w-full'
                            value={{
                                label: streamNames.label ?? ' ',
                                value: streamNames.value ?? ' ',
                            }}
                            loading={objectLoadingState}
                            disabled={objectLoadingState}
                            onChange={(selectedObj) => {
                                const subObjects = GetUserProperties(
                                    streamsList.find(
                                        (item) =>
                                            item.stream.name === selectedObj
                                    ).stream.jsonSchema.properties,
                                    selectedObj
                                );
                                if (subObjects) {
                                    setStreamNames({
                                        label: selectedObj,
                                        value: selectedObj,
                                        stream: streamsList.find(
                                            (item) =>
                                                item.stream.name === selectedObj
                                        ).stream,
                                        config: streamsList.find(
                                            (item) =>
                                                item.stream.name === selectedObj
                                        ).config,
                                        subObjects: subObjects,
                                    });
                                    const formattedSchemaCopy =
                                        generateTableOptions(
                                            subObjects,
                                            selectedObj
                                        );
                                    setColumnMapping(
                                        generateColumnMapping(subObjects)
                                    );
                                    setSelectedColumns(
                                        subObjects[selectedObj].children
                                            .slice(0, 5)
                                            .map((item) => item.key)
                                    );
                                    setAppliedLimit('');
                                    setAppliedSorts([]);
                                    setListOfAppliedFilters([]);
                                    setColumnSelectionTree(formattedSchemaCopy);
                                }
                            }}
                            listHeight={300}
                            options={streamsList.map((item) => ({
                                label: item.stream.name,
                                value: item.stream.name,
                            }))}
                        />
                    </div>
                </div>

                <SelectedColumnsTree selectedColumns={selectedColumns} />
            </div>
        </div>
    );
};

export default ExternalDataPreviewConfig;
