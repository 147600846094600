import React from 'react';
import { Select } from 'antd';
import { fetchFilterOptions, getImportedPageData } from '../../../network/api';

// import {
//     createChargebeeFilterObject,
//     createChargebeeImportFilterObj,
//     createChargebeeImportFilterObjWithSort,
//     createChargebeeSortFilterObj,
//     extractSortOrder,
//     processChargebeeFilters,
//     setChargebeeColumnNamesForFilters,
//     setChargebeeEditConfigColumns,
//     setChargebeeEditConfigFilters,
//     formatChargebeeImportConfig,
//     getChargebeeDataSource,
//     renderFilterNamesChargebee,
// } from '../Components/Chargebee/Chargebee';
// import {
//     createHubspotFilterObject,
//     createHubspotImportFilterObj,
//     createHubspotImportFilterObjWithSort,
//     createHubspotSortFilterObj,
//     setHubspotColumnNamesForFilters,
//     setHubspotEditConfigColumns,
//     setHubspotEditConfigFilters,
//     formatHubspotImportConfig,
//     getHubspotDataSource,
//     renderFilterNamesHubspot,
// } from '../Components/Hubspot/Hubspot';
import { CONNECTIONS } from '../../../constants/connection-constants';

const { DATE_RANGE_TYPE } = require('../../../constants/date-range-constants');

const { Option } = Select;

const transformEmptyImportFilterObject = (connectionName) => {
    let importFilterObj = {};
    switch (connectionName) {
        case 'Chargebee':
            importFilterObj = {};
            break;
        case 'Hubspot':
            importFilterObj = {
                filterGroups: [],
                sorts: [],
            };
            break;
        default:
            break;
    }
    return importFilterObj;
};

// connectionImportConfig is a JS object that contains attributes like object, popularColumnList, etc. depending on the connection
const transformImportConfigObject = (
    connectionName,
    connectionImportConfig,
    importType,
    importConfigId = null,
    userConnectionId = null,
    currentSheetId = null,
    sharedConnection = false
) => {
    const importConfigObject = {};

    if (importType === 'update') {
        importConfigObject.importConfigId = importConfigId;
    } else {
        importConfigObject.userConnectionId = userConnectionId;
        importConfigObject.spreadsheetId = currentSheetId;
    }

    switch (connectionName) {
        case 'Hubspot':
        // importConfigObject.connectionImportConfig =
        //     formatHubspotImportConfig(
        //         connectionImportConfig.object,
        //         connectionImportConfig.subObject,
        //         connectionImportConfig.popularColumnList,
        //         connectionImportConfig.columnsList,
        //         connectionImportConfig.filters,
        //         connectionImportConfig.sortBy,
        //         connectionImportConfig.sortOrder
        //     );
        // break;
        case 'Chargebee':
        // importConfigObject.connectionImportConfig =
        //     formatChargebeeImportConfig(
        //         connectionImportConfig.object,
        //         connectionImportConfig.subObject,
        //         connectionImportConfig.popularColumnList,
        //         connectionImportConfig.columnsList,
        //         connectionImportConfig.filters,
        //         connectionImportConfig.sortBy,
        //         connectionImportConfig.sortOrder
        //     );
        // break;
        case 'Linkedin':
            // eslint-disable-next-line no-case-declarations
            const metricKeys = connectionImportConfig.metrics
                .map((metric) => metric.value)
                .filter((metric) => metric !== null || metric !== undefined);
            importConfigObject.connectionImportConfig = {
                adAccId: connectionImportConfig.adAccId,
                accountName: connectionImportConfig.accountName,
                metrics: metricKeys,
                displayMetrics: connectionImportConfig.displayMetrics,
                pivots: connectionImportConfig.pivots,
                campaigns: connectionImportConfig.campaigns,
                campaignGroups: connectionImportConfig.campaignGroups,
                campaignType: connectionImportConfig.campaignTypes,
                timeGranularity: connectionImportConfig.timeGranularity,
            };
            if (connectionImportConfig.dateRangeType === DATE_RANGE_TYPE.LAST) {
                importConfigObject.connectionImportConfig.dateRange = {
                    type: connectionImportConfig.dateRangeType,
                    lastXDays: connectionImportConfig.lastXDays,
                };
            } else if (
                connectionImportConfig.dateRangeType === DATE_RANGE_TYPE.FIXED
            ) {
                importConfigObject.connectionImportConfig.dateRange = {
                    type: connectionImportConfig.dateRangeType,
                    startDate: connectionImportConfig.startDate,
                    endDate: connectionImportConfig.endDate,
                };
            }
            break;
        default:
            break;
    }

    return {
        ...importConfigObject,
        sharedConnection,
    };
};

const setSortFromLookUpAI = (
    sortObj,
    setSortBy,
    setSortOrder,
    connectionName
) => {
    // if (
    //     connectionName === 'Chargebee' &&
    //     sortObj &&
    //     Object.keys(sortObj).length
    // ) {
    //     const sortKey = Object.keys(sortObj)[0];
    //     const sortOrder = extractSortOrder(sortKey);
    //     if (sortOrder) {
    //         setSortOrder(sortOrder);
    //         setSortBy(sortObj[sortKey]);
    //     }
    // }
};

const resetFilterState = (
    setFilterColumnName,
    setFilterColumnNameLabel,
    setFilterOperation,
    setFilterOperationLabel,
    setFilterValue,
    setFilterValueLabel,
    setFilterReady,
    setInitializeLookupAI
) => {
    setFilterColumnName('Create a new Filter');
    setFilterColumnNameLabel('');
    setFilterOperation('');
    setFilterOperationLabel('');
    setFilterValue('');
    setFilterValueLabel('');
    setFilterReady(false);
    setInitializeLookupAI(false);
};

const getFiltersFromLookUpAI = (
    filtersFromLookUpAI,
    filters,
    setFilters,
    setFilterColumnName,
    setFilterColumnNameLabel,
    setFilterOperation,
    setFilterOperationLabel,
    setFilterValue,
    setFilterValueLabel,
    setFilterReady,
    setInitializeLookupAI,
    setLookupAIButtonText,
    setFetchLoading,
    setErrorMsginLookupAI,
    connectionName
) => {
    switch (connectionName) {
        case 'Chargebee':
            // try {
            //     setFetchLoading(true);

            //     const newFiltersToAdd = processChargebeeFilters(
            //         filtersFromLookUpAI,
            //         filters
            //     );

            //     setFilters((prevFilters) => [
            //         ...prevFilters,
            //         ...newFiltersToAdd,
            //     ]);

            //     resetFilterState(
            //         setFilterColumnName,
            //         setFilterColumnNameLabel,
            //         setFilterOperation,
            //         setFilterOperationLabel,
            //         setFilterValue,
            //         setFilterValueLabel,
            //         setFilterReady,
            //         setInitializeLookupAI
            //     );
            // } catch (err) {
            //     setErrorMsginLookupAI(
            //         'Something went wrong while fetching filters from LookUp AI. Please try again'
            //     );
            //     setLookupAIButtonText('Ask Again');
            //     setInitializeLookupAI(false);
            // } finally {
            //     setFetchLoading(false);
            // }
            break;
        default:
            break;
    }
};

const getDataSourceForTable = (
    treeSelectValue,
    columnSelectionTree,
    connectionName,
    setPopularColumnList
) => {
    switch (connectionName) {
        case 'Hubspot':
        // return getHubspotDataSource(
        //     treeSelectValue,
        //     columnSelectionTree,
        //     setPopularColumnList
        // );
        case 'Chargebee':
        // return getChargebeeDataSource(treeSelectValue);
        default:
            return [];
    }
};

const fetchImportedPageData = async (
    state,
    setFetchLoading,
    setPopularColumnList,
    setImportedFilters,
    setImportedColumns,
    setUserConnectionId,
    setErrorMsg,
    setSubObject,
    setDerivedColumnsData = undefined
) => {
    try {
        setFetchLoading(true);
        const response = await getImportedPageData(
            state.importConfigId,
            state.sharedConfig == null ? false : state.sharedConfig
        );
        if (response.status === 200) {
            if (
                response.data.importConfig.connectionImportConfig
                    .popularColumnList &&
                response.data.importConfig.connectionImportConfig
                    .popularColumnList.length > 0
            ) {
                setPopularColumnList(
                    response.data.importConfig.connectionImportConfig
                        .popularColumnList
                );
            }
            setUserConnectionId(response.data.importConfig.userConnectionId);
            switch (state.connectionName) {
                case 'Chargebee':
                    setImportedFilters(
                        response.data.importConfig.connectionImportConfig
                            .filters ?? []
                    );
                    setSubObject(
                        response.data.importConfig.connectionImportConfig
                            .subObject
                    );
                    setDerivedColumnsData(
                        response.data.importConfig.connectionImportConfig
                            .derivedColumns ?? []
                    );
                    break;
                case 'Hubspot':
                    // setImportedFilters(response.data.importConfig.connectionImportConfig.filterGroups);
                    // setSubObject(response.data.importConfig.connectionImportConfig.subObject);
                    // setDerivedColumnsData(response.data.importConfig.connectionImportConfig.derivedColumns ?? []);
                    break;
                default:
                    break;
            }
            setImportedColumns(
                response.data.importConfig.connectionImportConfig.columns
            );
        } else {
            setErrorMsg(response?.data?.message);
            setTimeout(() => {
                setErrorMsg('');
            }, 3000);
        }
    } catch (err) {
        setErrorMsg(err?.response?.data?.message);
        setTimeout(() => {
            setErrorMsg('');
        }, 3000);
    }
};

const setEditConfigColumns = (
    connectionName,
    importedColumns,
    setTreeSelectValue,
    columnSelectionTree,
    popularColumnList,
    setFilterTree
) => {
    switch (connectionName) {
        case 'Chargebee':
            // setChargebeeEditConfigColumns(importedColumns, setTreeSelectValue);
            break;
        case 'Hubspot':
            // setHubspotEditConfigColumns(
            //     importedColumns,
            //     setTreeSelectValue,
            //     columnSelectionTree,
            //     popularColumnList,
            //     setFilterTree
            // );
            break;
        default:
            break;
    }
};
const setEditConfigFilters = (
    connectionName,
    importedFilters,
    setFilters,
    columnSelectionTree = []
) => {
    switch (connectionName) {
        case 'Chargebee':
            // setChargebeeEditConfigFilters(
            //     importedFilters,
            //     setFilters,
            //     columnSelectionTree,
            //     connectionName
            // );
            break;
        case 'Hubspot':
            // setHubspotEditConfigFilters(importedFilters, setFilters);
            break;
        default:
            break;
    }
};

const setColumnNamesForFilters = (
    connectionName,
    object,
    setColNames,
    filterTree,
    filterSchema,
    treeSelectValue
) => {
    switch (connectionName) {
        case 'Chargebee':
            // setChargebeeColumnNamesForFilters(
            //     object,
            //     setColNames,
            //     filterSchema,
            //     treeSelectValue
            // );
            break;
        case 'Hubspot':
            // setHubspotColumnNamesForFilters(setColNames, filterTree);
            break;
        default:
            setColNames([]);
            break;
    }
};

const getImportFilterObjectInFiltersAndSorts = (
    connectionName,
    filters,
    sortBy,
    sortOrder
) => {
    switch (connectionName) {
        case 'Chargebee':
        // return createChargebeeImportFilterObjWithSort(
        //     filters,
        //     sortBy,
        //     sortOrder
        // );
        case 'Hubspot':
        // return createHubspotImportFilterObjWithSort(
        //     filters,
        //     sortBy,
        //     sortOrder
        // );
        default:
            return {};
    }
};

const getImportFilterObjectInSorts = (connectionName, sortBy, sortOrder) => {
    switch (connectionName) {
        case 'Chargebee':
        // return createChargebeeSortFilterObj(sortBy, sortOrder);
        case 'Hubspot':
        // return createHubspotSortFilterObj(sortBy, sortOrder);
        default:
            return {};
    }
};

const getImportFilterObjectInFilters = (connectionName, filters) => {
    switch (connectionName) {
        case 'Chargebee':
        // return createChargebeeImportFilterObj(filters);
        case 'Hubspot':
        // return createHubspotImportFilterObj(filters);
        default:
            return {};
    }
};

const dynamicFilterConversion = (
    updatedFilters,
    existingFilterIndex,
    existingFilterValue,
    newFilterObj,
    setFilters
) => {
    const updatedFiltersCopy = [...updatedFilters];
    if (typeof existingFilterValue === 'string') {
        let newArr = [];
        newArr.push(existingFilterValue);
        newArr.push(newFilterObj.value);
        newArr = newArr.map((value) => value.toLowerCase());
        updatedFiltersCopy[existingFilterIndex] = {
            ...updatedFiltersCopy[existingFilterIndex],
            value: newArr,
            valueLabel: newArr.join(', '),
        };
        switch (newFilterObj.operation) {
            case 'is':
                updatedFiltersCopy[existingFilterIndex].operation = 'in';
                break;
            case 'is_not':
                updatedFiltersCopy[existingFilterIndex].operation = 'not_in';
                break;
            case 'CONTAINS_TOKEN':
                updatedFiltersCopy[existingFilterIndex].operation = 'IN';
                break;
            case 'NOT_CONTAINS_TOKEN':
                updatedFiltersCopy[existingFilterIndex].operation = 'NOT_IN';
                break;
            default:
                break;
        }
        setFilters(updatedFiltersCopy);
    } else {
        existingFilterValue.push(newFilterObj.value);
        updatedFiltersCopy[existingFilterIndex] = {
            ...updatedFiltersCopy[existingFilterIndex],
            value: existingFilterValue,
            valueLabel: existingFilterValue.join(', '),
        };
        switch (newFilterObj.operation) {
            case 'is':
                updatedFiltersCopy[existingFilterIndex].operation = 'in';
                break;
            case 'is_not':
                updatedFiltersCopy[existingFilterIndex].operation = 'not_in';
                break;
            case 'CONTAINS_TOKEN':
                updatedFiltersCopy[existingFilterIndex].operation = 'IN';
                break;
            case 'NOT_CONTAINS_TOKEN':
                updatedFiltersCopy[existingFilterIndex].operation = 'NOT_IN';
                break;
            default:
                break;
        }
        setFilters(updatedFiltersCopy);
    }
};

const createNewFilterObject = (
    connectionName,
    filterColumnName,
    filterColumnNameLabel,
    filterOperation,
    filterOperationLabel,
    filterValue,
    filterValueLabel,
    hubspotFilterGrouping,
    dateValue
) => {
    let newFilterObj = {};
    switch (connectionName) {
        case 'Chargebee':
            // newFilterObj = createChargebeeFilterObject(
            //     filterColumnName,
            //     filterOperation,
            //     filterValue,
            //     filterColumnNameLabel,
            //     filterOperationLabel,
            //     filterValueLabel
            // );
            break;
        case 'Hubspot':
            // newFilterObj = createHubspotFilterObject(
            //     filterColumnName,
            //     filterOperation,
            //     filterValue,
            //     filterColumnNameLabel,
            //     filterOperationLabel,
            //     filterValueLabel,
            //     hubspotFilterGrouping,
            //     dateValue
            // );
            break;
        default:
            newFilterObj = {
                column: filterColumnName.split('.').pop(),
                operation: filterOperation,
                value: filterValue,
                columnLabel: filterColumnNameLabel,
                operationLabel: filterOperationLabel,
                valueLabel: filterValueLabel,
                and: hubspotFilterGrouping,
            };
            break;
    }
    return newFilterObj;
};

function renderFilterColumnOptions(connName, columns, object) {
    switch (connName) {
        case CONNECTIONS.CHARGEBEE:
        // return renderFilterNamesChargebee(columns);
        case CONNECTIONS.HUBSPOT:
        // return renderFilterNamesHubspot(columns, object);
        default:
            return null;
    }
}

function renderFilterOptions(connectionName, operations, filterColumnType) {
    switch (connectionName) {
        case 'Chargebee':
            const cbFilterGroup = operations.ChargebeeOptions.filter(
                (group) =>
                    group.label.toUpperCase() === filterColumnType.toUpperCase()
            );
            return cbFilterGroup[0]?.options?.map((option) => (
                <Option key={option.key} value={option.value}>
                    {option.label}
                </Option>
            ));

        case 'Hubspot':
            const hsFilterGroup = operations.HubspotOptions.filter(
                (group) =>
                    group.label.toUpperCase() === filterColumnType.toUpperCase()
            );
            return hsFilterGroup[0]?.options?.map((option) => (
                <Option key={option.key} value={option.value}>
                    {option.label}
                </Option>
            ));

        default:
            return null;
    }
}

const fetchFilters = async (
    connectionName,
    setFilterErrorMsg,
    setOperations
) => {
    try {
        const response = await fetchFilterOptions(connectionName);
        if (response.status === 200) {
            setOperations({
                [connectionName]: {
                    TextOperations:
                        response.data[`${connectionName}TextOperations`],
                    NumberOperations:
                        response.data[`${connectionName}NumberOperations`],
                    DateOperations:
                        response.data[`${connectionName}DateOperations`],
                    BooleanOperations:
                        response.data[`${connectionName}BooleanOperations`],
                },
                [`${connectionName}Options`]:
                    response.data[`${connectionName}Options`],
            });
        }
    } catch (error) {
        setFilterErrorMsg(error?.response?.data?.message);
        setTimeout(() => {
            setFilterErrorMsg('');
        }, 10000);
    }
};

const convertToTitleCase = (str) => {
    if (!str) {
        return '';
    }

    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase().concat(word.substr(1));
        })
        .join(' ');
};

const convertToTitleCaseForPostgres = (str, includeSchemaName) => {
    if (!str) {
        return '';
    }

    const splittedStr = str.split('.');

    if (includeSchemaName) {
        return `[${splittedStr[0]}] ${splittedStr.slice(1).join('.')}`;
    }
    return `${splittedStr.slice(1).join('.')}`;
};

const convertToHeaderForSQL = (
    str,
    connectionName,
    includeSchemaName = false
) => {
    switch (connectionName) {
        case CONNECTIONS.POSTGRES:
        case CONNECTIONS.REDSHIFT:
            return convertToTitleCaseForPostgres(str, includeSchemaName);
        default:
            return convertToTitleCase(str);
    }
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${amOrPm}, ${day} ${months[monthIndex]}`;
};

const formatColumnName = (columnName) => {
    return columnName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const validateImportedFilters = (connectionName, importedFilters) => {
    switch (connectionName) {
        case 'Chargebee':
            if (
                importedFilters &&
                typeof importedFilters === 'object' &&
                Object.keys(importedFilters).length > 0
            )
                return true;
            return false;
        case 'Hubspot':
            if (
                importedFilters &&
                Array.isArray(importedFilters) &&
                importedFilters.length > 0
            )
                return true;
            return false;
        default:
            return false;
    }
};

export {
    transformEmptyImportFilterObject,
    transformImportConfigObject,
    setSortFromLookUpAI,
    getFiltersFromLookUpAI,
    getDataSourceForTable,
    fetchImportedPageData,
    setEditConfigColumns,
    setEditConfigFilters,
    setColumnNamesForFilters,
    getImportFilterObjectInFiltersAndSorts,
    getImportFilterObjectInFilters,
    getImportFilterObjectInSorts,
    dynamicFilterConversion,
    createNewFilterObject,
    renderFilterOptions,
    renderFilterColumnOptions,
    fetchFilters,
    convertToTitleCase,
    formatDate,
    formatColumnName,
    validateImportedFilters,
    convertToHeaderForSQL,
};
