import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CONNECTIONS } from '../../../../constants/connection-constants';
import ColumnSearch from '../common/ColumnSearch';
import ColumnSelectionTree from '../common/ColumnSelectionTree';
import { useDataPreviewContext } from '../../Context/DataPreviewContext';

function ApiTemplateDataPreviewColumnActions() {
    const {
        activeConnectionObj,
        showColumnActions,
        setShowColumnActions,
        collapseAllObjects,
        setCollapseAllObjects,
        columnSearchText,
        setColumnSearchText,
    } = useDataPreviewContext();

    const [openDcDrawer, setOpenDcDrawer] = useState(false);
    const [editDc, setEditDc] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const [selectedField, setSelectedField] = useState('');
    const [selectedFormula, setSelectedFormula] = useState('');

    const handleClose = () => {
        setShowColumnActions(!showColumnActions);
        setColumnSearchText('');
    };

    const handleFieldSelect = (value) => {
        setSelectedField(value);
    };

    const handleFormulaSelect = (value) => {
        setSelectedFormula(value);
    };

    return (
        <div className=' flex flex-col overflow-hidden w-full z-30 relative'>
            <div className='flex justify-between items-center  flex-shrink  mb-[10px] px-[13.5px] pt-[13.5px]'>
                <div className=' text-stackit-text-gray p-0 m-0 text-[12px] flex items-center font-bold'>
                    ADD{' '}
                    {activeConnectionObj?.connectionName ===
                    CONNECTIONS.GOOGLE_SEARCH_CONSOLE
                        ? 'METRICS'
                        : 'FIELDS'}
                </div>
                <div className='flex items-center gap-3'>
                    <div
                        onClick={() =>
                            !collapseAllObjects
                                ? setCollapseAllObjects(true)
                                : {}
                        }
                        className={
                            collapseAllObjects
                                ? 'text-stackit-gray bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                                : 'text-stackit-primary bg-transparent border-none cursor-pointer text-[12px] font-[500]'
                        }
                    >
                        Collapse All
                    </div>
                    <AiOutlineClose
                        onClick={() => handleClose()}
                        className='cursor-pointer w-5 h-5'
                    />
                </div>
            </div>

            <ColumnSearch
                value={columnSearchText}
                setValue={setColumnSearchText}
                placeholderText={`Search ${
                    activeConnectionObj?.connectionName ===
                    CONNECTIONS.GOOGLE_SEARCH_CONSOLE
                        ? 'Metrics'
                        : 'Fields'
                }...`}
                classNames={'px-[13.5px] mb-[8px] shrink-0'}
            />

            <ColumnSelectionTree
                columnSearchText={columnSearchText}
                setColumnSearchText={setColumnSearchText}
                setDcDrawer={setOpenDcDrawer}
                setSelectedField={handleFieldSelect}
                setSelectedFormula={handleFormulaSelect}
                setEditDc={setEditDc}
                setViewOnly={setViewOnly}
            />
        </div>
    );
}

export default ApiTemplateDataPreviewColumnActions;
