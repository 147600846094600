import React, { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useDataPreviewContext } from '../../../Context/DataPreviewContext';
import { handleErrorWithToast } from '../../../../../utils/ErrorHandling';
import { toast } from 'sonner';

function ParamPopOverContent({
    param = {},
    params = [],
    type = 'param',
    onSetupClick = () => {},
}) {
    const {
        apiCustomFilters,
        setApiCustomFilters,
        setApiRequestParameters,
        setIsDataLoading,
        setSetUpClicked,
        setActionNeeded,
    } = useDataPreviewContext();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [localApiRequestParameters, setLocalApiRequestParameters] = useState(
        []
    );
    const [localApiCustomFilters, setLocalApiCustomFilters] = useState(
        apiCustomFilters || {}
    );

    async function handleSetupClick() {
        try {
            setLoading(true);
            switch (type) {
                case 'param':
                    // eslint-disable-next-line no-case-declarations
                    const requiredParamIndex = params.findIndex(
                        (p) => p.required
                    );
                    if (requiredParamIndex !== -1) {
                        setIsDataLoading(true);
                        setActionNeeded(false);
                        setSetUpClicked(true);
                    }
                    break;
                case 'filter':
                    setSetUpClicked(true);
                    break;
                default:
                    break;
            }

            setApiRequestParameters(localApiRequestParameters);
            setApiCustomFilters(localApiCustomFilters);
            toast.success('Saved Successfully!', {
                closeButton: true,
                autoClose: 1000,
            });
            onSetupClick();
        } catch (e) {
            handleErrorWithToast(e, 'Some Error Occured');
        } finally {
            setLoading(false);
        }
    }

    function handleSetProperty(property, value, fieldType = 'text') {
        if (type === 'param') {
            setLocalApiRequestParameters((prev) => {
                const existingIndex = prev.findIndex(
                    (item) => item.key === property
                );

                if (existingIndex !== -1) {
                    // If the key exists, replace the object
                    return prev.map((item, index) =>
                        index === existingIndex
                            ? { key: property, value, type: fieldType }
                            : item
                    );
                } else {
                    // If the key doesn't exist, add the new object
                    return [...prev, { key: property, value, type: fieldType }];
                }
            });
        } else {
            setLocalApiCustomFilters((prev) => ({
                ...prev,
                [property]: value,
            }));
        }
    }

    function convertDefaultDateToISOFormat(date, dateFormat) {
        if (date != null) {
            switch (dateFormat) {
                case 'YYYY-MM-DD':
                    return date;
                case 'YYYY/MM/DD':
                    return `${date.slice(0, 4)}-${date.slice(
                        5,
                        7
                    )}-${date.slice(8, 10)}`;

                case 'DD/MM/YYYY':
                case 'DD-MM-YYYY':
                    return `${date.slice(6, 10)}-${date.slice(
                        3,
                        5
                    )}-${date.slice(0, 2)}`;

                default:
                    return date;
            }
        }
    }

    function handleInputFieldRender(paramObj) {
        switch (paramObj.fieldType) {
            case 'select':
                const parsedFieldValues = Object.entries(
                    paramObj.fieldValues || {}
                ).map(([key, value]) => ({
                    value: key,
                    label: value.displayName || '',
                }));
                return (
                    <>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'m-0'}>{paramObj.fieldName}</p>
                            <p
                                className={
                                    'm-0 break-words text-xs text-stackit-gray whitespace-break-spaces'
                                }
                            >
                                {paramObj.description}
                            </p>
                        </div>
                        <Form.Item
                            name={paramObj.fieldName}
                            rules={[
                                {
                                    required: paramObj.required,
                                    message: `Please select ${paramObj.fieldName}`,
                                },
                            ]}
                            required={paramObj.required}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={`Select ${paramObj.fieldName}`}
                                onChange={(value) =>
                                    handleSetProperty(
                                        paramObj.fieldPropertyName,
                                        value,
                                        'select'
                                    )
                                }
                                options={parsedFieldValues}
                            />
                        </Form.Item>
                    </>
                );
            case 'multiSelect':
                const parsedMultiFieldValues = Object.entries(
                    paramObj.fieldValues || {}
                ).map(([key, value]) => ({
                    value: key,
                    label: value.displayName || '',
                }));
                return (
                    <>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'m-0'}>{paramObj.fieldName}</p>
                            <p
                                className={
                                    'm-0 break-words text-xs text-stackit-gray whitespace-break-spaces'
                                }
                            >
                                {paramObj.description}
                            </p>
                        </div>
                        <Form.Item
                            name={paramObj.fieldName}
                            rules={[
                                {
                                    required: paramObj.required,
                                    message: `Please select ${paramObj.fieldName}`,
                                },
                            ]}
                            required={paramObj.required}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={`Select ${paramObj.fieldName}`}
                                mode='multiple'
                                onChange={(value) =>
                                    handleSetProperty(
                                        paramObj.fieldPropertyName,
                                        value,
                                        'multiSelect'
                                    )
                                }
                                options={parsedMultiFieldValues}
                            />
                        </Form.Item>
                    </>
                );
            case 'date':
                return (
                    <>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'m-0'}>{paramObj.fieldName}</p>
                            <p
                                className={
                                    'm-0 break-words text-xs text-stackit-gray whitespace-break-spaces'
                                }
                            >
                                {paramObj.description}
                            </p>
                        </div>
                        <input
                            type={'date'}
                            className={'p-1 rounded border outline-none'}
                            value={convertDefaultDateToISOFormat(
                                localApiCustomFilters[
                                    paramObj.fieldPropertyName
                                ] ||
                                    apiCustomFilters[
                                        paramObj.fieldPropertyName
                                    ],
                                paramObj.dateFormat
                            )}
                            onChange={(e) =>
                                handleSetProperty(
                                    paramObj.fieldPropertyName,
                                    e.target.value,
                                    'date'
                                )
                            }
                        />
                    </>
                );
            default:
                return (
                    <>
                        <div className={'flex flex-col gap-2'}>
                            <p className={'m-0'}>{paramObj.fieldName}</p>
                            <p
                                className={
                                    'm-0 break-words text-xs text-stackit-gray whitespace-break-spaces'
                                }
                            >
                                {paramObj.description}
                            </p>
                        </div>
                        <Form.Item
                            name={paramObj.fieldPropertyName}
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter ${paramObj.fieldName}`,
                                },
                            ]}
                            required={true}
                        >
                            <Input
                                placeholder={paramObj.fieldName}
                                className={'text-xs rounded-none'}
                                size={'large'}
                                onChange={(e) =>
                                    handleSetProperty(
                                        paramObj.fieldPropertyName,
                                        e.target.value,
                                        'text'
                                    )
                                }
                            />
                        </Form.Item>
                    </>
                );
        }
    }

    return (
        <div className='px-4 py-4 w-80 border-stackit-gray-primary'>
            <Form
                layout={'vertical'}
                className='flex flex-col gap-y-2'
                style={{ marginBottom: '0px' }}
                form={form}
                onFinish={handleSetupClick}
                initialValues={apiCustomFilters}
            >
                {params &&
                    params.length > 0 &&
                    params.map((p) => handleInputFieldRender(p))}

                {param &&
                    Object.keys(param || {}).length > 0 &&
                    handleInputFieldRender(param)}

                <Form.Item style={{ marginBottom: '0px' }}>
                    <Button
                        block
                        type={'default'}
                        htmlType='submit'
                        className='bg-stackit-primary hover:bg-stackit-primary text-white border-none hover:border-none pagination-btn text-xs'
                        disabled={loading}
                        loading={loading}
                    >
                        Setup
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ParamPopOverContent;
