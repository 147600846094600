/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import { Skeleton, Tooltip } from 'antd';
import { MdOutlineFilterList, MdFilterListAlt } from 'react-icons/md';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useDataPreviewContext } from '../Context/DataPreviewContext';
import { CONNECTIONS } from '../../../constants/connection-constants';
import { truncateString } from '../../../utils/jsUtils';

const ErrorImg = 'https://assets.superjoin.ai/images/oops-removebg-preview.png';
const sqlImg = 'https://assets.superjoin.ai/images/sqldb.png';

const DataTable = ({
    dataSource,
    columns,
    numberOfRows,
    scrollFields,
    setScrollFields,
    isPossibleToLoadMoreData,
    setLoadMoreData,
    setSelectedFilterColumn,
    activeConnectionObj,
    appliedFilters,
    setIsFilterPopoverOpen,
    subObjectName,
    setFilterOpenGroupIndex,
}) => {
    const dataColumnRef = useRef(null);
    const staticDivRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (dataColumnRef.current && staticDivRef.current) {
                const { scrollLeft } = dataColumnRef.current;
                staticDivRef.current.style.left = `${scrollLeft}px`;
            }
        };

        const scrollContainer = dataColumnRef.current;
        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollFields) {
            if (dataColumnRef.current) {
                setTimeout(() => {
                    dataColumnRef.current.scrollTo({
                        left:
                            dataColumnRef.current.scrollWidth -
                            dataColumnRef.current.clientWidth,
                        behavior: 'smooth',
                    });
                }, 100);
            }
        }

        setScrollFields(false);
    }, [scrollFields]);

    function checkIfFilterAttached(key, connName) {
        switch (connName) {
            case CONNECTIONS.CHARGEBEE:
                return appliedFilters.some((filter) => filter.column === key);
            case CONNECTIONS.HUBSPOT:
                return appliedFilters
                    .flat()
                    .some((filter) => filter.column === key?.split('.')?.pop());
            default:
                return false;
        }
    }

    return (
        <div
            ref={dataColumnRef}
            className='w-full flex flex-grow flex-col overflow-y-auto mb-[5px] overflow-x-scroll relative'
        >
            <div className='w-[400px] flex '>
                {(columns || []).map((column, index) => (
                    <div
                        className='flex flex-col h-full space-y-0 w-fit'
                        key={index}
                    >
                        <p
                            style={{
                                whiteSpace: 'nowrap',
                                border: '0.5px solid #e8e8e8',
                            }}
                            className={`${index === 0 && 'rounded-tl-sm'} ${
                                column?.key?.startsWith('FX.')
                                    ? ' bg-[#E8F3FD] text-stackit-dc-primary'
                                    : ' bg-[#FEFAF4] text-stackit-primary'
                            } flex justify-between items-center font-bold  mx-0 mb-0 mt-0 pt-3 pb-3 pl-4 pr-2  h-fit sticky top-0 text-ellipsis`}
                        >
                            {column.title}{' '}
                            {(!column?.key?.startsWith(
                                `${subObjectName
                                    ?.replace(' ', '_')
                                    .toLowerCase()}.` &&
                                    activeConnectionObj.connectionName ===
                                        CONNECTIONS.HUBSPOT
                            ) ||
                                activeConnectionObj.connectionName ===
                                    CONNECTIONS.CHARGEBEE) &&
                                !column?.key?.startsWith('FX.') &&
                                (checkIfFilterAttached(
                                    column.key,
                                    activeConnectionObj.connectionName
                                ) ? (
                                    <MdFilterListAlt
                                        onClick={() =>
                                            setIsFilterPopoverOpen(true)
                                        }
                                        className={`ml-3 w-4 h-4 cursor-pointer ${
                                            activeConnectionObj.connectionName !==
                                                CONNECTIONS.CHARGEBEE &&
                                            activeConnectionObj.connectionName !==
                                                CONNECTIONS.HUBSPOT &&
                                            ' hidden'
                                        }`}
                                    />
                                ) : (
                                    <MdOutlineFilterList
                                        onClick={() => {
                                            setIsFilterPopoverOpen(true);
                                            setSelectedFilterColumn(column);
                                            if (
                                                activeConnectionObj.connectionName ===
                                                CONNECTIONS.HUBSPOT
                                            ) {
                                                const groupIndex = (
                                                    appliedFilters || []
                                                ).findIndex(
                                                    (group) =>
                                                        (group || []).length < 3
                                                );
                                                setFilterOpenGroupIndex(
                                                    groupIndex
                                                );
                                            }
                                        }}
                                        className={`ml-3 w-4 h-4 cursor-pointer ${
                                            activeConnectionObj.connectionName !==
                                                CONNECTIONS.CHARGEBEE &&
                                            activeConnectionObj.connectionName !==
                                                CONNECTIONS.HUBSPOT &&
                                            ' hidden'
                                        }`}
                                    />
                                ))}
                        </p>
                        {dataSource[column.key]
                            ? (dataSource[column.key] || []).map(
                                  (data, dataIndex) => {
                                      return (
                                          <p
                                              style={{
                                                  border: '0.5px solid #e8e8e8',
                                                  whiteSpace: 'nowrap',
                                              }}
                                              className='bg-white h-fit py-2 px-2 data-preview-table-cell'
                                              key={dataIndex}
                                          >
                                              {data != null && data !== '' ? (
                                                  truncateString(
                                                      data.toString(),
                                                      20
                                                  )
                                              ) : (
                                                  <span className='text-transparent data-preview-table-cell'>
                                                      null
                                                  </span>
                                              )}
                                          </p>
                                      );
                                  }
                              )
                            : Array(numberOfRows)
                                  .fill()
                                  .map((_, dataIndex) => (
                                      <p
                                          style={{
                                              border: '0.5px solid #e8e8e8',
                                              whiteSpace: 'nowrap',
                                          }}
                                          className='bg-white h-fit py-2 px-2 text-transparent data-preview-table-cell'
                                          key={dataIndex}
                                      >
                                          null
                                      </p>
                                  ))}
                    </div>
                ))}
            </div>
            {activeConnectionObj.connectionName === CONNECTIONS.CHARGEBEE ||
            activeConnectionObj.connectionName === CONNECTIONS.HUBSPOT ? (
                <div className=' w-full flex-shrink gap-2 items-center  justify-center transform'>
                    <div
                        ref={staticDivRef}
                        className='absolute text-center top-0 left-0 w-full py-4 my-2 flex flex-col gap-2'
                    >
                        <p className='p-0 m-0 text-stackit-text-gray text-[13px] font-[500]'>
                            End Of Preview
                        </p>
                        {numberOfRows >= 100 ? (
                            <p className='p-0 px-[20px] m-0 text-[13px] text-stackit-gray font-[300] text-center'>
                                This is a preview of your latest 100 rows. Use
                                filters to fine tune your data.{' '}
                                <span
                                    onClick={() => setIsFilterPopoverOpen(true)}
                                    className='p-0 m-0 text-stackit-primary cursor-pointer'
                                >
                                    {appliedFilters.length > 0
                                        ? 'Add more filters'
                                        : 'Add Filters'}
                                </span>
                            </p>
                        ) : numberOfRows === 0 ? (
                            <p className='p-0 px-[20px] m-0 text-[13px] text-stackit-gray font-[300] text-center'>
                                The filter is applied only on the first 100
                                rows. Use additional filters to fine tune your
                                data.{' '}
                                <span
                                    onClick={() => setIsFilterPopoverOpen(true)}
                                    className='p-0 m-0 text-stackit-primary cursor-pointer'
                                >
                                    {appliedFilters.length > 0
                                        ? 'Modify filters'
                                        : 'Add Filters'}
                                </span>
                            </p>
                        ) : (
                            <p className='p-0 px-[20px] m-0 text-[13px] text-stackit-gray font-[300] text-center'>
                                This is a preview of your latest {numberOfRows}{' '}
                                rows. Use filters to fine tune your data.{' '}
                                <span
                                    onClick={() => setIsFilterPopoverOpen(true)}
                                    className='p-0 m-0 text-stackit-primary cursor-pointer'
                                >
                                    {appliedFilters.length > 0
                                        ? 'Modify filters'
                                        : 'Add Filters'}
                                </span>
                            </p>
                        )}
                        {isPossibleToLoadMoreData && numberOfRows < 100 && (
                            <button
                                onClick={() => setLoadMoreData(true)}
                                className='bg-black border-none text-white px-4 py-1.5 text-[12px] rounded-md cursor-pointer w-fit self-center'
                            >
                                Load More Rows
                            </button>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default function DataPreviewTable() {
    const {
        numberOfRows,
        dataSource,
        dataSelectedColumns,
        isDataLoading,
        refreshRequired,
        setRefreshButtonClicked,
        setLoadMoreData,
        isPossibleToLoadMoreData,
        scrollFields,
        setScrollFields,
        setSelectedFilterColumn,
        activeConnectionObj,
        appliedFilters,
        setIsFilterPopoverOpen,
        errorMsg,
        sqlActiveTab,
        subObject,
        setFilterOpenGroupIndex,
        actionNeeded,
    } = useDataPreviewContext();

    return (
        <div className='h-full flex flex-col w-full'>
            <div
                className={`flex flex-shrink w-full justify-center bg-stackit-background-3 py-[3.5px]`}
            >
                <p
                    className={`m-0 text-[10px] font-medium flex gap-2 items-center p-0 text-center`}
                >
                    PREVIEW{' '}
                    <Tooltip
                        placement={'bottom'}
                        title={`With Superjoin you can import data upto ${
                            activeConnectionObj?.connectionName ===
                            CONNECTIONS.API_ORCHESTRATOR
                                ? '20,000'
                                : '50,000'
                        } rows on every pull.`}
                    >
                        <IoInformationCircleOutline size={15} />
                    </Tooltip>
                </p>
            </div>
            {[
                CONNECTIONS.MYSQL,
                CONNECTIONS.POSTGRES,
                CONNECTIONS.REDSHIFT,
                CONNECTIONS.MSSQL,
                CONNECTIONS.SNOWFLAKE,
            ].includes(activeConnectionObj?.connectionName) &&
                errorMsg &&
                sqlActiveTab === 2 &&
                dataSelectedColumns.length === 0 &&
                !refreshRequired && (
                    <div className='w-full h-[98%] flex flex-col items-center justify-center'>
                        <img
                            src={ErrorImg}
                            className='h-32'
                            alt='empty-state'
                        />
                        <br />
                        <div className='font-semibold text-stackit-gray-primary text-lg'>
                            Something went wrong!
                        </div>
                        <p className=''>{errorMsg}</p>
                    </div>
                )}
            {isDataLoading && actionNeeded && (
                <div className='flex-shrink w-[95%] h-[95%] flex flex-col space-y-4 pl-5 pr-5 pt-4 pb-5 items-center justify-center'>
                    <div className='flex flex-col gap-2 items-center justify-center text-center'>
                        <img
                            src={
                                'https://assets.superjoin.ai/images/data-preview-loading.svg'
                            }
                            className='h-24'
                            alt='empty-state'
                        />
                        <p className='m-0 font-semibold'>Action Needed</p>
                        <p className='m-0 text-sm text-stackit-gray'>
                            Please enter the required details to proceed
                        </p>
                    </div>
                </div>
            )}
            {isDataLoading && !refreshRequired && !actionNeeded && (
                <div className='flex-shrink w-[95%] h-[95%] flex flex-col space-y-4 pl-5 pr-5 pt-4 pb-5 items-center justify-center'>
                    <div className='grid grid-cols-4 gap-0 w-full h-full'>
                        {Array(40)
                            .fill()
                            .map((_, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        border: '0.5px solid #e8e8e8',
                                    }}
                                    className={`${
                                        index < 4 &&
                                        'bg-stackit-secondary text-stackit-primary'
                                    } w-full h-full flex flex-col items-center justify-center`}
                                >
                                    <Skeleton
                                        paragraph={false}
                                        key={index}
                                        active={true}
                                        style={{
                                            width: '90%',
                                            color: '#e8e8e8',
                                        }}
                                        className='mx-auto -m-2'
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            )}

            {!isDataLoading &&
                sqlActiveTab === 1 &&
                dataSelectedColumns.length === 0 &&
                !refreshRequired && (
                    <div className='w-full h-[99%] flex flex-col items-center justify-center'>
                        <img
                            src={
                                'https://assets.superjoin.ai/images/data-preview-loading.svg'
                            }
                            alt='empty-state'
                        />
                        <br />
                        Choose the fields to display
                    </div>
                )}
            {!isDataLoading &&
                sqlActiveTab === 2 &&
                !errorMsg &&
                dataSelectedColumns.length === 0 &&
                !refreshRequired && (
                    <div className='w-full h-[99%] flex flex-col items-center justify-center'>
                        <img
                            className='h-24'
                            src={
                                [
                                    CONNECTIONS.MYSQL,
                                    CONNECTIONS.POSTGRES,
                                    CONNECTIONS.REDSHIFT,
                                    CONNECTIONS.MSSQL,
                                    CONNECTIONS.SNOWFLAKE,
                                ].includes(activeConnectionObj?.connectionName)
                                    ? sqlImg
                                    : 'https://assets.superjoin.ai/images/data-preview-loading.svg'
                            }
                            alt='empty-state'
                        />
                        <br />
                        {[
                            CONNECTIONS.MYSQL,
                            CONNECTIONS.POSTGRES,
                            CONNECTIONS.REDSHIFT,
                            CONNECTIONS.MSSQL,
                            CONNECTIONS.SNOWFLAKE,
                        ].includes(activeConnectionObj?.connectionName)
                            ? 'Write SQL Query to Fetch Columns'
                            : 'Choose the fields to display'}
                    </div>
                )}
            {!isDataLoading &&
                dataSelectedColumns.length > 0 &&
                !refreshRequired && (
                    <>
                        <DataTable
                            numberOfRows={numberOfRows}
                            dataSource={dataSource}
                            columns={dataSelectedColumns}
                            scrollFields={scrollFields}
                            setScrollFields={setScrollFields}
                            isPossibleToLoadMoreData={isPossibleToLoadMoreData}
                            setLoadMoreData={setLoadMoreData}
                            setSelectedFilterColumn={setSelectedFilterColumn}
                            activeConnectionObj={activeConnectionObj}
                            appliedFilters={appliedFilters}
                            setIsFilterPopoverOpen={setIsFilterPopoverOpen}
                            subObjectName={subObject}
                            setFilterOpenGroupIndex={setFilterOpenGroupIndex}
                        />
                    </>
                )}

            {refreshRequired && (
                <>
                    <div className='flex-shrinkw-full h-full flex flex-col items-center justify-center'>
                        Refresh Preview
                        <br />
                        <button
                            onClick={() => {
                                setRefreshButtonClicked(true);
                            }}
                            className='mt-4 bg-stackit-primary text-white px-4 py-2 rounded-md'
                        >
                            Refresh
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
